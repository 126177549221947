import { ReactComponent as GreenLeaf } from "@assets/img/green-leaf.svg";
import { ReactComponent as BlushLogo } from "@assets/img/transport/Blush.svg";
import { ReactComponent as BringLogo } from "@assets/img/transport/Bring.svg";
import BudbeeLogo from "@assets/img/transport/Budbee.png";
import { ReactComponent as KomplettLogo } from "@assets/img/transport/Komplett.svg";
import { ReactComponent as PorterBuddyLogo } from "@assets/img/transport/PorterBuddy.svg";
import { ReactComponent as PostenLogo } from "@assets/img/transport/Posten.svg";
import { ReactComponent as PostNordLogo } from "@assets/img/transport/Postnord.svg";
import { TRANSPORTS } from "@constants/constants";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";

const transportLogoMap = {
	[TRANSPORTS.BUDBEE]: (
		<img src={BudbeeLogo} data-testid="Budbee" alt="Budbee" />
	),
	[TRANSPORTS.BLUSH]: <BlushLogo data-testid="Blush" />,
	[TRANSPORTS.BRING]: <BringLogo data-testid="Bring" />,
	[TRANSPORTS.KOMPLETT]: <KomplettLogo data-testid="Komplett" />,
	[TRANSPORTS.PORTERBUDDY]: <PorterBuddyLogo data-testid="PorterBuddy" />,
	[TRANSPORTS.POSTEN]: <PostenLogo data-testid="Posten" />,
	[TRANSPORTS.POSTNORD]: <PostNordLogo data-testid="PostNord" />,
};

const ShippingOptionLabel = ({
	typeCode,
	header,
	subHeader,
	label,
	hasDifferentNames,
	hours,
	transporter,
	hasDifferentTransporters,
	street,
	postCode,
	estimatedDeliveryDate,
	estimatedDeliveryTime,
	price,
	hasDifferentPrices,
	greenDeliveryLabel,
}) => {
	const labelText = hasDifferentNames && label;
	const transporterLogo = hasDifferentTransporters
		? transportLogoMap[transporter]
		: null;
	const showPrice = hasDifferentPrices && price >= 0;

	return (
		<div
			className={classNames("shipping-option-label", typeCode, {
				"with-label-text": !!labelText,
				"with-hours": !!hours,
				"with-transporter": !!transporterLogo,
				"with-address": !!street,
				"with-estimated-delivery": !!estimatedDeliveryDate,
				"with-price": !!showPrice,
				"with-green-delivery": !!greenDeliveryLabel,
				"with-header": !!header,
			})}
		>
			{header && (
				<div className="header">
					<span className="header">{header}</span>
					{subHeader && <span className="subheader">{subHeader}</span>}
				</div>
			)}
			<div className="content">
				{labelText && <span className="label">{labelText}</span>}
				{hours && <span className="hours">{hours}</span>}
				{street && (
					<span className="address">
						{postCode}, {street}
					</span>
				)}
				{showPrice && (
					<span className="price">
						<FormattedMessage id="shortMonetary" values={{ value: price }} />
					</span>
				)}
				{estimatedDeliveryDate && (
					<div className="estimated-delivery">
						<span className="estimated-delivery-date">
							{estimatedDeliveryDate}.{" "}
						</span>
						{estimatedDeliveryTime && (
							<span className="estimated-delivery-time">
								{estimatedDeliveryTime}
							</span>
						)}
					</div>
				)}
				{transporterLogo && (
					<span className="transporter">{transporterLogo}</span>
				)}
				{greenDeliveryLabel && (
					<div className="green-delivery">
						<GreenLeaf className="green-delivery-logo" /> {greenDeliveryLabel}
					</div>
				)}
			</div>
		</div>
	);
};

export default ShippingOptionLabel;
