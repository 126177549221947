export const delay = (milliseconds = 1000) =>
	new Promise((resolve) => setTimeout(() => resolve(), milliseconds));

export const throwAfter = async ({
	milliseconds = 1000,
	message = "failed in throw after",
}) => {
	await delay(milliseconds);
	throw new Error(message);
};
