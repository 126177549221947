import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const LinkButton = styled.button(
	({ theme }) => css`
		color: ${theme.colors.linkColor};
		font-family: inherit;
		cursor: pointer;
		padding: 0;
		background: none;
		border: 0 none;
		font-size: inherit;
		text-decoration: none;
		text-decoration-skip: auto;
		&:link,
		&:visited,
		&:active {
			text-decoration: none;
		}
		&:hover {
			color: ${theme.colors.linkHoverColor};
			text-decoration: underline;
		}
	`,
);
