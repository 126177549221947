import { ReactComponent as EllipseChecked } from "@assets/img/icons/elipse-checked.svg";
import { ReactComponent as Ellipse } from "@assets/img/icons/elipse.svg";
import { KEY_MAP } from "@constants/constants";
import dispatchClarityEvent from "@utils/MicrosoftClarityUtils";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

class Dropdown extends React.Component {
	constructor(props) {
		super(props);

		this.manageSelection = (children, selectedId) => {
			const index = this.getSelectedIndex(children, selectedId);
			return {
				items: children,
				selectedIndex: index,
				selection: selectedId,
				selected: selectedId,
			};
		};

		this.getSelected = (children, selectedId) => {
			const newItems = {
				items: [
					...children.filter((child) => child.key === selectedId),
					...children.filter((child) => child.key !== selectedId),
				],
			};
			const newSelection = {
				selectedIndex: 0,
				selection: newItems.items[0].key,
				selected: newItems.items[0].key,
			};
			return { ...newItems, ...newSelection };
		};

		this.getSelectedIndex = (items, selectedId) => {
			return items.findIndex((item) => item.key === selectedId);
		};

		this.state = {
			selected: props.selectedId,
			active: props.active || false,
			...(props.active
				? this.manageSelection(props.children, props.selectedId)
				: this.getSelected(props.children, props.selectedId)),
		};

		this.selectActiveItem = (id) => {
			if (this.state.selected !== id) {
				const newState = this.props.active
					? this.manageSelection(this.props.children, id)
					: this.getSelected(this.props.children, id);
				this.setState(newState);
				this.props.onSelect(id);
			}
			this.deactivate();
		};

		this.setWrapperHeight = () => {
			this._wrapper.style.height = getComputedStyle(this._wrapper).height;
		};

		this.unsetWrapperHeight = () => {
			this._wrapper.style.height = null;
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		const newState = this.props.active
			? this.manageSelection(props.children, props.selectedId)
			: this.getSelected(props.children, props.selectedId);
		this.setState(newState);
	}

	componentDidUpdate() {
		if (this.props.active && !this.props.horizontal) {
			this._options.focus();
		}
	}

	handleItemMouseDown = (id) => (e) => {
		e.preventDefault();

		if (this.state.items.length > 1) {
			!this.state.active ? this.activate() : this.selectActiveItem(id);
		}
		this._options.focus();
	};

	handleItemKeyDown = (id) => (e) => {
		switch (e.keyCode) {
			case KEY_MAP.ENTER:
				e.preventDefault();
				this.state.active ? this.selectActiveItem(id) : this.activate();
				break;
			case KEY_MAP.TAB:
				e.shiftKey ? this.activateItem(-1) : this.activateItem(1);
				break;
		}
	};

	handleKeyDown = (e) => {
		if (!this.props.active) {
			switch (e.keyCode) {
				case KEY_MAP.ENTER:
					e.preventDefault();
					this.state.active
						? this.selectActiveItem(this.state.selection)
						: this.activate();
					break;
				case KEY_MAP.ESCAPE:
					e.preventDefault();
					this.deactivate();
					break;
				case KEY_MAP.ARROW_DOWN:
					e.preventDefault();
					this.state.active ? this.activateItem(1) : this.activate();
					break;
				case KEY_MAP.ARROW_UP:
					e.preventDefault();
					this.activateItem(-1);
					break;
				case KEY_MAP.SPACE:
					e.preventDefault();
					break;
			}
		}
	};

	handleBlur = () => {
		!this.props.active && this.deactivate();
	};

	handleExpandDropdown = () => {
		this.setState(
			{
				active: true,
			},
			() => {
				this._options.focus();
			},
		);
	};

	activate = () => {
		if (!this.props.active) {
			this.setWrapperHeight();
			this.setState({ active: true });
		}
		this.props.onActivate && this.props.onActivate();
	};

	deactivate = () => {
		if (!this.props.active) {
			this.unsetWrapperHeight();
			this.setState({ active: false }, () => {
				this._dropdownButton.focus();
			});
		}
		this.props.onDeactivate && this.props.onDeactivate();
	};

	activateItem(delta) {
		if (this.state.active) {
			const index =
				(this.state.selectedIndex + this.state.items.length + delta) %
				this.state.items.length;
			this.setState({
				selectedIndex: index,
				selection: this.state.items[index].key,
			});
		}
	}

	activateItemByIndex = (key) => () => {
		const index = this.getSelectedIndex(this.state.items, key);
		this.setState({ selection: key, selectedIndex: index });
	};

	handleItemClick = (horizontal, isSelected, disabled) => () => {
		const shouldDispatchEventToClarity = horizontal && isSelected && !disabled;

		if (shouldDispatchEventToClarity) {
			dispatchClarityEvent("tryReactivateDeliveryOption");
		}
	};

	render() {
		const { items, active, selection, selected } = this.state;
		const { id, horizontal, disabled, ariaLabel } = this.props;
		let { className } = this.props;
		const accessibilitySubject = `${id}-listbox-item-`;

		return (
			<div
				data-testid="dropdown"
				ref={(el) => (this._wrapper = el)}
				id={id}
				className={classNames(className, {
					multioptional: items.length > 1,
					active: active,
					horizontal: horizontal,
				})}
				aria-label={ariaLabel}
			>
				<button
					ref={(el) => (this._dropdownButton = el)}
					type="button"
					aria-haspopup="listbox"
					aria-expanded={active}
					className={classNames("option active multioptional drop-button", {
						hidden: horizontal,
						active: active,
					})}
					onClick={this.handleExpandDropdown}
				>
					{items[0]}
				</button>

				<ul
					ref={(el) => (this._options = el)}
					aria-activedescendant={accessibilitySubject + selection}
					className={classNames("options", { hidden: !active })}
					onKeyDown={this.handleKeyDown}
					role="listbox"
					tabIndex={disabled || active || items.length === 0 ? -1 : 0}
					onBlur={this.handleBlur}
					aria-labelledby={id}
				>
					{items.map((item) => {
						const uid = accessibilitySubject + item.key;
						const isActive = selection === item.key;
						const isSelected = selected === item.key;

						return (
							<li
								key={uid}
								id={uid}
								className={classNames("option", {
									active: isActive,
									selected: isSelected,
									disabled: disabled,
								})}
								onMouseOver={this.activateItemByIndex(item.key)}
								onMouseDown={this.handleItemMouseDown(item.key)}
								onKeyDown={this.handleItemKeyDown(item.key)}
								onClick={this.handleItemClick(horizontal, isSelected, disabled)}
								role="option"
								aria-selected={isActive}
								tabIndex={this.props.active ? 0 : -1}
							>
								{horizontal && (isSelected ? <EllipseChecked /> : <Ellipse />)}
								{item}
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
}

Dropdown.propTypes = {
	active: PropTypes.bool,
	className: PropTypes.string,
	ariaLabel: PropTypes.string,
	id: PropTypes.string.isRequired,
	selectedId: PropTypes.string,
	children: PropTypes.array,
	onActivate: PropTypes.func,
	onDeactivate: PropTypes.func,
	onSelect: PropTypes.func.isRequired,
	horizontal: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default Dropdown;
