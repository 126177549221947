import styled from '@emotion/styled';

export const DrawerWrapper = styled('div')<{ isOpen: boolean }>(({ isOpen }) => ({
	position: 'absolute',
	height: '100vh',
	background: '#fff',
	maxWidth: 600,
	right: 0,
	transform: `translateX(${isOpen ? 0 : 100}%)`,
	transition: 'all 0.2s ease-in-out',
	top: 0,
	zIndex: 999,
	overflow: 'hidden',
}));

export const DrawerHeader = styled('div')({
	position: 'relative',
	display: 'flex',
	justifyContent: 'end',
	alignItems: 'center',
});

export const DrawerCloseButton = styled('button')({
	position: 'absolute',
	top: 10,
	right: 10,
	border: 'none',
	cursor: 'pointer',
	background: 'rgba(238, 238, 238, 0.2)',
	borderRadius: 5,
	padding: '7px 7px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	transition: '.2s',
	'&:hover': {
		background: 'rgba(221, 221, 221, 0.5)',
	},
});

export const DrawerContent = styled('div')({
	padding: '0 10px',
	marginTop: 40,
	textAlign: 'center',
});

export const DrawerBackdrop = styled('div')({
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	overflow: 'hidden',
	background: 'black',
	opacity: '0.6',
	zIndex: 999,
});