import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const getLinkStyles = ({ theme }) => css`
  color: ${theme.colors.linkColor};
  &:link,
  &:visited,
  &:active {
		color: ${theme.colors.linkColor};
	},
  &:hover {
    color: ${theme.colors.linkHoverColor};
  }
`;

const Link = styled.a(getLinkStyles);

export default Link;
