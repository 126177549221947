import Button from "@components/v2/components/buttons/Button";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const StyledSection = styled.section(
	({
		theme: {
			colors: { section },
			breakpoints,
		},
	}) => css`
		box-sizing: border-box;
		background-color: ${section.background};
		box-shadow: rgba(149, 157, 165, 0.2) 0 4px 16px;
		position: relative;

		@media (min-width: ${breakpoints.md}) {
			border-radius: 8px;
		}
	`,
);

export const SectionContent = styled.section(
	({ theme: { breakpoints } }) => css`
		padding: 16px;

		@media (min-width: ${breakpoints.md}) {
			padding: 32px;
		}
	`,
);

export const SectionNumber = styled.div(({ theme: { colors } }) => {
	return css`
		flex-shrink: 0;
		display: block;
		width: 38px;
		height: 38px;
		border-radius: 50%;
		font-size: 16px;
		text-align: center;
		line-height: 38px;
		background-color: ${colors.section.header.background};
	`;
});

export const SectionLabel = styled.div`
	word-break: break-word;
`;

export const SectionHeader = styled.h1`
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 16px;
	min-height: 38px;
	font-size: 18px;
	font-weight: 700;
	line-height: 16px;
`;

export const SectionHeaderButton = styled(Button)`
	word-break: break-word;
	flex-shrink: 0;
	margin-left: auto;
`;
