import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import GiftCardSection from "./GiftCardSection";

const GiftCardContainer = () => {
	const giftCard = useSelector((state) => state.payment.giftCard);
	const giftCards = useSelector((state) => state.payment.giftCards);
	const showgiftCardLoader = useSelector((state) => state.payment.showgiftCardLoader);
	const isDeletingGiftCard = useSelector((state) => state.payment.isDeletingGiftCard);

	const isCoveredByGiftCardsAndBonusPoints = useSelector((state) => state.checkout.isCoveredByGiftCardsAndBonusPoints);

	const allowsGiftCard = useSelector(({ payment: { directMethods, selectedMethod } }) => directMethods[selectedMethod].allowsGiftCard);

	return (
		<>
			{!allowsGiftCard && (
				<section
					className="gift-card"
					data-automationid="giftCard.openForm.unavailable"
				>
					<h2>
						<FormattedMessage id="payment.giftCard.unavailable" />
					</h2>
				</section>
			)}
			{allowsGiftCard && (
				<GiftCardSection
					giftCard={giftCard.applied}
					giftCards={giftCards}
					showgiftCardLoader={showgiftCardLoader}
					isDeletingGiftCard={isDeletingGiftCard}
					isTotalCoveredByGiftCard={isCoveredByGiftCardsAndBonusPoints}
				/>
			)}
		</>
	);
};

export default GiftCardContainer;
