import NSecLogo from "@assets/img/NSec.svg";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Footer = styled.footer(
	({ theme }) => css`
		display: grid;
		grid-row-gap: 8px;
		justify-items: center;
		margin: 24px auto;

		@media (max-width: ${theme.breakpoints.sm}) {
			grid-template-columns: auto;
			max-width: 450px;
			margin: 16px auto;
			grid-row-gap: 16px;
		}

		@media (min-width: ${theme.breakpoints.md}) {
			grid-template-columns: 1fr minmax(300px, 650px) minmax(300px, 650px) 1fr;
			grid-column-gap: 24px;
		}
	`,
);
export const TermsInfo = styled.div(
	({ theme }) => css`
		color: ${theme.extraColors.boulder};

		@media (max-width: ${theme.breakpoints.sm}) {
			grid-area: 1/1;
		}

		@media (min-width: ${theme.breakpoints.md}) {
			grid-area: 1/2;
		}
	`,
);

export const SVGImage = styled.svg(
	({ theme }) => css`
		background: url(${NSecLogo}) transparent no-repeat center center;
		width: 92px;
		height: 50px;
		display: inline-block;

		@media (max-width: ${theme.breakpoints.sm}) {
			grid-area: 2/1;
		}
		@media (min-width: ${theme.breakpoints.md}) {
			grid-area: 2/2;
		}
	`,
);
