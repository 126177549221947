import { ReactComponent as Check } from "@assets/img/icons/check-xl.svg";
import {
	ListItemLabel,
	ListItemNumberWrapper,
	ListItemSeparator,
	ListItemSeparatorWrapper,
	RenewalStepListItem,
	RenewalStepsList,
	RenewalStepsSection,
} from "@components/v2/components/Renewal/RenewalSteps/RenewalSteps.styles";
import React from "react";
import { useIntl } from "react-intl";

const RenewalSteps = () => {
	const { formatMessage } = useIntl();

	return (
		<RenewalStepsSection>
			<RenewalStepsList>
				<RenewalStepListItem aria-current="step">
					<ListItemNumberWrapper>
						<Check />
					</ListItemNumberWrapper>
					<ListItemLabel>
						{formatMessage({ id: "renewal.steps.returnLabel" })}
					</ListItemLabel>
				</RenewalStepListItem>

				<ListItemSeparatorWrapper>
					<ListItemSeparator />
				</ListItemSeparatorWrapper>

				<RenewalStepListItem>
					<ListItemNumberWrapper isActive={true}>2</ListItemNumberWrapper>
					<ListItemLabel>
						{formatMessage({ id: "renewal.steps.deliverLabel" })}
					</ListItemLabel>
				</RenewalStepListItem>

				<ListItemSeparatorWrapper>
					<ListItemSeparator />
				</ListItemSeparatorWrapper>

				<RenewalStepListItem>
					<ListItemNumberWrapper>3</ListItemNumberWrapper>
					<ListItemLabel>
						{formatMessage({ id: "renewal.steps.signLabel" })}
					</ListItemLabel>
				</RenewalStepListItem>
			</RenewalStepsList>
		</RenewalStepsSection>
	);
};

export default RenewalSteps;
