import { selectShipping } from "@actions/shipping.actions";
import Dropdown from "@components/form/Dropdown";
import ShippingExtraServices from "@components/order/shipping/ShippingExtraServices";
import ShippingItem from "@components/order/shipping/ShippingItem";
import {
	DISABLE_DROPDOWN_OPTIONS_LIMIT,
	TYPECODES,
} from "@constants/constants";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const ShippingCarryin = () => {
	const dispatch = useDispatch();

	const selectShippingState = (state) => state.order.logisticUnits[0].shipping;

	const logisticUnitId = useSelector(
		(state) => state.order.logisticUnits[0].id,
	);
	const currentMethod = useSelector(
		(state) => selectShippingState(state).currentMethod,
	);
	const extraServicesTotalGross = useSelector(
		(state) =>
			selectShippingState(state).selectedShipping.extraServicesTotalGross,
	);
	const carryinMethod = useSelector((state) =>
		selectShippingState(state).availableShipping.find(
			(shipping) => shipping.typeCode === TYPECODES.CARRYIN,
		),
	);

	const extraServiceIds = useSelector(
		(state) => selectShippingState(state).extraServiceIds,
	);

	const isCarryin = currentMethod.typeCode === TYPECODES.CARRYIN;

	const currentOption = carryinMethod.options.find(
		(option) => option.id === carryinMethod.selectedOptionId,
	);

	const extraServices = currentOption?.extraServices || [];

	const shouldDisplayOptions =
		carryinMethod.hasAlternatives && carryinMethod.options.length > 1;

	const handleSelectOption = (optionId) => {
		dispatch(selectShipping(carryinMethod.typeCode, optionId, []));
	};

	const handleSelectExtraServices = (extraServices) => {
		dispatch(
			selectShipping(
				carryinMethod.typeCode,
				carryinMethod.selectedOptionId,
				extraServices,
			),
		);
	};

	return (
		<>
			{shouldDisplayOptions && (
				<Dropdown
					id={`shippingOptions-${logisticUnitId}`}
					className="available-items"
					selectedId={carryinMethod.selectedOptionId}
					onSelect={handleSelectOption}
					active={carryinMethod.options.length < DISABLE_DROPDOWN_OPTIONS_LIMIT}
					horizontal={
						carryinMethod.options.length < DISABLE_DROPDOWN_OPTIONS_LIMIT
					}
					disabled={!isCarryin}
				>
					{carryinMethod.options.map((option) => (
						<ShippingItem
							key={option.id}
							className={carryinMethod.typeCode}
							header={option.header}
							subHeader={option.subHeader}
							greenDeliveryLabel={option.greenDeliveryLabel}
							price={carryinMethod.hasDifferentPrices && option.price}
							transport={[option.transporter]}
							postCode={option.postCode}
							street={option.street}
							hours={option.hours}
							estimatedDeliveryDate={option.estimatedDeliveryDate}
							estimatedDeliveryTime={option.estimatedDeliveryTime}
						>
							<FormattedMessage
								id="shortMonetary"
								values={{ value: option.price }}
							/>
						</ShippingItem>
					))}
				</Dropdown>
			)}
			<div className="extra-services-wrapper">
				<h1 className="extra-services-header">
					<FormattedMessage id="order.shipping.methods.carryin.extraservices" />
				</h1>
				<ShippingExtraServices
					extraServices={extraServices}
					extraServiceIds={extraServiceIds}
					onExtraServiceCheck={handleSelectExtraServices}
				/>
				{!!extraServicesTotalGross && (
					<div className="extra-services-total-gross">
						<FormattedMessage id="order.shipping.methods.carryin.extraservices.gross" />
						<FormattedMessage
							id="monetary"
							values={{ value: extraServicesTotalGross }}
						>
							{(string) => <strong className="total-gross">{string}</strong>}
						</FormattedMessage>
					</div>
				)}
			</div>
			{currentOption?.estimatedDeliveryDate && (
				<div
					className="estimated-delivery"
					/* eslint-disable-next-line react/no-danger */
					dangerouslySetInnerHTML={{
						__html: currentOption.estimatedDeliveryDate,
					}}
				/>
			)}
			{currentOption?.description && (
				<section
					className="extra-message"
					/* eslint-disable-next-line react/no-danger */
					dangerouslySetInnerHTML={{
						__html: currentOption.description,
					}}
				/>
			)}
		</>
	);
};

export default ShippingCarryin;
