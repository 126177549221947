import React from "react";
import { useIntl } from "react-intl";

const ProductPrice = ({ price, offerPrice, discounts = [] }) => {
	const { formatMessage } = useIntl();

	return (
		<>
			{!!discounts.length && (
				<del className="discounted">
					{formatMessage({ id: "monetary" }, { value: offerPrice })}
				</del>
			)}
			<span>{formatMessage({ id: "monetary" }, { value: price })}</span>
		</>
	);
};

export default ProductPrice;
