import React from "react";
import { StyledBox } from "./Box.styles";
import { getTheme } from "../../../app/theme";
import { ThemeProvider } from "@emotion/react";
import { BoxTypes } from "./Box.types";

const Box: React.FC<BoxTypes> = (props) => (
  <ThemeProvider theme={getTheme(props.storeId)}>
    <StyledBox style={props.style} className={props.className}>{props.children}</StyledBox>
  </ThemeProvider>
);

export default Box;
