export default {
	title: "Oops, sorry!",
	message: {
		part1: "Something went wrong, go back to",
		part2: "cart",
		part3: "or",
		part4: "try again",
	},
	messageNoCartUrl: {
		part1: "Sorry, something went wrong",
		part2: "Try again",
		part3: "or if the issue persists, contact customer service",
	},
};
