import { Theme } from "@emotion/react";

import { STORES_ID } from "./stores";

const defaultTheme = {
  colors: {
    labelText: "#222222",
    border: "#e4e4e4",
    primary: "#f8b912",
    primaryHover: "#fac742",
    secondary: "#476471",
    flatHover: "#f5f5f5",
  },
  breakpoints: {
    small: "479px",
    medium_vertical: "661px",
    medium: "991px",
  },
  columns: {
    1: "8.33333333%",
    2: "16.66666667%",
    3: "25%",
    4: "33.33333333%",
    5: "41.66666667%",
    6: "50%",
    7: "58.33333333%",
    8: "66.66666667%",
    9: "75%",
    10: "83.33333333%",
    11: "91.66666667%",
    12: "100%",
  },
  alerts: {
    success: {
      background: "#dcfce7",
      color: "#15803d",
    },
    error: {
      background: "#fee2e2",
      color: "#b91c1c",
    },
    warning: {
      background: "#fef9c3",
      color: "#a16207",
    },
    info: {
      background: "#f3f4f6",
      color: "#374151",
    },
  },
};

export const getTheme = (storeId: string | undefined): Theme => {
  switch (storeId) {
    case STORES_ID[310]:
    case STORES_ID[312]:
    case STORES_ID[321]:
    case STORES_ID[324]:
    case STORES_ID[344]:
      return {
        ...defaultTheme,
        colors: {
          labelText: "#222222",
          border: "#e4e4e4",
          primary: "#f8b912",
          primaryHover: "#fac742",
          secondary: "#476471",
          flatHover: "#f5f5f5",
        },
      };
    case STORES_ID[318]:
    case STORES_ID[325]:
      return {
        ...defaultTheme,
        colors: {
          labelText: "#fff",
          border: "#7397d1",
          primary: "#f56722;",
          primaryHover: "#f56722;",
          secondary: "#4ecfff",
          flatHover: "#f5f5f5",
        },
      };
    default:
      return {
        ...defaultTheme,
        colors: {
          labelText: "#222222",
          border: "#e4e4e4",
          primary: "#f8b912",
          primaryHover: "#fac742",
          secondary: "#476471",
          flatHover: "#f5f5f5",
        },
      };
  }
};
