import * as CheckoutActions from "@actions/checkout.actions";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Header from "../Header";

import defaultMessages from "./defaultMessages";

const ErrorPage = () => {
	const { cartUrl, storeUrl } = useSelector((state => state.checkout))
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(CheckoutActions.getCheckoutUrls());
	}, [dispatch]);

	const handleClick = () => window.location.reload();

	return (
		<div className="error-page">
			<Header showBackToCartLink={false} storeUrl={storeUrl} />
			<h1 className="title">
				<FormattedMessage
					id="errorPage.title"
					defaultMessage={defaultMessages.title}
				/>
			</h1>
			{cartUrl ? (
				<p className="error-message">
					<FormattedMessage
						id="errorPage.message.part1"
						defaultMessage={defaultMessages.message.part1}
					/>
					<a href={cartUrl} className="back-to-cart">
						<FormattedMessage
							id="errorPage.message.part2"
							defaultMessage={defaultMessages.message.part2}
						/>
					</a>
					<FormattedMessage
						id="errorPage.message.part3"
						defaultMessage={defaultMessages.message.part3}
					/>
					<button onClick={handleClick} className="refresh-page">
						<FormattedMessage
							id="errorPage.message.part4"
							defaultMessage={defaultMessages.message.part4}
						/>
					</button>
					.
				</p>
			) : (
				<p className="error-message">
					<FormattedMessage
						id="errorPage.messageNoCartUrl.part1"
						defaultMessage={defaultMessages.messageNoCartUrl.part1}
					/>
					.
					<button onClick={handleClick} className="refresh-page">
						<FormattedMessage
							id="errorPage.messageNoCartUrl.part2"
							defaultMessage={defaultMessages.messageNoCartUrl.part2}
						/>
					</button>
					,{" "}
					<FormattedMessage
						id="errorPage.messageNoCartUrl.part3"
						defaultMessage={defaultMessages.messageNoCartUrl.part3}
					/>
					.
				</p>
			)}
		</div>
	);
};

export default ErrorPage;
