import getCheckoutId from "@actions/utils/common/getCheckoutId";
import getNavigationData from "@actions/utils/common/getNavigationData";
import { USER_STATES } from "@constants/constants";
import OidcClient from "oidc-client/lib/oidc-client";

import OidcConfiguration from "./OidcConfigration.js";

export const Manager = new OidcClient.UserManager(OidcConfiguration);

OidcClient.Log.logger = console;
OidcClient.Log.level = OidcClient.Log.WARN;

Manager.events.addUserLoaded(function () {
	console.info("User loaded");
});

Manager.events.addUserUnloaded(function () {
	console.info("User unloaded");
});

Manager.events.addAccessTokenExpiring(function () {
	console.info("User access token expiring");
});

Manager.events.addAccessTokenExpired(function () {
	console.info("User access token expired");
	authorize();
});

Manager.events.addSilentRenewError(function (error) {
	console.error("Silent renew failed");
	console.error(error);
	authorize();
});

Manager.events.addUserSignedOut(function () {
	authorize();
});

const getGaCookieValue = () => {
	let gaCookieValue = "";
	window.location.search
		.substring(1)

		.split("&")
		.forEach(function (kvp) {
			if (kvp.startsWith("_ga")) {
				gaCookieValue = kvp;
			}
		});

	return gaCookieValue;
};

export const authorize = (level = 1, options = {}, saveHistory = false) =>
	getSigninUrl(level, options).then((signinUrl) => {
		if (saveHistory) {
			window.location.assign(signinUrl);
		} else {
			window.location.replace(signinUrl);
		}
	});

export const registerB2B = () =>
	getSigninUrl(1, { register: "b2b" }).then((url) =>
		window.location.assign(url),
	);

const createAcrValues = (level, options, data) => {
	let acr_values = `SecurityLevel:${level} ssn:1 tenant:${data.storeUrl}`;

	if (options.register) {
		acr_values += ` register_user:${options.register}`;
	}
	if (options.register_authenticated_user) {
		acr_values += ` register_authenticated_user:${options.register_authenticated_user}`;
	}

	return acr_values;
};

export const getSigninUrl = (level = 1, options = {}) => {
	const checkoutId = getCheckoutId();
	if (checkoutId) {
		return getNavigationData(checkoutId)
			.then((data) =>
				Manager.createSigninRequest({
					state: {
						...options,
						checkoutId: getCheckoutId(),
						gaCookieValue: getGaCookieValue(),
						expected_security_level: level,
					},
					acr_values: createAcrValues(level, options, data),
					prompt: options.prompt,
					login_hint: options.login_hint,
				}),
			)
			.then((data) => {
				const params = window.location.href.substring(
					window.location.href.indexOf("?") + 1,
					window.location.href.lastIndexOf("#"),
				);

				return `${data.url}&${params}`;
			});
	}
	return null;
};

export const getUser = () =>
	sessionStorage.getItem("session_checked")
		? Manager.getUser()
				.then((user) => {
					if (user) {
						return { userState: USER_STATES.AUTHENTICATED, user };
					} else {
						return authorize(1).then(() => ({
							userState: USER_STATES.AUTHENTICATING,
						}));
					}
				})
				.catch(() =>
					authorize(1).then(() => ({ userState: USER_STATES.AUTHENTICATING })),
				)
		: authorize(1).then(() => ({ userState: USER_STATES.AUTHENTICATING }));

export const logout = () => Manager.signoutRedirect();

export const signOutCallback = () =>
	Manager.signoutRedirectCallback().then(
		({ state: { checkoutId, gaCookieValue } }) => {
			const queryParams =
				gaCookieValue && gaCookieValue.length > 0 ? `?${gaCookieValue}` : "";
			window.location = `/${queryParams}#${checkoutId}`;
		},
	);
