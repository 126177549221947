import { ReactComponent as BlushLogo } from "@assets/img/transport/Blush.svg";
import { ReactComponent as BringLogo } from "@assets/img/transport/Bring.svg";
import BudbeeLogo from "@assets/img/transport/Budbee.png";
import { ReactComponent as KomplettLogo } from "@assets/img/transport/Komplett.svg";
import { ReactComponent as PorterBuddyLogo } from "@assets/img/transport/PorterBuddy.svg";
import { ReactComponent as PostenLogo } from "@assets/img/transport/Posten.svg";
import { ReactComponent as PostNordLogo } from "@assets/img/transport/Postnord.svg";
import Countdown from "@components/order/shipping/Countdown";
import { TRANSPORTS } from "@constants/constants";
import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

const ShippingMethodHeader = ({
	shipping: {
		label,
		cheapestOptionPrice,
		hasDifferentPrices,
		expiresAt,
		options,
	},
	isSelected = false,
	enableConfirmButton,
}) => {
	const transport = [
		...new Set(options.map((option) => option?.transporter)),
	].filter(Boolean);

	const transportLogoMap = {
		[TRANSPORTS.BUDBEE]: (
			<img src={BudbeeLogo} data-testid="Budbee" alt="Budbee" />
		),
		[TRANSPORTS.BLUSH]: <BlushLogo data-testid="Blush" />,
		[TRANSPORTS.BRING]: <BringLogo data-testid="Bring" />,
		[TRANSPORTS.KOMPLETT]: <KomplettLogo data-testid="Komplett" />,
		[TRANSPORTS.PORTERBUDDY]: <PorterBuddyLogo data-testid="PorterBuddy" />,
		[TRANSPORTS.POSTEN]: <PostenLogo data-testid="Posten" />,
		[TRANSPORTS.POSTNORD]: <PostNordLogo data-testid="PostNord" />,
	};

	return (
		<div className="shipping-method-header">
			{label && <div className="label">{label}</div>}
			{cheapestOptionPrice >= 0 && (
				<div className="cheapest-option-price">
					<FormattedMessage
						id="shipping.monetary.from"
						values={{
							priceFrom: hasDifferentPrices, // TODO: Remove the login from string editor and move it to the component.
							value: cheapestOptionPrice,
						}}
					/>
				</div>
			)}
			{transport.length > 0 && (
				<span className="transports">
					{transport.map((transportName, i) => {
						return (
							transportName && (
								<Fragment key={i}>{transportLogoMap[transportName]}</Fragment>
							)
						);
					})}
				</span>
			)}
			{expiresAt && (
				<Countdown
					expiresAt={expiresAt}
					isSelected={isSelected}
					onCountdownStarted={enableConfirmButton}
				/>
			)}
		</div>
	);
};

export default ShippingMethodHeader;
