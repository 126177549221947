export default (fun) => {
	let cache;
	let memoized = (...args) =>
		cache
			? new Promise((resolve) => resolve(cache))
			: fun(...args).then((val) => {
					return (cache = val);
			  });

	memoized.clearCache = () => {
		cache = undefined;
	};

	return memoized;
};
