import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
/**
 * Common close button for alerts and modals
 */
const CloseButton = ({ stringId = "alert.close", onClick, ...props }) => {
	return (
		<button className="close" onClick={onClick} {...props}>
			<FormattedMessage id={stringId} />
		</button>
	);
};

CloseButton.propTypes = {
	stringId: PropTypes.string,
	onClick: PropTypes.func,
	attrs: PropTypes.object,
};

export default CloseButton;
