import Modal from "@components/modal/Modal";
import { FlexConsent } from "@komplettnpm/mfcomponents";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

// TODO: DO not use micro frontend anymore because it's not maintained.
const MarketingConsentModal = ({ isModalOpen, handleToggleModal }) => {
	const { formatMessage } = useIntl();
	const storeUrl = useSelector((state) => state.checkout.storeUrl);

	return (
		<>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={handleToggleModal}
				className="finance-payment-terms-modal"
				aria-labelledby="financePaymentTermsModalHeader"
				contentLabel="financePaymentTermsModalContent"
			>
				<FlexConsent
					className="marketing-consent-modal-content"
					text={formatMessage(
						{ id: "payments.fiarownsubscription.consent.description" },
						{
							value: `${storeUrl}`,
						},
					)}
					label={formatMessage({
						id: "payments.fiarownsubscription.consent.checkbox.label",
					})}
					apiHost={new URL(storeUrl).hostname}
				/>
			</Modal>
		</>
	);
};

export default MarketingConsentModal;
