import { authorize } from "@components/authentication/authenticationConfig";
import { REDIRECT_ACTIONS_TYPES } from "@constants/constants";
import { sendPaymentEvent } from "@utils/GTMUtils";

export default async (json) => {
	const { actionType, actionUrl, parameters } = json;

	switch (actionType) {
		case REDIRECT_ACTIONS_TYPES.REDIRECT: {
			window.location.assign(actionUrl);

			break;
		}
		case REDIRECT_ACTIONS_TYPES.KOM_ID_AUTHENTICATION: {
			await sendPaymentEvent("KomIdAuthenticationRequired");
			await authorize(
				parameters.AuthenticationLevel,
				{ paymentId: parameters.PaymentId },
				true,
			);

			break;
		}
		case REDIRECT_ACTIONS_TYPES.SIGNING: {
			await sendPaymentEvent("DocumentSigningRequired");
			window.location.assign(parameters.SigningUri);

			break;
		}
		case REDIRECT_ACTIONS_TYPES.VIPPS_AUTHORIZATION: {
			await sendPaymentEvent("VippsAuthorizationRequired");
			window.location.assign(parameters.VippsAuthorizationUri);

			break;
		}
		case REDIRECT_ACTIONS_TYPES.CREDIT_CARD_AUTHORIZATION: {
			await sendPaymentEvent("CreditCardAuthorizationRequired");
			window.location.assign(parameters.CreditCardAuthorizationUri);

			break;
		}
		default: {
			throw new Error("Unknown action type " + actionType);
		}
	}
};
