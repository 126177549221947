export const initialState: FlexConsentStateI = {
  isSubscribed: true,
  isFetching: false,
  errorMessage: "",
};

export enum ACTIONS {
  GET_SUBSCRIPTION,
  SET_SUBSCRIPTION,
  SET_ERROR_MESSAGE,
  SET_IS_FETCHING,
}

interface FlexConsentStateI {
  isSubscribed: boolean;
  isFetching: boolean;
  errorMessage: string;
}

type Action =
  | { type: ACTIONS.GET_SUBSCRIPTION; payload: boolean }
  | { type: ACTIONS.SET_SUBSCRIPTION; payload: boolean }
  | { type: ACTIONS.SET_ERROR_MESSAGE; payload: string }
  | { type: ACTIONS.SET_IS_FETCHING; payload: boolean };

export const reducer = (state: FlexConsentStateI, action: Action) => {
  switch (action.type) {
    case ACTIONS.GET_SUBSCRIPTION:
      return {
        ...state,
        isSubscribed: action.payload,
      };
    case ACTIONS.SET_SUBSCRIPTION:
      return {
        ...state,
        isSubscribed: action.payload,
      };
    case ACTIONS.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case ACTIONS.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
  }
};
