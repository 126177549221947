import { ALERT_LEVELS } from "@constants/constants";

import * as AlertActions from "../actions/alert.actions";
import handleErrors from "../utils/handleErrors";

import * as CheckoutActions from "./checkout.actions";
import { hideLoader } from "./checkout.actions";
import authFetch from "./utils/common/authFetch";
import getCheckoutId from "./utils/common/getCheckoutId";
import handleErrorStatus from "./utils/common/handleErrorStatus";

/* action types */

export const RECIPIENT_SET_REQUESTED = "RECIPIENT_SET_REQUESTED";
export const RECIPIENT_SET_DONE = "RECIPIENT_SET_DONE";
export const RECIPIENT_SET_FAILED = "RECIPIENT_SET_FAILED";

export const RECIPIENT_SAVE_REQUESTED = "RECIPIENT_SAVE_REQUESTED";
export const RECIPIENT_SAVE_DONE = "RECIPIENT_SAVE_DONE";
export const RECIPIENT_SAVE_FAILED = "RECIPIENT_SAVE_FAILED";
export const RECIPIENT_SAVE_CANCELLED = "RECIPIENT_SAVE_CANCELLED";

export const RECIPIENT_CITY_CHANGE_REQUESTED =
	"RECIPIENT_CITY_CHANGE_REQUESTED";
export const RECIPIENT_CITY_CHANGE_DONE = "RECIPIENT_CITY_CHANGE_DONE";
export const RECIPIENT_CITY_CHANGE_FAILED = "RECIPIENT_CITY_CHANGE_FAILED";
export const RECIPIENT_CITY_CHANGE_CANCELLED =
	"RECIPIENT_CITY_CHANGE_CANCELLED";

export const RECIPIENT_VALIDITY_CHANGE = "RECIPIENT_VALIDITY_CHANGE";

export const EDIT_MODE_SHOW = "EDIT_MODE_SHOW";
export const EDIT_MODE_HIDE = "EDIT_MODE_HIDE";

/* action creators */

export const setRecipient = (user) => async (dispatch) => {
	try {
		dispatch(CheckoutActions.showLoader());
		dispatch({ type: RECIPIENT_SET_REQUESTED });

		const response = await fetch(
			`api/checkouts/${getCheckoutId()}/recipient/${user.profile.sub}`,
			{
				method: "POST",
				headers: new Headers({
					Authorization: `Bearer ${user.access_token}`,
					"Content-Type": "application/json",
					"Cache-Control": "no-cache, no-store",
					"Request-Id": getCheckoutId(),
				}),
			},
		);

		await handleErrors(response);

		dispatch({ type: RECIPIENT_SET_DONE });

		await dispatch(CheckoutActions.fetchCheckout());
	} catch (e) {
		handleErrorStatus(e, RECIPIENT_SET_FAILED, dispatch);
	} finally {
		dispatch(hideLoader());
	}
};

export const saveRecipient = (recipient) => async (dispatch) => {
	try {
		dispatch(CheckoutActions.showLoader());
		dispatch({
			type: RECIPIENT_SAVE_REQUESTED,
			recipient,
		});

		await authFetch({
			url: `api/checkouts/${getCheckoutId()}/recipient`,
			method: "PUT",
			body: JSON.stringify(recipient),
		});

		dispatch({ type: RECIPIENT_SAVE_DONE });
		dispatch(setEditModeVisibility(false));
		await dispatch(CheckoutActions.fetchCheckout());
	} catch (e) {
		dispatch({ type: RECIPIENT_SAVE_FAILED });

		dispatch(
			e.status
				? AlertActions.addAlert(
						"checkout.recipient.general",
						ALERT_LEVELS.WARN,
						"recipient.save.alert",
				  )
				: AlertActions.addAlert(
						"checkout.general",
						ALERT_LEVELS.ERROR,
						"network.lost",
				  ),
		);
	} finally {
		dispatch(CheckoutActions.hideLoader());
	}
};

export function isPostcodeValid(postcode) {
	return (dispatch) => {
		const fetchMethod = authFetch;

		return fetchMethod({
			url: `api/checkouts/${getCheckoutId()}/recipient/cities/${postcode}`,
			options: { cache: "default" },
		})
			.then(() => {
				return Promise.resolve();
			})
			.catch((error) => {
				if (error.status) {
					if (error.status === 400) {
						return Promise.reject({
							postcode: "validation.postcode.city.not.found",
						});
					} else {
						return Promise.resolve();
					}
				} else {
					dispatch(
						AlertActions.addAlert(
							"checkout.general",
							ALERT_LEVELS.ERROR,
							"network.lost",
						),
					);
					return Promise.reject("No connection");
				}
			});
	};
}

export function setEditModeVisibility(isVisible) {
	return (dispatch) => {
		if (isVisible) {
			dispatch(CheckoutActions.enableBlur());
			dispatch({ type: EDIT_MODE_SHOW });
		} else {
			dispatch(CheckoutActions.disableBlur());
			dispatch({ type: EDIT_MODE_HIDE });
		}
	};
}
