import Input from "@components/form/Input";
import {
	Label,
	UpdateUserProfile,
} from "@components/recipient/Recipient.styles";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

export const UpdateUserProfileCheckbox = () => {
	const { formatMessage } = useIntl();
	const { register, watch } = useFormContext();
	const updateUserProfileWatch = watch("updateUserProfile");

	return (
		<UpdateUserProfile
			aria-live="polite"
			className="update-user-profile"
			isChecked={updateUserProfileWatch}
		>
			<Input
				id="updateUserProfile"
				type="checkbox"
				hotJarSuppress={true}
				{...register("updateUserProfile")}
			/>
			<Label htmlFor="updateUserProfile">
				{formatMessage({
					id: "recipient.data.updateUserProfile.label",
				})}
			</Label>
		</UpdateUserProfile>
	);
};

export default UpdateUserProfileCheckbox;
