import { ALERT_LEVELS } from "@constants/constants";

import authFetch from "./utils/common/authFetch";
import getCheckoutId from "./utils/common/getCheckoutId";

export const ALERTS_ADD_ALERT = "ALERTS_ADD_ALERT";
export const ALERTS_REMOVE_ALL = "ALERTS_REMOVE_ALL";
export const ALERTS_REMOVE_ALERT = "ALERTS_REMOVE_ALERT";

export const dismissAlert = (role, message, id) => (dispatch) => {
	if (!id) {
		return dispatch(removeAlert(role, message));
	} else {
		return authFetch({
			url: `api/checkouts/${getCheckoutId()}/alerts/${id}`,
			method: "DELETE",
		})
			.then(dispatch(removeAlert(role, message)))
			.catch((error) => {
				if (error.status) {
					dispatch(
						addAlert(
							"checkout.general",
							ALERT_LEVELS.ERROR,
							"connectivity.error.genericError",
						),
					);
				} else {
					dispatch(
						addAlert("checkout.general", ALERT_LEVELS.ERROR, "network.lost"),
					);
				}
			});
	}
};

export const addAlert = (role, level, message, values) => (dispatch) => {
	dispatch({ type: ALERTS_ADD_ALERT, role, level, message, values });
};

export const removeAllAlerts = (role) => (dispatch) => {
	dispatch({ type: ALERTS_REMOVE_ALL, role });
};

export const removeAlert = (role, message) => (dispatch) => {
	dispatch({ type: ALERTS_REMOVE_ALERT, role, message });
};
