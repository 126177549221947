import { enableConfirmButton } from "@actions/checkout.actions";
import ShippingItemHeader from "@components/order/shipping/ShippingItemHeader";
import {
	CollapsablePanelHeaderRadio,
	CollapsiblePanel,
	CollapsiblePanelContent,
} from "@components/v2/components/collapsiblePanel/collapsiblePanel.styles";
import { Divider } from "@components/v2/components/dividers/Divider";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const ShippingMethod = ({
	shipping,
	isSelected,
	isExpanded,
	handleActivatePanel,
	isSectionDisabled,
	children,
}) => {
	const isLoading = useSelector((state) => state.checkout.isLoading);
	const isDisabled = (isLoading && !isSelected) || isSectionDisabled;

	return (
		<CollapsiblePanel
			id={shipping.typeCode}
			className={classNames(shipping.typeCode, "panel", {
				active: isSelected,
			})}
			key={shipping.typeCode}
			expanded={isExpanded}
			trigger={
				<CollapsablePanelHeaderRadio
					id={`${shipping.typeCode}-trigger`}
					name="ShippingMethod"
					value={shipping.typeCode}
					checked={isSelected}
					onChange={() => handleActivatePanel(shipping.typeCode)}
					label={
						<ShippingItemHeader
							shipping={shipping}
							isSelected={isSelected}
							enableConfirmButton={enableConfirmButton}
						/>
					}
					disabled={isDisabled}
				/>
			}
		>
			<Divider />
			<CollapsiblePanelContent className="content">
				{children}
			</CollapsiblePanelContent>
		</CollapsiblePanel>
	);
};

export default ShippingMethod;
