import FlexSection from "@components/payment/SubscriptionPayment/FlexSection";
import FinancePaymentMethod from "@components/payment/methods/financePaymentMethod/FinancePaymentMethod";
import { PAYMENT_METHOD_NAMES } from "@constants/constants";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import PaymentHeader from "./PaymentHeader";

const SubscriptionPaymentSection = () => {
	const { formatMessage } = useIntl();

	const isFairownSubscription = useSelector(
		(state) =>
			state.payment.subscriptionMethod.type ===
			PAYMENT_METHOD_NAMES.FAIROWN_SUBSCRIPTION,
	);
	const subscriptionMethod = useSelector(
		(state) => state.payment.subscriptionMethod,
	);

	return (
		<section
			className="section-payment-subscription"
			aria-label={formatMessage({
				id: "monthly.payment.section.title",
			})}
		>
			<h1>
				<PaymentHeader method={subscriptionMethod} />
			</h1>
			{isFairownSubscription ? (
				<FlexSection />
			) : (
				<FinancePaymentMethod
					type={subscriptionMethod.type}
					version={subscriptionMethod.version}
				/>
			)}
		</section>
	);
};

export default SubscriptionPaymentSection;
