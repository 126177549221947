import { setSwishPhoneNumber, setValidity } from "@actions/payment/methods.actions";
import Phone from "@components/form/Phone";
import {
	COUNTRY_CODES,
	PHONE_CODES,
	PAYMENT_METHOD_NAMES,
} from "@constants/constants";
import Validation from "@validation/Validation";
import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const validation = new Validation({
	phone: [{ name: "required" }, { name: "phone" }],
});

const SwishPaymentMethod = () => {
	const dispatch = useDispatch();

	const recipientPhoneNumber = useSelector((state) => state.recipient.data.phone);
	const isDisabled = useSelector((state) => (
		state.payment.isSectionDisabled || state.checkout.isDisabled
	));

	const [phoneError, setPhoneError] = useState("");
	const [phoneNumber, setPhoneNumber] = useState(recipientPhoneNumber);

	const handleOnChange = ({ target: { value } }) => {
		const validationResult = validation.validateSingleField("phone", value, {
			country: COUNTRY_CODES.SV,
		});
		setPhoneNumber(value);
		setPhoneError(validationResult.phone);
	};

	const setCorrectPhoneNumber = useCallback(
		(value) => {
			const invalidCodesPattern = new RegExp(
				`^(${PHONE_CODES.SV}0|0${PHONE_CODES.SV}|0)`,
			);
			const fixedCountryCode = value.replace(
				invalidCodesPattern,
				PHONE_CODES.SV,
			);

			if (fixedCountryCode.startsWith(PHONE_CODES.SV)) {
				dispatch(setSwishPhoneNumber(fixedCountryCode));
			} else {
				dispatch(setSwishPhoneNumber(
					`${PHONE_CODES.SV}${fixedCountryCode}`
				));
			}
		},
		[dispatch],
	);

	useEffect(() => {
		setPhoneNumber(recipientPhoneNumber)
	}, [recipientPhoneNumber])

	useEffect(() => {
		dispatch(setValidity(
			PAYMENT_METHOD_NAMES.SWISH,
			!phoneError
		));
		setCorrectPhoneNumber(phoneNumber);
	}, [phoneNumber, phoneError, setCorrectPhoneNumber, dispatch]);

	return (
		<form className="swish-method">
			<Phone
				name="phone"
				id="swishPhone"
				className="phone"
				type="tel"
				label={<FormattedMessage id="payment.swish.phone" />}
				disabled={isDisabled}
				defaultValue={recipientPhoneNumber}
				value={phoneNumber}
				error={phoneError}
				handleChange={handleOnChange}
				autoComplete="tel-national"
				automationid="swishPhoneNumber"
				pattern={`^\\d{0,${12}}$`}
			/>
		</form>
	);
};

export default SwishPaymentMethod;
