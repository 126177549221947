import ProductPrice from "@components/order/ProductPrice";
import { GTM_EVENT_NAMES, pushToDataLayer } from "@utils/GTMUtils";
import dispatchClarityEvent from "@utils/MicrosoftClarityUtils";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

import Discount from "./Discount";
import StockStatus from "./StockStatus";

const Product = ({
	name,
	quantity,
	price,
	monthlyPrice,
	image,
	productId,
	description,
	stock,
	discounts,
	offerPrice,
}) => {
	return (
		<tr
			className="product"
			data-automationid="product"
			data-automation-product-id={productId}
		>
			<td className={classNames("figure", { empty: !image })}>
				{image && (
					<FormattedMessage id="payment.image.alternativeText">
						{(msg) => (
							<img
								onClick={() => {
									dispatchClarityEvent("productImageClick");
									pushToDataLayer({
										data: {
											event: GTM_EVENT_NAMES.PRODUCT_IMAGE_CLICK,
											click: "product image click",
										},
									});
								}}
								className="image"
								src={image}
								alt={msg}
								width="80"
								height="60"
							/>
						)}
					</FormattedMessage>
				)}
			</td>
			<td className="name">
				<h2 className="heading" data-automationid="nameText">
					{name}{" "}
					<span data-automationid="quantityValue">
						({quantity}&nbsp;
						<FormattedMessage id="order.shop.item.count.unit" />)
					</span>
				</h2>
				{description && <div className="description">{description}</div>}
				{stock && (
					<StockStatus
						stringKey={stock.stringKey}
						params={stock.params}
						stockStatus={stock.stockStatus}
					/>
				)}
			</td>
			<td className="amount" data-automationid="productPrice">
				{monthlyPrice ? (
					<>
						<FormattedMessage
							id="monthlyMonetary"
							values={{ value: monthlyPrice }}
						/>
						{discounts?.map((discount, key) => (
							<Discount
								key={key}
								type={discount.type}
								amount={discount.amount}
							/>
						))}
					</>
				) : (
					<ProductPrice
						price={price}
						offerPrice={offerPrice}
						discounts={discounts}
					/>
				)}
			</td>
		</tr>
	);
};

Product.propTypes = {
	name: PropTypes.string,
	quantity: PropTypes.number,
	price: PropTypes.number,
	monthlyPrice: PropTypes.number,
	image: PropTypes.string,
	intl: PropTypes.object,
	productId: PropTypes.string,
	description: PropTypes.string,
	stock: PropTypes.object,
	discounts: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			amount: PropTypes.number,
		}),
	),
};

export default Product;
