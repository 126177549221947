import { ALERT_LEVELS } from "@constants/constants";
import { GTM_EVENT_NAMES, pushToDataLayerAsync } from "@utils/GTMUtils";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormattedHTMLMessage } from "react-intl";

import {
	Header,
	Wrapper,
	Content,
	Text,
	CloseButton,
	Icon,
} from "./Alerts.styles";
const Alert = ({
	message,
	values,
	id,
	role,
	canDismiss,
	level,
	onAlertClose,
	children,
	closeButtonLabel,
}) => {
	useEffect(() => {
		pushToDataLayerAsync({
			data: {
				event: GTM_EVENT_NAMES.ALERT_RAISED,
				alertRaised: message,
			},
		});
	}, [message, role]);

	const handleClick = (alertMessage, alertId) =>
		onAlertClose(alertMessage, alertId);

	return (
		<Wrapper data-testid="alert-wrapper" level={level.toLowerCase()}>
			<Header>
				<Icon />
			</Header>
			<Content>
				<Text>
					<FormattedHTMLMessage id={message} values={values} />
					{children}
				</Text>
			</Content>
			{level !== ALERT_LEVELS.ERROR && canDismiss && (
				<CloseButton
					data-testid="button-dismiss"
					type="button"
					onClick={() => handleClick(message, id)}
					aria-label={closeButtonLabel}
				/>
			)}
		</Wrapper>
	);
};

Alert.propTypes = {
	closeButtonLabel: PropTypes.string,
	message: PropTypes.string,
	values: PropTypes.object,
	id: PropTypes.string,
	canDismiss: PropTypes.bool,
	level: PropTypes.oneOf(Object.values(ALERT_LEVELS)),
	onAlertClose: PropTypes.func,
	role: PropTypes.string,
	dismissAlert: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export { Alert };
