import ScrollObserver from "@utils/scrollObserver";
import { useEffect, useRef } from "react";

export const useElementObserver = (ref, callback) => {
	const elementToObserve = useRef(null);

	useEffect(() => {
		if (ref?.current?.id) {
			elementToObserve.current = ref.current;
		}
	}, [ref, callback]);

	useEffect(() => {
		if (elementToObserve.current) {
			const isElementObserved = ScrollObserver.isElementObserved(
				elementToObserve.current,
			);

			!isElementObserved &&
				ScrollObserver.registerElement(elementToObserve.current, callback);
		}
	}, [callback]);

	useEffect(
		() => () => {
			const isElementObserved = ScrollObserver.isElementObserved(
				elementToObserve.current,
			);

			if (!isElementObserved) {
				ScrollObserver.unregisterElement(
					ScrollObserver.isElementObserved(elementToObserve.current),
				);
			}
		},
		[],
	);
};
