import * as AlertActions from "@actions/alert.actions";
import * as CheckoutActions from "@actions/checkout.actions";

const defaultState = {
	"checkout.general": {},
	"checkout.recipient.general": {},
	"checkout.order.general": {},
	"checkout.payment.general": {},
	"checkout.shipping.general": {},
	"checkout.payment.creditCard": {},
	"checkout.payment.creditcard.new": {},
	"checkout.payment.giftCard": {},
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case CheckoutActions.CHECKOUT_SET_INITIAL_DATA:
			return {
				...state,
				"checkout.general": {
					...state["checkout.general"],
					...action.data.alerts,
				},
			};

		case CheckoutActions.CHECKOUT_FETCH_DONE: {
			const mapAlerts = (alertContainer) =>
				alertContainer && alertContainer.alerts
					? alertContainer.alerts.reduce(
							(accumulator, alert) => ({
								...accumulator,
								[alert.message]: alert,
							}),
							{},
					  )
					: {};

			return {
				...state,
				"checkout.general": mapAlerts(action.json),
				"checkout.recipient.general": mapAlerts(action.json.recipient),
				"checkout.order.general": mapAlerts(action.json.order),
				"checkout.payment.general": mapAlerts(action.json.payment),
			};
		}

		case AlertActions.ALERTS_ADD_ALERT:
			return {
				...state,
				[action.role]: {
					...state[action.role],
					[action.message]: {
						level: action.level,
						message: action.message,
						values: action.values,
					},
				},
			};

		case AlertActions.ALERTS_REMOVE_ALL: {
			const newState = { ...state };

			if (newState[action.role]) {
				newState[action.role] = {};
			}

			return newState;
		}

		case AlertActions.ALERTS_REMOVE_ALERT: {
			const newAlerts = { ...state[action.role] };

			delete newAlerts[action.message];

			return { ...state, [action.role]: newAlerts };
		}

		default:
			return state;
	}
};
