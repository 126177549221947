import Offer from "@components/order/offer/Offer";
import React from "react";
import { FormattedMessage } from "react-intl";

const OrderItemsTable = ({ offers, title }) => (
	<>
		<h1 className="title">
			{title}
		</h1>
		<table className="items-listing">
			<thead className="header">
			<tr>
				<th>
					<FormattedMessage id="product.image" />
				</th>
				<th>
					<FormattedMessage id="product.name" />
				</th>
				<th>
					<FormattedMessage id="product.price" />
				</th>
			</tr>
			</thead>
			{offers.map((offer) => (
				<Offer key={offer.id} {...offer} />
			))}
		</table>
	</>
);

export default OrderItemsTable;
