import authFetch from "../common/authFetch";
import getCheckoutId from "../common/getCheckoutId";

const findCityByPostcode = (postcode) => {
	const fetchMethod = authFetch;
	return fetchMethod({
		url: `api/checkouts/${getCheckoutId()}/recipient/cities/${postcode}`,
		options: { cache: "default" },
	})
		.then((response) => response.json())
		.then((response) => {
			if (typeof response === "string") {
				return response;
			}
			return null;
		})
		.catch((error) => {
			throw error.body._error;
		});
};

export { findCityByPostcode };
