import Link from "@components/v2/components/link/Link.styles";
import {
	MessageBold,
	MessageGroup,
	MessageHeader,
	MessageItalic,
	MessageParagraph,
	MessageWrapper,
} from "@components/v2/components/payments/methods/financePaymentMethodInfo/FinancePaymentMethodInfo.styles";
import { useInfoTextString } from "@components/v2/components/payments/methods/financePaymentMethodInfo/useFinancePaymentMethodInfo";
import React, { useRef } from "react";
import { useIntl } from "react-intl";

const PaymentMethodDescription = ({
	type,
	paymentPlan,
	cost,
	code,
	options,
}) => {
	const renderKey = useRef(1);

	const { id, values } = useInfoTextString({
		paymentPlan,
		cost,
		code,
		options,
		type,
	});
	const { formatMessage } = useIntl();

	return formatMessage(
		{ id },
		{
			...values,
			article: (...str) => (
				<MessageWrapper key={++renderKey.current}>{str}</MessageWrapper>
			),
			div: (...str) => (
				<MessageGroup key={++renderKey.current}>{str}</MessageGroup>
			),
			h6: (...str) => (
				<MessageHeader key={++renderKey.current}>{str}</MessageHeader>
			),
			p: (...str) => (
				<MessageParagraph key={++renderKey.current}>{str}</MessageParagraph>
			),
			strong: (...str) => (
				<MessageBold key={++renderKey.current}>{str}</MessageBold>
			),
			i: (...str) => (
				<MessageItalic key={++renderKey.current}>{str}</MessageItalic>
			),
			a: (...str) => (
				<Link
					key={++renderKey.current}
					target="_blank"
					href={formatMessage({
						id: "payment.methods.FairownSubscription.paymentPlan.link",
					})}
				>
					{str}
				</Link>
			),
		},
	);
};

export default PaymentMethodDescription;
