import { ReactComponent as GreenLeaf } from "@assets/img/green-leaf.svg";
import { ReactComponent as BlushLogo } from "@assets/img/transport/Blush.svg";
import { ReactComponent as BringLogo } from "@assets/img/transport/Bring.svg";
import BudbeeLogo from "@assets/img/transport/Budbee.png";
import { ReactComponent as KomplettLogo } from "@assets/img/transport/Komplett.svg";
import { ReactComponent as PorterBuddyLogo } from "@assets/img/transport/PorterBuddy.svg";
import { ReactComponent as PostenLogo } from "@assets/img/transport/Posten.svg";
import { ReactComponent as PostNordLogo } from "@assets/img/transport/Postnord.svg";
import { TRANSPORTS } from "@constants/constants";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";

const ShippingItem = ({
	name,
	className,
	label,
	header,
	subHeader,
	greenDeliveryLabel,
	price,
	hours,
	estimatedDeliveryDate,
	estimatedDeliveryTime,
	transport = [],
	postCode,
	street,
	children,
	expirationDate,
	isSelected,
}) => {
	const intlHook = useIntl();
	const priceLabel =
		intlHook.formatMessage({ id: "order.headings.price" }) + " " + price;

	const transportLogoMap = {
		[TRANSPORTS.BUDBEE]: (
			<img src={BudbeeLogo} data-testid="Budbee" alt="Budbee" />
		),
		[TRANSPORTS.BLUSH]: <BlushLogo data-testid="Blush" />,
		[TRANSPORTS.BRING]: <BringLogo data-testid="Bring" />,
		[TRANSPORTS.KOMPLETT]: <KomplettLogo data-testid="Komplett" />,
		[TRANSPORTS.PORTERBUDDY]: <PorterBuddyLogo data-testid="PorterBuddy" />,
		[TRANSPORTS.POSTEN]: <PostenLogo data-testid="Posten" />,
		[TRANSPORTS.POSTNORD]: <PostNordLogo data-testid="PostNord" />,
	};

	return (
		<div
			className={classNames("shipping-item optionContent", className, {
				"has-expire-date": expirationDate,
			})}
		>
			{header && (
				<div
					className="method-option-header"
					data-automationid="shippingMethodHours"
				>
					<span>{header}</span>
					<br />
					{subHeader && <strong>{subHeader}</strong>}
				</div>
			)}
			{label && (
				<span
					className={classNames("method-name", name, {
						active: isSelected,
					})}
					data-automationid="shippingMethodName"
				>
					<span className="label">{label}</span>
					{street && (
						<span className="address">
							{postCode}, {street}
						</span>
					)}
					{estimatedDeliveryDate && (
						<div className="estimated-delivery">
							<span>{estimatedDeliveryDate}</span>
							{estimatedDeliveryTime && (
								<span className="estimated-delivery-time">
									. {estimatedDeliveryTime}
								</span>
							)}
						</div>
					)}
				</span>
			)}
			{hours && (
				<span className="method-hours" data-automationid="shippingMethodHours">
					{hours}
				</span>
			)}
			{price >= 0 && (
				<span
					className="method-price"
					aria-label={priceLabel}
					data-automationid="shippingMethodPrice"
				>
					{children}
				</span>
			)}
			{transport.length > 0 && (
				<span className="transports">
					{transport.map((transportName, i) => {
						return (
							transportName && (
								<Fragment key={i}>{transportLogoMap[transportName]}</Fragment>
							)
						);
					})}
				</span>
			)}
			{greenDeliveryLabel && (
				<div className="green-delivery">
					<GreenLeaf className="green-delivery-logo" /> {greenDeliveryLabel}
				</div>
			)}
		</div>
	);
};

ShippingItem.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	price: PropTypes.number,
	transport: PropTypes.array,
	postCode: PropTypes.string,
	street: PropTypes.string,
	hours: PropTypes.string,
	children: PropTypes.node,
	expirationDate: PropTypes.string,
	isSelected: PropTypes.bool,
};

export default ShippingItem;
