import * as CheckoutActions from "@actions/checkout.actions";
import * as PaymentMethodsActions from "@actions/payment/methods.actions";
import { STATUS_NAMES } from "@constants/constants";

const defaultState = {
	giftCard: {},
	giftCards: [],
	ssn: null,
	isPaymentSectionValid: false,
	isPaymentSubscriptionSectionValid: false,
	isValid: false,
	isSplitPayment: false,
	isHidden: false,
	isSectionDisabled: false,
	directMethodsOrder: [],
	directMethods: {},
	subscriptionMethod: null,
	selectedMethod: null,
	totalCost: null,
	showgiftCardLoader: false,
	isDeletingGiftCard: false,
	swishPhoneNumber: "",
};

const payment = (state = defaultState, action) => {
	switch (action.type) {
		case CheckoutActions.CHECKOUT_FETCH_DONE:
			return {
				...state,
				isHidden:
					!action.json.payment ||
					action.json.status === STATUS_NAMES.EXPIRED ||
					action.json.status === STATUS_NAMES.PLACED ||
					action.json.order.logisticUnits?.length <= 0,
				isSplitPayment:
					!!action.json.payment?.directPayment &&
					!!action.json.payment?.subscriptionPayment,
			};

		case PaymentMethodsActions.SELECT_PAYMENT_METHOD_REQUESTED:
			return {
				...state,
				selectedMethod: action.methodType,
			};

		case PaymentMethodsActions.SELECT_PAYMENT_METHOD_REVERTED:
			return {
				...state,
				selectedMethod: action.revertToMethodType,
			};

		case PaymentMethodsActions.PAYMENT_METHOD_OPTION_CHANGE:
			return {
				...state,
				directMethods: {
					...state.directMethods,
					[action.methodType]: {
						...state.directMethods[action.methodType],
						selectedOptionCode: action.optionCode,
					},
				},
			};

		case PaymentMethodsActions.PAYMENT_METHOD_VALIDITY_SET:
			return {
				...state,
				directMethods: {
					...state.directMethods,
					[action.methodType]: {
						...state.directMethods[action.methodType],
						isValid: action.isValid,
					},
				},
			};

		case PaymentMethodsActions.PAYMENT_FAIROWN_CONSENT_CHECKBOX_SET:
			return {
				...state,
				subscriptionMethod: {
					...state.subscriptionMethod,
					consent: {
						isConsentChecked: action.payload.checked,
						content: action.payload.content,
					},
				},
			};

		case PaymentMethodsActions.PAYMENT_SECTION_VALIDITY_SET:
			return {
				...state,
				isPaymentSectionValid: action.isValid,
				isValid: state.isSplitPayment
					? state.isPaymentSubscriptionSectionValid && action.isValid
					: action.isValid,
			};

		case PaymentMethodsActions.PAYMENT_SUBSCRIPTION_SECTION_VALIDITY_SET:
			return {
				...state,
				isPaymentSubscriptionSectionValid: action.isValid,
				isValid: state.isSplitPayment
					? state.isPaymentSectionValid && action.isValid
					: action.isValid,
			};

		case PaymentMethodsActions.PAYMENT_METHOD_OPTION_UPDATE: {
			return {
				...state,
				directMethods: {
					...state.directMethods,
					[action.methodType]: {
						...state.directMethods[action.methodType],
						options: {
							...state.directMethods[action.methodType].options,
							[action.optionCode]: {
								...state.directMethods[action.methodType].options[
									action.optionCode
								],
								...action.optionBody,
							},
						},
					},
				},
			};
		}

		case PaymentMethodsActions.SET_BONUS_POINTS: {
			return {
				...state,
				bonusPoints: action.bonusPoints,
			};
		}

		case PaymentMethodsActions.SET_GIFT_CARD_DATA:
			return {
				...state,
				giftCards: action.payload.giftCardData,
			};

		case PaymentMethodsActions.ENABLE_GIFT_CARD:
			return {
				...state,
				giftCard: {
					...state.giftCard,
					isEnabled: action.payload.giftCardIsEnabled,
				},
			};

		case PaymentMethodsActions.REMOVE_GIFT_CARD_REQUESTED:
			return {
				...state,
				isDeletingGiftCard: true,
				giftCards: state.giftCards.filter(
					(giftCard) => giftCard.code !== action.code,
				),
			};

		case PaymentMethodsActions.REMOVE_ALL_GIFT_CARD_REQUESTED:
			return {
				...state,
				giftCards: [],
			};

		case PaymentMethodsActions.REMOVE_GIFT_CARD_DONE:
			return {
				...state,
				isDeletingGiftCard: false,
			};

		case PaymentMethodsActions.REMOVE_SUBSCRIPTION_PAYMENT_METHOD:
			return {
				...state,
				subscriptionMethod: null,
			};

		case PaymentMethodsActions.REMOVE_DIRECT_PAYMENT_METHOD:
			return {
				...state,
				directMethodsOrder: [],
				directMethods: {},
			};

		case PaymentMethodsActions.SET_SUBSCRIPTION_PAYMENT_METHOD: {
			const _options = {};
			const _optionsOrder = [];
			action.payload.subscriptionMethod.options.forEach((option) => {
				_optionsOrder.push(option.code);
				_options[option.code] = option;
			});

			return {
				...state,
				isPaymentSubscriptionSectionValid:
					state.isPaymentSubscriptionSectionValid,
				subscriptionMethod: {
					...action.payload.subscriptionMethod,
					consent: {
						isConsentChecked:
							state.subscriptionMethod?.consent?.isConsentChecked || false,
						description: state.subscriptionMethod?.consent?.description || "",
					},
					options: _options,
					optionsOrder: _optionsOrder,
					selectedOptionCode: _optionsOrder[0],
				},
			};
		}

		case PaymentMethodsActions.SET_PAYMENT_METHODS: {
			const _directMethods = {};
			const _directMethodsOrder = [];

			action.payload.methods.forEach((method) => {
				_directMethodsOrder.push(method.type);
				const _options = {};
				const _optionsOrder = [];

				method.options.forEach((option) => {
					_optionsOrder.push(option.code);
					_options[option.code] = option;
				});

				const newMethod = {
					...method,
					options: _options,
					optionsOrder: _optionsOrder,
				};

				const stateSelectedOption =
					state.directMethods[method.type] &&
					state.directMethods[method.type].selectedOptionCode;

				// We need to re-check if selectedOption has not been removed from the options that come from the API.
				// This might happen in an edge-case where applied gift card prevents availability of some options.
				newMethod.selectedOptionCode =
					stateSelectedOption && newMethod.options[stateSelectedOption]
						? stateSelectedOption
						: method.selectedOptionCode || newMethod.optionsOrder[0];
				_directMethods[method.type] = newMethod;
			});

			return {
				...state,
				directMethods: _directMethods,
				directMethodsOrder: _directMethodsOrder,
				selectedMethod:
					(!!_directMethods[state.selectedMethod] && state.selectedMethod) ||
					action.payload.selectedMethod,
			};
		}

		case PaymentMethodsActions.PAYMENT_SSN_SET:
			return {
				...state,
				ssn: action.ssn,
			};

		case PaymentMethodsActions.APPLY_GIFT_CARD_REQUESTED:
			return {
				...state,
				showgiftCardLoader: true,
			};

		case PaymentMethodsActions.APPLY_GIFT_CARD_DONE:
			return {
				...state,
				showgiftCardLoader: false,
			};

		case PaymentMethodsActions.SET_SWISH_PHONE_NUMBER:
			return {
				...state,
				swishPhoneNumber: action.payload.swishPhoneNumber,
			};

		default:
			return state;
	}
};

export default payment;
