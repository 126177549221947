import React from "react";
import { StyledBreak } from "./Break.styles";

interface BreakProps {
  spacing?: number | undefined;
}

export const Break: React.FC<BreakProps> = (props) => {
  return <StyledBreak spacing={props.spacing} />;
};

export default Break;
