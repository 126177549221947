import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const InfoWrapper = styled.div(
	({ theme: { breakpoints }, isFairownSubscription }) => css`
		font-size: 14px;
		line-height: 130%;
		margin: 8px ${isFairownSubscription ? "8px" : "0"};
		padding: 0;
		background: none;

		@media (min-width: ${breakpoints.md}) {
			margin: 8px 0;
		}
	`,
);

export const MessageWrapper = styled.article`
	display: grid;
	grid-gap: 12px;
`;

export const MessageGroup = styled.div``;

export const MessageHeader = styled.h6`
	font-weight: 700;
	line-height: 140%;
`;

export const MessageParagraph = styled.p`
	font-weight: 400;
	line-height: 130%;
`;

export const MessageBold = styled.strong`
	font-weight: 700;
`;

export const MessageItalic = styled.i`
	font-style: italic;
`;
