import Agreement from "@components/payment/SubscriptionPayment/Agreement";
import Choices from "@components/payment/SubscriptionPayment/Choices";
import ConsentCheckbox from "@components/payment/SubscriptionPayment/ConsentCheckbox";
import Details from "@components/payment/SubscriptionPayment/Details";
import Faq from "@components/payment/SubscriptionPayment/Faq";
import FinanceInformation from "@components/payment/SubscriptionPayment/FinanceInformation";
import MarketingConsent from "@components/payment/SubscriptionPayment/MarketingConsent";
import Title from "@components/payment/SubscriptionPayment/Title";
import React from "react";
import { useSelector } from "react-redux";

const FlexSection = () => {
	const period = 24;

	const fairownPricingValues = useSelector(
		(state) => state.payment?.subscriptionMethod.options[period].paymentPlan,
	);
	const residualValue = useSelector(
		(state) => state.payment?.subscriptionMethod.options[period].residualValue,
	);
	const secciUrl = useSelector(
		(state) =>
			state.payment?.subscriptionMethod.options[period].paymentPlan.secci,
	);

	const numberOfTermsLeftAfterRenewalPeriod =
		fairownPricingValues.numberOfTerms - period;

	return (
		<section className="flex-section">
			<section className="title-section">
				<Title monthlyCost={fairownPricingValues.monthlyCost} />
				<span className="divider" />
				<Details
					effectiveInterestRate={fairownPricingValues.effectiveInterestRate}
					residualValue={residualValue}
					period={period}
				/>
			</section>
			<Agreement
				totalCost={fairownPricingValues.totalCost}
				numberOfTerms={fairownPricingValues.numberOfTerms}
				creditCost={fairownPricingValues.creditCost}
				totalCreditCost={fairownPricingValues.totalCreditCost}
			/>
			<Choices
				numberOfTermsLeftAfterRenewalPeriod={
					numberOfTermsLeftAfterRenewalPeriod
				}
				monthlyCost={fairownPricingValues.monthlyCost}
				residualValue={residualValue}
				period={period}
			/>
			<Faq />
			<FinanceInformation secciUrl={secciUrl} />
			<span className="divider" />
			<ConsentCheckbox />
			<MarketingConsent />
		</section>
	);
};

export default FlexSection;
