import { DIVIDER_VARIANTS } from "@components/v2/constants";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const getDividerBorderStyle = (variant) =>
	variant === DIVIDER_VARIANTS.SOLID ? "solid" : "dashed";

export const Divider = styled.div(
	({
		theme: { colors },
		variant = DIVIDER_VARIANTS.SOLID,
	}) => css`
		border-bottom: 1px ${getDividerBorderStyle(variant)} ${colors.divider};
		border-right: 1px ${getDividerBorderStyle(variant)} ${colors.divider};
	`,
);
