import * as CheckoutActions from "@actions/checkout.actions";
import * as RecipientActions from "@actions/recipient.actions";
import * as SummaryActions from "@actions/summary.actions";
import { STATUS_NAMES } from "@constants/constants";

const defaultState = {
	isHidden: false,
	isFetching: false,
	isSending: false,
	sellingPoints: [],
	items: [],
	directPaymentSummary: null,
	subscriptionPaymentSummary: null,
	wholeCartDiscounts: [],
	totalVat: null,
};

const summary = (state = defaultState, action) => {
	switch (action.type) {
		case CheckoutActions.CHECKOUT_FETCH_DONE:
			return {
				...state,
				...action.json.summary,
				directPaymentSummary: action.json.summary.directPaymentSummary,
				subscriptionPaymentSummary: action.json.summary.subscriptionPaymentSummary,
				wholeCartDiscounts: action.json.summary.wholeCartDiscounts,
				totalVat: action.json.summary.totalVat,
				isHidden:
					action.json.payment === null ||
					action.json.status === STATUS_NAMES.EXPIRED ||
					action.json.status === STATUS_NAMES.PLACED ||
					!(action.json.order.logisticUnits?.length > 0),
			};

		case RecipientActions.RECIPIENT_SAVE_REQUESTED:
			return {
				...state,
				isFetching: true,
			};

		case RecipientActions.RECIPIENT_SAVE_DONE:
		case RecipientActions.RECIPIENT_SAVE_FAILED:
		case RecipientActions.RECIPIENT_SAVE_CANCELLED:
			return {
				...state,
				isFetching: false,
			};

		case SummaryActions.ORDER_PLACE_REQUESTED:
			return {
				...state,
				isSending: true,
			};

		case SummaryActions.ORDER_PLACE_DONE:
		case SummaryActions.ORDER_PLACE_FAILED:
			return {
				...state,
				isSending: false,
			};

		default:
			return state;
	}
};

export default summary;
