import { disableExpiredCheckout } from "@actions/checkout.actions";
import AlertsContainer from "@components/alerts/AlertsContainer";
import classNames from "classnames";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

dayjs.extend(duration);

//TODO: check why displaying 60 min instead 1 hour.
const Countdown = ({ onCountdownStarted, isSelected, expiresAt }) => {
	const [currentHours, setCurrentHours] = useState(0);
	const [currentMinutes, setCurrentMinutes] = useState(0);
	const [loading, setLoading] = useState(true);
	const [urgency, setUrgency] = useState(false);
	const [shouldShow, setShouldShow] = useState(false);
	const intervalId = useRef(null);
	const expiresAtPrev = useRef(null);
	const isExpiredErrorVisible = useSelector(
		(state) =>
			!!Object.keys(state.alerts?.["checkout.shipping.countdown"] || []).length,
	);
	const dispatch = useDispatch();

	const timer = useCallback(
		(expirationDate) => {
			const remaining = dayjs.duration(dayjs(expirationDate).diff(dayjs()));
			const remainingSeconds = Math.ceil(remaining.asSeconds());
			const shouldShow = remainingSeconds >= 0 && remainingSeconds <= 36000; // 36000 === 10 hours
			const isUrgent = remainingSeconds > 0 && remainingSeconds <= 1800; // 1800 === 30 minutes
			const hasExpired = remainingSeconds <= 0;

			if (loading) {
				onCountdownStarted();
			}

			if (hasExpired) {
				clearInterval(intervalId.current);
				dispatch(
					disableExpiredCheckout({
						lockConfirmButton: isSelected,
					}),
				);
			}

			setUrgency(isUrgent);
			setShouldShow(shouldShow);
			setLoading(false);
			setCurrentMinutes(toDisplayMinutes(remainingSeconds));
			setCurrentHours(toDisplayHours(remainingSeconds));
		},
		[dispatch, isSelected, loading, onCountdownStarted],
	);

	// Run the timer on mount to avoid initial delay of setInterval.
	useEffect(() => {
		timer(expiresAt);
	}, [expiresAt, timer]);

	const toDisplayHours = (seconds) =>
		seconds < 0 ? 0 : Math.floor(seconds / 3600);

	const toDisplayMinutes = (seconds) =>
		seconds < 0 ? 0 : Math.ceil((seconds % 3600) / 60);

	useEffect(() => {
		const shouldClearInterval =
			intervalId.current && expiresAtPrev.current !== expiresAt;

		if (shouldClearInterval) {
			clearInterval(intervalId.current);
			setLoading(true);
			intervalId.current = null;
			expiresAtPrev.current = expiresAt;
		}

		if (!intervalId.current) {
			expiresAtPrev.current = expiresAt;
			intervalId.current = setInterval(() => timer(expiresAt), 1000);
		}
	}, [expiresAt, timer]);

	useEffect(() => () => clearInterval(intervalId.current), []);

	return (
		<div className="countdown">
			{loading && <div data-testid="loader" className="loader" />}
			{!loading && shouldShow && (
				<span className={classNames("countdown-content", { urgent: urgency })}>
					<FormattedHTMLMessage
						id="porterbuddy.delivery.countdown"
						values={{ currentHours, currentMinutes }}
					/>
				</span>
			)}
			{isSelected && isExpiredErrorVisible && (
				<AlertsContainer role="checkout.shipping.countdown" />
			)}
		</div>
	);
};

Countdown.propTypes = {
	expiresAt: PropTypes.string,
	isSelected: PropTypes.bool,
	onCountdownStarted: PropTypes.func,
};

export default Countdown;
