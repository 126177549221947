/*
* Rules:
* The tags can be any alphanumeric value you like including spaces. For example, 'Checkout Page'.
*The tag and its value can't be longer than 255 characters.
A single page can have no more than 128 tags. Any other tags will be ignored.
* */
const dispatchClarityEvent = (value) => {
	window?.clarity?.("event", value);
};

export default dispatchClarityEvent;
