import * as CheckoutActions from "@actions/checkout.actions";

const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case CheckoutActions.CHECKOUT_FETCH_DONE:
			return {
				...state,
				...action.json.rolloutFeatures,
			};
		default:
			return { ...state };
	}
};
