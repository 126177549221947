import { TextInput } from "@components/recipient/Recipient.styles";
import {
	requiredMessage,
	validatePhoneNumber,
} from "@components/recipient/recipientForm/recipientFormValidation";
import { PHONE_NUMBER_LENGTHS } from "@constants/constants";
import React from "react";
import { useController } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const PhoneInput = () => {
	const { formatMessage } = useIntl();

	const country = useSelector((state) => state.checkout.storeLanguage);
	const isSaving = useSelector((state) => state.recipient.isSaving);

	const {
		field: phoneField,
		formState: { errors },
	} = useController({
		name: "phone",
		rules: {
			required: requiredMessage,
			validate: {
				validatePhoneNumber: (value) => validatePhoneNumber(value, country),
			},
		},
	});

	const handlePaste = (e) => {
		e.preventDefault();

		const text = (e.originalEvent || e).clipboardData.getData("text/plain");

		const isValueNotNumber = /[^0-9 ]/g.test(text);

		if (isValueNotNumber) {
			return;
		}

		phoneField.onChange({
			target: {
				name: "phone",
				value: text.replaceAll(" ", ""),
			},
		});
	};

	return (
		<TextInput
			id="phone"
			type="tel"
			autoComplete="tel-national"
			placeholder={formatMessage({
				id: "recipient.data.phone.placeholder",
			})}
			maxLength={PHONE_NUMBER_LENGTHS[country]}
			disabled={isSaving}
			isInvalid={!!errors.phone}
			ariaInvalid={!!errors.phone}
			hotJarSuppress={true}
			onChange={phoneField.onChange}
			onPaste={handlePaste}
			onBlur={phoneField.onBlur}
			value={phoneField.value}
			name={phoneField.name}
			inputRef={phoneField.ref}
		/>
	);
};

export default PhoneInput;
