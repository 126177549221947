import { findCityByPostcode } from "@actions/utils/recipient/findCityByPostcode";
import { MaskedTextInput } from "@components/recipient/Recipient.styles";
import { POSTCODE_MASKS } from "@components/recipient/constants";
import {
	requiredMessage,
	validatePostCodeLength,
} from "@components/recipient/recipientForm/recipientFormValidation";
import React, { useCallback, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";

const PostcodeInput = () => {
	const { formatMessage } = useIntl();

	const country = useSelector((state) => state.checkout.storeLanguage);

	const initialCity = useSelector((state) => state.recipient.data.city);
	const isSaving = useSelector((state) => state.recipient.isSaving);

	const {
		setError,
		setValue,
		watch,
		formState: { errors },
	} = useFormContext();

	const postcodeWatch = watch("postcode");

	const [debouncedPostcode] = useDebounce(postcodeWatch, 50);

	const getPostCodeMask = () => POSTCODE_MASKS[country];

	const setCity = useCallback(async () => {
		const isPostCodeInvalid = debouncedPostcode.includes("_");

		if (!debouncedPostcode || isPostCodeInvalid) {
			return;
		}

		try {
			const city = await findCityByPostcode(debouncedPostcode, true);
			const hasCityChanged = city === initialCity;

			setValue("city", city, {
				shouldValidate: true,
				shouldDirty: !hasCityChanged,
			});
		} catch (e) {
			setError("postcode", {
				type: "Invalid postcode",
				message: formatMessage({ id: e }),
			});
		}
	}, [formatMessage, initialCity, debouncedPostcode, setError, setValue]);

	useEffect(() => {
		setCity();
	}, [setCity]);

	return (
		<Controller
			name="postcode"
			rules={{
				required: requiredMessage,
				validate: {
					validatePostCodeLength: (value) =>
						validatePostCodeLength(value, country),
				},
			}}
			render={({ field }) => {
				return (
					<MaskedTextInput
						id="postcode"
						disabled={isSaving}
						isInvalid={!!errors.postcode}
						aria-invalid={!!errors.postcode}
						hotJarSuppress={true}
						mask={getPostCodeMask()}
						{...field}
					/>
				);
			}}
		/>
	);
};

export default PostcodeInput;
