export default function handleErrors(response) {
	return new Promise((resolve, reject) => {
		if (!response.ok) {
			response
				.json()
				.then((json) => {
					return reject({
						status: response.status,
						body: json,
					});
				})
				.catch((error) => {
					return reject({
						status: response.status,
						body: error,
					});
				});
		} else {
			return resolve(response);
		}
		return null;
	});
}
