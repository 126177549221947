import authFetch from "@actions/utils/common/authFetch";
import getCheckoutId from "@actions/utils/common/getCheckoutId";

import { delay, throwAfter } from "./delayPromise";

export const GTM_EVENT_NAMES = Object.freeze({
	SHIPPING_VISITED: "shipping_visited",
	PAYMENT_VISITED: "payment_visited",
	PAYMENT_METHOD_DEFAULT: "payment_method_default",
	PAYMENT_METHOD_SELECTED: "payment_method_selected",
	DELIVERY_METHOD_DEFAULT: "delivery_method_default",
	DELIVERY_METHOD_SELECTED: "delivery_method_selected",
	ALERT_RAISED: "alert_raised",
	EXTRA_SERVICES_VISITED: "extra_services_visited",
	EXTRA_SERVICES_SELECTED: "extra_services_selected",
	PRODUCT_IMAGE_CLICK: "product_image_click",
});

const validateGTMEvent = ({ event, ...eventData }) => ({
	event:
		Object.values(GTM_EVENT_NAMES).includes(event) ||
		event === "checkoutLoadedEvent"
			? event
			: `Unsupported event ${event}`,
	app: "KomPay",
	...eventData,
});

export const setInitialAnalyticsSent = () =>
	authFetch({
		url: `api/checkouts/${getCheckoutId()}/analytics/initial`,
		method: "PUT",
	});

export const pushToDataLayer = ({ data }) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		...validateGTMEvent(data),
	});
};

export const pushToDataLayerAsync = ({ data, throwAfterFailed = false }) =>
	Promise.race([
		new Promise((resolve) => {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				...validateGTMEvent(data),
				eventCallback: resolve,
			});
		}),
		throwAfterFailed
			? throwAfter({ message: "Initial push to datalayer failed" })
			: delay(),
	]);

export const sendPaymentEvent = (action, label) =>
	pushToDataLayerAsync({ data: { event: "PaymentsEvent", action, label } });
export const sendLeasingEvent = (action, label) =>
	pushToDataLayerAsync({ data: { event: "LeasingEvent", action, label } });
