const selectPaymentMethodVisibility = (state) => {
	const methodsOrder = state.payment.directMethodsOrder;
	const selectedMethod = state.payment.selectedMethod;

	const isCoveredByGiftCardsAndBonusPoints = state.checkout.isCoveredByGiftCardsAndBonusPoints;
	const isCoveredByBonusPoints = state.checkout.isCoveredByBonusPoints;
	const isCoveredByGiftCard = state.checkout.isCoveredByGiftCard;
	const isGiftCardEnabled = state.payment.giftCard.isEnabled

	const isSubscriptionMethodVisible = !!state.payment.subscriptionMethod;

	const hasDirectPaymentMethods = !!methodsOrder.length;

	const isDirectPaymentCovered =
		isCoveredByGiftCard ||
		isCoveredByBonusPoints ||
		isCoveredByGiftCardsAndBonusPoints;

	const isDirectPaymentMethodsVisible =
		hasDirectPaymentMethods && !isDirectPaymentCovered;

	const isGiftCardVisible =
		isGiftCardEnabled && !isCoveredByBonusPoints && selectedMethod;

	const isCoveredByBonusVisible =
		(isCoveredByBonusPoints || isCoveredByGiftCardsAndBonusPoints) &&
		!isCoveredByGiftCard &&
		selectedMethod;

	return {
		isDirectPaymentMethodsVisible,
		isGiftCardVisible,
		isCoveredByBonusVisible,
		isSubscriptionMethodVisible
	};
};

export default selectPaymentMethodVisibility;
