import { StyledButton } from "@components/v2/components/buttons/button.styles";
import { BUTTON_TYPES } from "@components/v2/constants";
import classNames from "classnames";
import React from "react";

const Button = ({
	children,
	type = BUTTON_TYPES.BUTTON,
	className,
	id,
	disabled,
	title,
	variant,
	rounded,
	onClick = () => {},
}) => (
	<StyledButton
		id={id}
		className={classNames(className, variant)}
		type={type}
		disabled={disabled}
		title={title}
		rounded={rounded}
		onClick={onClick}
		data-testid={`${id}.button`}
		data-automationid={`${id}.button`}
	>
		{children}
	</StyledButton>
);

export default Button;
