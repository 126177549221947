import PropTypes from "prop-types";
import React from "react";

const TextArea = ({
	onChange,
	pattern,
	className,
	placeholder,
	fieldValidation,
	value,
}) => {
	const handleOnChange = ({ target: { value } }) => {
		if (value.match(pattern)) {
			onChange(value);
		}
	};

	return (
		<div className={className}>
			<textarea
				{...fieldValidation}
				value={value}
				placeholder={placeholder}
				onChange={handleOnChange}
				className="field"
			/>
		</div>
	);
};

TextArea.propTypes = {
	onChange: PropTypes.func,
	className: PropTypes.string,
	fieldValidation: PropTypes.object,
	value: PropTypes.string,
	pattern: PropTypes.instanceOf(RegExp),
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TextArea;
