import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const StatusContainer = styled.div`
	font-size: 0.8em;
	line-height: 1.3em;
	margin-top: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const STATUSES = {
	instock: "#01c200",
	prebook: "#0074bd",
	incomingstock: "linear-gradient(135deg, #ffdf00 50%, #01c200 50%)",
	outofstock: "#ffdf00",
};

export const StatusIcon = styled.div(
	({ status }) => css`
		display: inline-block;
		width: 1em;
		height: 1em;
		margin-right: 5px;
		border-radius: 50%;
		background: ${STATUSES[status]};
	`,
);

export const StatusText = styled.span`
	vertical-align: text-top;
`;
