import { SECTIONS_NAMES } from "@constants/constants";
import classNames from "classnames";
import React, { useRef } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
const ItemsSummary = ({ items }) =>
	items.map((item) => {
		return [
			<dt key={`${item.stringKey}Label`} className={`label ${item.kind}`}>
				<FormattedMessage id={item.stringKey} values={item.values} />
			</dt>,
			<dd key={`${item.stringKey}Value`} className={`value ${item.kind}`}>
				<FormattedMessage id="monetary" values={{ value: item.value }} />
			</dd>,
		];
	});

const DirectPaymentGroupSummary = ({ items, total, isExposedTotalFont }) => (
	<dl className="summary direct-payment">
		<dt className="title">
			<FormattedMessage id="summary.section.directPayment.title" />
		</dt>
		<ItemsSummary items={items} />
		<dt className="label total">
			<FormattedMessage id={total.stringKey} />
		</dt>
		<dd
			className={classNames("value", "total", {
				"bigger-text": isExposedTotalFont,
			})}
			data-automationid="totalPriceValue"
		>
			<FormattedMessage id="monetary" values={{ value: total.value }} />
		</dd>
	</dl>
);

const SubscriptionPaymentGroupSummary = ({
	items,
	subscriptionMonthlyTotal,
	isRenewalMode,
}) => (
	<dl className="summary subscription-payment">
		<dt className="title">
			<FormattedMessage
				id={
					isRenewalMode
						? "summary.section.subscription.title.renewal"
						: "summary.section.subscription.title"
				}
			/>
		</dt>
		<ItemsSummary items={items} />
		<dt className="label total">
			<FormattedMessage id={subscriptionMonthlyTotal.stringKey} />
		</dt>
		<dd className="value total" data-automationid="subscriptionMonthlyTotal">
			<FormattedMessage
				id="monthlyMonetary"
				values={{ value: subscriptionMonthlyTotal.value }}
			/>
		</dd>
	</dl>
);

const Summary = ({ className }) => {
	const intlHook = useIntl();
	const sectionElement = useRef(null);

	const directPaymentSummary = useSelector(
		(state) => state.summary.directPaymentSummary,
	);
	const subscriptionPaymentSummary = useSelector(
		(state) => state.summary.subscriptionPaymentSummary,
	);
	const totalVat = useSelector((state) => state.summary.totalVat);
	const isHidden = useSelector((state) => state.summary.isHidden);

	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);

	if (isHidden) {
		return null;
	}

	return (
		<section
			className={classNames("section-summary", className)}
			data-automationid="orderSummary"
			id={SECTIONS_NAMES.SUMMARY}
			ref={sectionElement}
			aria-label={intlHook.formatMessage({ id: "summary.section.title" })}
		>
			<h1
				className={classNames("title", {
					"flex-title": subscriptionPaymentSummary,
				})}
			>
				<FormattedMessage
					id={
						subscriptionPaymentSummary
							? "summary.section.subscription.title"
							: "summary.section.title"
					}
				/>
			</h1>
			<div className="summary-info">
				{directPaymentSummary && (
					<DirectPaymentGroupSummary
						{...directPaymentSummary}
						isExposedTotalFont={!subscriptionPaymentSummary}
					/>
				)}
				{subscriptionPaymentSummary && (
					<SubscriptionPaymentGroupSummary
						{...subscriptionPaymentSummary}
						isRenewalMode={isRenewalMode}
					/>
				)}
				<dl className="summary summary-vat">
					{totalVat.stringKey && (
						<dt className="label vat">
							<FormattedMessage id={totalVat.stringKey} />
						</dt>
					)}
					<dd className="value vat" data-automationid="totalPriceVatValue">
						<span className="desc">
							<FormattedMessage id={totalVat.stringKey} />
						</span>
						<FormattedMessage
							id="monetary"
							values={{ value: totalVat.value }}
						/>
					</dd>
				</dl>
			</div>
		</section>
	);
};

export default Summary;
