import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import Spinner from "../loaders/spinner/Spinner";

const ReceiptPage = () => {
	const { orderConfirmationUrl, id } = useSelector((state) => state.checkout);

	useEffect(() => {
		if (orderConfirmationUrl && id) {
			window.location = `${orderConfirmationUrl}?checkoutId=${id}`;
		}
	}, [orderConfirmationUrl, id]);

	return <Spinner />;
};

export default ReceiptPage;
