import React from "react";
import { useIntl } from "react-intl";

const Agreement = ({
	totalCost,
	numberOfTerms,
	creditCost,
	totalCreditCost,
}) => {
	const { formatMessage, formatNumber } = useIntl();
	return (
		<p className="agreement">
			{formatMessage(
				{ id: "payment.methods.FairownSubscription.agreement" },
				{
					totalCost: formatMessage(
						{ id: "currencySymbol" },
						{ value: formatNumber(totalCost) },
					),
					numberOfTerms: formatMessage(
						{ id: "month" },
						{ value: numberOfTerms },
					),
					creditCost: formatMessage(
						{ id: "currencySymbol" },
						{ value: formatNumber(creditCost) },
					),
					totalCreditCost: formatMessage(
						{ id: "currencySymbol" },
						{ value: formatNumber(totalCreditCost) },
					),
				},
			)}
		</p>
	);
};

export default Agreement;
