import {
	EmailSection,
	EmailIcon,
	EmailHeader,
	ChangeEmail,
} from "@components/recipient/Recipient.styles";
import Link from "@components/v2/components/link/Link.styles";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const Email = () => {
	const email = useSelector((state) => state.recipient.data.email);
	const storeUrl = useSelector((state) => state.checkout.storeUrl);

	const { formatMessage } = useIntl();

	return (
		<EmailSection>
			<EmailHeader
				className="address"
				data-hj-suppress=""
				data-skipui-cobrowse=""
			>
				<EmailIcon />
				<span>{email}</span>
			</EmailHeader>
			<ChangeEmail>
				{formatMessage({ id: "recipientForm.email.change.label" })}
				<Link
					href={`${storeUrl}myprofile`}
					data-testid="my.profile.link"
					data-automationid="my.profile.link"
				>
					{formatMessage({ id: "recipientForm.email.change.link" })}
				</Link>
			</ChangeEmail>
		</EmailSection>
	);
};

export default Email;
