import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const RenewalStepsSection = styled.section(
	({ theme: { breakpoints } }) => css`
		grid-area: renewal-steps;
		margin: 0 0 16px;

		@media (min-width: ${breakpoints.md}) {
			margin: 32px 0;
		}
	`,
);

export const RenewalStepsList = styled.ol`
	min-width: 280px;
	max-width: 320px;
	margin: 0 auto;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
`;

export const RenewalStepListItem = styled.li`
	position: relative;
	display: grid;
	grid-gap: 8px;
	grid-auto-rows: min-content;
	justify-items: center;
	width: 68px;
	text-align: center;
`;

export const ListItemNumberWrapper = styled.div(
	({ theme: { colors }, isActive }) => css`
		display: grid;
		align-content: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		background: ${isActive ? colors.primary : colors.primaryWhite};
		border: 2px solid ${colors.primary};
		font-weight: 400;
		font-size: 18px;
		line-height: 21px;
	`,
);

export const ListItemLabel = styled.span`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
`;

export const ListItemSeparatorWrapper = styled.li`
	flex-grow: 1;
`;

export const ListItemSeparator = styled.div(
	({ theme: { colors } }) => css`
		padding-top: 23px;
		border-bottom: 1px dashed ${colors.primaryGray};
	`,
);
