import styled from "@emotion/styled";

export const Container = styled("ul")`
	box-sizing: border-box;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	padding: 10px 0;
	margin: 0 10px;
`;
