import { DISCOUNT_TYPES } from "@constants/constants";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const Discount = ({ amount, type }) => {
	return (
		<div className="discount-badge">
			<FormattedMessage id={type === DISCOUNT_TYPES.BENEFIT
				? "order.shop.discount.club"
				: "order.shop.discount.regular"}
			/>
			<FormattedMessage id="monetary" values={{ value: amount }} />
		</div>
	);
};

Discount.propTypes = {
	amount: PropTypes.number,
	type: PropTypes.string,
};

export default Discount;
