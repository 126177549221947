import { ReactComponent as FlexLogo } from "@assets/img/FLEX_logo_new.svg";
import React from "react";
import { useIntl } from "react-intl";

const Title = ({ monthlyCost }) => {
	const { formatMessage, formatNumber } = useIntl();

	return (
		<h1 className="title">
			<FlexLogo />
			<span className="monthly-price">
				{formatMessage(
					{ id: "perMonth" },
					{
						value: formatMessage(
							{ id: "currencySymbol" },
							{ value: formatNumber(monthlyCost) },
						),
					},
				)}
			</span>
		</h1>
	);
};

export default Title;
