import CheckCircle from "@assets/img/icons/check-circle.svg";
import Cross2 from "@assets/img/icons/cross2.svg";
import ExclamationTriangle from "@assets/img/icons/exclamation-triangle.svg";
import InfoCircle from "@assets/img/icons/info-circle.svg";
import TimesCircle from "@assets/img/icons/times-circle.svg";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

const errorLevels = {
	success: {
		border: "#00c200",
		headerBg: "#00c200",
		contentBg: "#dff7df",
		icon: `${CheckCircle}`,
	},
	info: {
		border: "#009ACD",
		headerBg: "#009ACD",
		contentBg: "#e8f4fb",
		icon: `${InfoCircle}`,
	},
	warn: {
		border: "#ffdf00",
		headerBg: "#ffdf00",
		contentBg: "#fffbde",
		icon: `${ExclamationTriangle}`,
	},
	error: {
		border: "#ff0000",
		headerBg: "#ff0000",
		contentBg: "#ffdedd",
		icon: `${TimesCircle}`,
	},
};

export const Header = styled("div")`
	display: flex;
	min-width: 60px;
	align-items: center;
	justify-content: center;
`;

export const Content = styled("div")`
	display: flex;
	flex: 1;
`;

export const Text = styled("div")`
	padding: 15px;
	width: 100%;
	line-height: 1.4;
`;

export const CloseButton = styled("button")`
	border: none;
	background: transparent;
	cursor: pointer;
	padding: 25px;
	background: url(${Cross2}) transparent no-repeat center center;
	transition: 250ms ease background-color;
	&:hover {
		background-color: #e4e4e4;
	}
`;

export const Icon = styled("span")`
	width: 32px;
	height: 32px;
`;

export const Wrapper = styled("li")(
	({ level }) => css`
		transition: box-shadow 0.2s ease-in-out;
		cursor: default;
		display: flex;
		width: 100%;
		flex-flow: row nowrap;
		padding: 0;
		margin: 0 0 5px;
		background: #fff;
		border: 1px solid #ebebeb;

		${Header} {
			background: ${errorLevels[level].headerBg};
		}

		${Content} {
			border-right: 3px solid ${errorLevels[level].border};
		}

		${Icon} {
			background: url(${errorLevels[level].icon}) transparent no-repeat center
				center;
		}
	`,
);
