import { setEditModeVisibility } from "@actions/recipient.actions";
import {
	AddressList,
	ContactList,
	EmailIcon,
	ListItem,
	PhoneIcon,
	RecipientData,
} from "@components/recipient/Recipient.styles";
import RecipientFormProvider from "@components/recipient/recipientForm/RecipientFormProvider";
import { Divider } from "@components/v2/components/dividers/Divider";
import Section from "@components/v2/components/section/Section";
import {
	SectionHeader,
	SectionHeaderButton,
	SectionLabel,
	SectionNumber,
} from "@components/v2/components/section/section.styles";
import { BUTTON_VARIANTS, DIVIDER_VARIANTS } from "@components/v2/constants";
import { SECTIONS_NAMES } from "@constants/constants";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const Recipient = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const isDisabled = useSelector((state) => state.checkout.isDisabled);
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);
	const data = useSelector((state) => state.recipient.data);
	const isEditing = useSelector((state) => state.recipient.isEditing);
	const isValid = useSelector((state) => state.recipient.isValid);
	const isHidden = useSelector((state) => state.recipient.isHidden);

	const changeRecipient = (e) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch(setEditModeVisibility(true));
	};

	if (isHidden) {
		return null;
	}

	const editRecipientText = formatMessage({
		id: "recipient.change",
	});

	return (
		<Section
			id={SECTIONS_NAMES.RECIPIENT}
			className="hero"
			data-section-recipient-valid={isValid}
		>
			<SectionHeader>
				{!isRenewalMode && <SectionNumber>{1}</SectionNumber>}
				<SectionLabel>
					{formatMessage({ id: "recipient.section.name" })}
				</SectionLabel>
				<SectionHeaderButton
					id="changeRecipientAddress"
					className="section-button"
					variant={BUTTON_VARIANTS.SECONDARY}
					title={editRecipientText}
					onClick={changeRecipient}
					disabled={isDisabled}
				>
					{editRecipientText}
				</SectionHeaderButton>
			</SectionHeader>
			<Divider />

			<RecipientData className="section-content">
				<AddressList>
					<ListItem
						data-automationid="name"
						data-hj-suppress
						data-skipui-cobrowse
					>
						{data.name}
					</ListItem>
					<ListItem data-hj-suppress data-skipui-cobrowse>
						<span data-automationid="addressLine1">{data.addressLine1}</span>
					</ListItem>
					<ListItem data-hj-suppress data-skipui-cobrowse>
						<span data-automationid="postcodeAndCity">{`${data.postcode} ${data.city}`}</span>
					</ListItem>
					{data.addressLine2 && (
						<ListItem
							data-automationid="careOf"
							data-hj-suppress
							data-skipui-cobrowse
						>
							{data.addressLine2}
						</ListItem>
					)}
				</AddressList>
				<Divider variant={DIVIDER_VARIANTS.DASHED} />
				<ContactList>
					<ListItem
						className="email"
						data-automationid="email"
						data-hj-suppress
						data-skipui-cobrowse
					>
						<EmailIcon />
						<span>{data.email}</span>
					</ListItem>
					<ListItem
						data-automationid="phone"
						data-hj-suppress
						data-skipui-cobrowse
					>
						<PhoneIcon />
						<span>{data.phone}</span>
					</ListItem>
				</ContactList>
			</RecipientData>

			{isEditing && <RecipientFormProvider />}
		</Section>
	);
};

export default Recipient;
