export const TYPECODES = Object.freeze({
	PORTER: "porter",
	CARRYIN: "carryin",
	HOME_EXPRESS: "home_express",
});

export const DELIVERY_WINDOW_TYPE_CODES = Object.freeze([
	TYPECODES.PORTER,
	TYPECODES.HOME_EXPRESS,
]);

export const STORE_IDS = Object.freeze({
	KOMPLETT_NO: "komplett.no",
	KOMPLETT_DK: "komplett.dk",
	KOMPLETT_SE: "komplett.se",
	BLUSH_NO: "blush.no",
});

export const KEY_MAP = Object.freeze({
	ENTER: 13,
	ESCAPE: 27,
	ARROW_DOWN: 40,
	ARROW_UP: 38,
	SPACE: 32,
	TAB: 9,
});

export const USER_STATES = Object.freeze({
	SIGNING_OUT: "SIGNING_OUT",
	NO_SESSION: "NO_SESSION",
	AUTHENTICATING: "AUTHENTICATING",
	AUTHENTICATED: "AUTHENTICATED",
});

export const STATUS_NAMES = Object.freeze({
	OPEN: "Open",
	PLACED: "Placed",
	EXPIRED: "Expired",
});

export const SECTIONS_NAMES = Object.freeze({
	ITEMS: "items",
	RECIPIENT: "recipient",
	ORDER: "orderItems",
	PAYMENT: "payment",
	SUMMARY: "summary",
	CONFIRMATION: "placeOrderButton",
	TERMS: "terms",
	SHIPPING: "shipping",
});

export const ALERT_LEVELS = Object.freeze({
	ERROR: "ERROR",
	WARN: "WARN",
	INFO: "INFO",
	SUCCESS: "SUCCESS",
});

export const LOG_LEVELS = Object.freeze({
	DEBUG: "Debug",
	INFO: "Info",
	WARN: "Warn",
	ERROR: "Error",
	FATAL: "Fatal",
});

export const ALERT_CLASSES = Object.freeze({
	ERROR: "error",
	WARN: "warning",
	INFO: "info",
	SUCCESS: "success",
});

export const REDIRECT_ACTIONS_TYPES = Object.freeze({
	REDIRECT: "Redirect",
	FORM_POST: "FormPost",
	KOM_ID_AUTHENTICATION: "KomIdAuthentication",
	SIGNING: "Signing",
	VIPPS_AUTHORIZATION: "VippsAuthorization",
	CREDIT_CARD_AUTHORIZATION: "CreditCardAuthorization",
});

export const PAYMENT_METHODS_WITH_OWN_VALIDATION = Object.freeze({
	SWISH: "Swish",
});

export const PAYMENT_METHOD_NAMES = Object.freeze({
	...PAYMENT_METHODS_WITH_OWN_VALIDATION,
	KOMPLETT_SAP_INVOICE: "KomplettSapInvoice",
	VIPPS: "Vipps",
	FAIROWN_SUBSCRIPTION: "FairownSubscription",
	CREDIT_CARD: "CreditCard",
	KOMPLETT_BANK_BONUS_POINTS_V2: "KomplettBankBonusPointsV2",
	WALLEY_DEFERRED: "WalleyDeferred",
	WALLEY_INSTALLMENT: "WalleyInstallment",
	WALLEY_INVOICE: "WalleyInvoice",
});

export const GIFTCARD_PAYMENT_METHOD_NAMES = Object.freeze({
	GIFT_CARD: "GiftCard",
	GIFT_CARD_RETAIN_24: "GiftCardRetain24",
});

export const BONUS_POINTS_NAME = "KomplettBankBonusPoints";

export const PAYMENT_METHOD_LOGOS = Object.freeze([
	PAYMENT_METHOD_NAMES.VIPPS,
	PAYMENT_METHOD_NAMES.SWISH,
	PAYMENT_METHOD_NAMES.WALLEY_INVOICE,
	PAYMENT_METHOD_NAMES.WALLEY_DEFERRED,
	PAYMENT_METHOD_NAMES.WALLEY_INSTALLMENT,
]);

export const PHONE_NUMBER_LENGTHS = Object.freeze({
	NO: 8,
	DA: 8,
	SV: 10,
	FI: 11,
});

export const PORTER_BUDDY_CHAR_LIMIT = 160;

// TODO: There is difference between countries and locales there is DK for Denmark and DA for danish locale.
export const COUNTRY_CODES = Object.freeze({
	NO: "NO",
	SV: "SV",
	DA: "DA",
});

export const PHONE_CODES = Object.freeze({
	SV: 46,
});

export const TRANSPORTS = Object.freeze({
	BLUSH: "Blush",
	BRING: "Bring",
	BUDBEE: "Budbee",
	KOMPLETT: "Komplett",
	PORTERBUDDY: "PorterBuddy",
	POSTEN: "Posten",
	POSTNORD: "PostNord",
});

export const DISABLE_DROPDOWN_OPTIONS_LIMIT = 5;

export const DISCOUNT_TYPES = Object.freeze({
	BENEFIT: "Benefit",
});

export const INPUT_TYPES = Object.freeze({
	NUMBER: "number",
	TEL: "tel",
	CHECKBOX: "checkbox",
});

export const FAIROWN_ALERT_MESSAGES = Object.freeze({
	UNEXPECTED_FAILURE: "paymentserviceprovider.error.700",
	NOT_APPROVED_BY_CREDIT_PROVIDER_YET: "paymentserviceprovider.error.701",
	DENIED_BY_CREDIT_PROVIDER: "paymentserviceprovider.error.702",
	NOT_SIGNED_BY_CUSTOMER_YET: "paymentserviceprovider.error.703",
});
