import classNames from "classnames";
import React, { forwardRef } from "react";
import ReactTextMask from "react-text-mask/dist/reactTextMask";

const MaskedInput = (
	{
		value,
		id,
		className,
		name,
		placeholder,
		type = "text",
		disabled,
		readOnly,
		onChange,
		onBlur,
		ariaInvalid = false,
		hotJarSuppress = false,
		skipuiCobrowse = false,
		autoComplete = "",
		mask
	},
	ref,
) => {
	const dataSkipuiCobrowseProps = skipuiCobrowse && {
		"data-skipui-cobrowse": ""
	};

	return (
		<ReactTextMask
			value={value}
			id={id}
			className={classNames(className, {
				disabled: disabled,
				"data-hj-suppress": hotJarSuppress
			})}
			name={name}
			placeholder={placeholder}
			type={type}
			disabled={disabled}
			readOnly={readOnly}
			onChange={onChange}
			onBlur={onBlur}
			autoComplete={autoComplete}
			aria-invalid={ariaInvalid}
			aria-errormessage={`${name}-error`}
			automationid={`${name}.input`}
			data-testid={`${name}.input`}
			{...dataSkipuiCobrowseProps}
			mask={mask}
			ref={ref}
		/>
	);
};

export default forwardRef(MaskedInput);
