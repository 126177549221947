export const BUTTON_VARIANTS = Object.freeze({
	PRIMARY: "primary",
	PRIMARY_TEXT: "primary-text",
	SECONDARY: "secondary",
	LINK: "link",
});

export const BUTTON_TYPES = Object.freeze({
	BUTTON: "button",
	SUBMIT: "submit",
	RESET: "reset",
});

export const DIVIDER_VARIANTS = Object.freeze({
	SOLID: "SOLID",
	DASHED: "DASHED",
});

export const INPUT_TYPES = Object.freeze({
	NUMBER: "number",
	TEL: "tel",
	CHECKBOX: "checkbox",
	RADIO: "radio",
});
