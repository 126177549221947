import { KEY_MAP } from "@constants/constants";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const Checkbox = ({
	id,
	value,
	className,
	name,
	label,
	optional,
	disabled,
	handleChange,
}) => {
	const controlId = `${id}.control`;

	const onChange = (event) => {
		handleChange(event);
	};

	const onKeyDown = (e) => {
		if (e.which === KEY_MAP.SPACE) {
			e.preventDefault();

			onChange(e);
		}
	};

	return (
		<div
			className={classNames(className, { disabled })}
			data-automationid={`${id}.wrapper`}
			data-testid="checkbox.container"
		>
			<input
				readOnly
				name={name}
				id={controlId}
				className="control"
				type="checkbox"
				disabled={disabled}
				checked={value}
				aria-checked={value}
				onChange={onChange}
				onKeyDown={onKeyDown}
				tabIndex={0}
			/>
			<label
				data-testid="checkbox.label"
				className="label"
				htmlFor={controlId}
				disabled={disabled}
				tabIndex={disabled ? -1 : 0}
			>
				{label}
			</label>
			{optional && <span className="optional">{optional}</span>}
		</div>
	);
};

Checkbox.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	optional: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.number,
	]),
};

export default Checkbox;
