import CookieStorage from "./CookieStorage";
import StorageFeatureDetection from "./StorageFeatureDetection";

export default class StorageFactory {
	static getStorage() {
		return StorageFeatureDetection.isEnabled("sessionStorage")
			? sessionStorage
			: StorageFeatureDetection.isEnabled("localStorage")
			? localStorage
			: new CookieStorage();
	}
}
