import Check from "@assets/img/icons/check.svg";
import { ReactComponent as Cross2 } from "@assets/img/icons/cross2.svg";
import { ReactComponent as EnvelopeIcon } from "@assets/img/icons/envelope.svg";
import { ReactComponent as MobileIcon } from "@assets/img/icons/mobile.svg";
import Input from "@components/form/Input";
import MaskedInput from "@components/form/MaskedInput";
import ErrorContainer from "@components/recipient/recipientForm/ErrorContainer";
import Button from "@components/v2/components/buttons/Button";
import { SectionContent } from "@components/v2/components/section/section.styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

// RECIPIENT SECTION
export const RecipientData = styled(SectionContent)`
	display: grid;
	grid-gap: 12px;
	grid-template-columns: 1fr;
	grid-template-areas:
		"address"
		"divider"
		"contact";
`;

const listStyles = css`
	display: grid;
	grid-gap: 8px;
	word-break: break-word;
`;

export const AddressList = styled.ul`
	${listStyles};
	grid-area: address;

	li {
		font-weight: 500;
	}
`;

export const ContactList = styled.ul`
	${listStyles};
	grid-area: contact;
	align-self: center;
	padding-left: 0;
`;

export const ListItem = styled.li`
	display: flex;
	align-items: center;
`;

// EDIT RECIPIENT
const iconStyles = css`
	display: inline-block;
	width: 24px;
	vertical-align: middle;
	margin-right: 4px;
`;

export const EmailIcon = styled(EnvelopeIcon)(() => iconStyles);

export const Phone = styled.span`
	grid-area: phone;
`;

export const PhoneIcon = styled(MobileIcon)(() => iconStyles);

export const SubmitButton = styled(Button)`
	margin: 16px auto 0;
`;

export const CancelIcon = styled(Cross2)`
	width: 16px;
	height: 16px;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
`;

export const CancelButton = styled.button(
	({ theme }) => css`
		position: absolute;
		right: 16px;
		top: 16px;
		width: 32px;
		height: 32px;
		padding: 0;
		border-radius: 50%;
		border: none;
		background: ${theme.colors.secondaryGray};
		cursor: pointer;
	`,
);

export const Legend = styled.legend(
	({ theme }) => css`
		font-size: 28px;
		font-weight: 700;
		margin-bottom: 24px;

		@media (min-width: ${theme.breakpoints.lg}) {
			margin-bottom: 40px;
		}
	`,
);

export const FormWrapper = styled.section(
	({ theme }) => css`
		z-index: 100;
		position: absolute;
		top: 0;
		box-sizing: border-box;
		width: 100%;
		padding: 70px 20px 40px 20px;
		border: none;
		background: ${theme.colors.primaryWhite};

		&:focus {
			outline: none;
		}

		.submit-button {
			width: 75%;
		}

		@media (min-width: ${theme.breakpoints.lg}) {
			padding: 70px 80px 50px 80px;
		}
	`,
);

export const Form = styled.form`
	display: grid;
	grid-gap: 20px;
`;

export const Label = styled.label(
	({ theme }) => css`
		display: block;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		margin: 0 8px;
		color: ${theme.colors.primaryBlack};
	`,
);

const TextInputStyles = ({ theme, isInvalid }) => css`
	box-sizing: border-box;
	width: 100%;
	margin: 8px 0;
	padding: 8px;
	border: 1px solid
		${isInvalid ? theme.colors.primaryRed : theme.colors.primaryGray};
	border-radius: 4px;
	font-size: 16px;
	line-height: 19px;

	&:disabled,
	&:read-only {
		background: ${theme.colors.secondaryGray};
	}

	${isInvalid &&
	css`
		&:focus-visible {
			outline: 1px solid ${theme.colors.primaryRed};
		}
	`}
`;

export const TextInput = styled(Input)(TextInputStyles);

export const MaskedTextInput = styled(MaskedInput)(TextInputStyles);

export const Error = styled(ErrorContainer)(
	({ theme }) => css`
		display: block;
		margin: 0 8px;
		color: ${theme.colors.primaryRed};
		font-size: 14px;
		line-height: 1.2;
	`,
);

export const EmailSection = styled.section`
	padding: 0 8px 16px 8px;
`;

const emailHeaderStyles = css`
	line-height: 1.3;
	overflow-wrap: normal;
	word-break: break-all;
	margin-bottom: 8px;
`;

export const EmailHeader = styled.h2`
	${emailHeaderStyles};
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;

export const ChangeEmail = styled.h2(() => emailHeaderStyles);

export const Fieldset = styled.fieldset`
	display: grid;
	grid-template-columns: 120px auto;
	justify-items: stretch;
	align-items: start;
	grid-gap: 16px;
	width: 100%;
`;

export const UpdateUserProfile = styled.p(
	({ theme, isChecked }) => css`
		input {
			display: none;
		}

		label {
			display: flex;
			flex-direction: row;
			align-items: center;

			&:before {
				background: transparent url(${isChecked ? Check : "none"}) no-repeat
					center center;
				content: "";
				box-sizing: border-box;
				display: block;
				width: 24px;
				height: 24px;
				margin-right: 16px;
				padding: 10px;
				border: 1px solid ${theme.colors.primaryBlack};
			}
		}
	`,
);
