import { StyledSection } from "@components/v2/components/section/section.styles";
import { SECTIONS_NAMES } from "@constants/constants";
import classNames from "classnames";
import React, { forwardRef } from "react";
import { useIntl } from "react-intl";

const Section = ({ id, className, children, ...rest }, ref) => {
	const { formatMessage } = useIntl();

	const areaLabels = {
		[SECTIONS_NAMES.RECIPIENT]: formatMessage({
			id: "recipient.section.title",
		}),
		[SECTIONS_NAMES.SHIPPING]: formatMessage({ id: "shipping.section.title" }),
		[SECTIONS_NAMES.PAYMENT]: formatMessage({ id: "payment.section.title" }),
	};

	return (
		<StyledSection
			id={id}
			className={classNames(`${id}-section`, className)}
			aria-label={areaLabels[id]}
			data-testid={`${id}.section`}
			data-automationid={`${id}.section`}
			ref={ref}
			{...rest}
		>
			{children}
		</StyledSection>
	);
};

export default forwardRef(Section);
