import Dropdown from "@components/form/Dropdown";
import ShippingItem from "@components/order/shipping/ShippingItem";
import ShippingItemRadioSet from "@components/order/shipping/ShippingItemRadioSet";
import { DISABLE_DROPDOWN_OPTIONS_LIMIT } from "@constants/constants";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";

const ShippingMethodAlternatives = ({
	currentMethod,
	currentOption,
	handleSelectOption,
	isSectionDisabled,
	logisticUnitId,
	shipping,
	hasDeliveryWindow,
	hasGreenDeliveryLabel,
}) => {
	const shouldDisplayRadioSet =
		currentMethod.options.length < DISABLE_DROPDOWN_OPTIONS_LIMIT;

	if (shouldDisplayRadioSet) {
		return (
			<ShippingItemRadioSet
				options={currentMethod.options}
				hasDifferentPrices={currentMethod.hasDifferentPrices}
				hasDifferentNames={currentMethod.hasDifferentNames}
				hasDifferentTransporters={currentMethod.hasDifferentTransporters}
				isSectionDisabled={isSectionDisabled}
				handleSelectOption={handleSelectOption}
				typeCode={shipping.typeCode}
				currentOption={currentOption}
			/>
		);
	}

	return (
		<Dropdown
			id={`shippingOptions-${logisticUnitId}`}
			className={classNames("available-items", {
				"delivery-window": hasDeliveryWindow,
				"has-green-delivery": hasGreenDeliveryLabel,
			})}
			selectedId={currentOption.id}
			onSelect={handleSelectOption(shipping.typeCode)}
			active={currentMethod.options.length < DISABLE_DROPDOWN_OPTIONS_LIMIT}
			horizontal={currentMethod.options.length < DISABLE_DROPDOWN_OPTIONS_LIMIT}
			disabled={isSectionDisabled}
		>
			{currentMethod.options.map((option) => {
				return (
					<ShippingItem
						key={option.id}
						className={classNames(shipping.typeCode, {
							"delivery-window": hasDeliveryWindow,
							"has-green-delivery": hasGreenDeliveryLabel,
						})}
						label={currentMethod.hasDifferentNames && option.label}
						header={option.header}
						subHeader={option.subHeader}
						greenDeliveryLabel={option.greenDeliveryLabel}
						price={currentMethod.hasDifferentPrices && option.price}
						transport={
							currentMethod.hasDifferentTransporters ? [option.transporter] : []
						}
						postCode={option.postCode}
						street={option.street}
						hours={option.hours}
						estimatedDeliveryDate={option.estimatedDeliveryDate}
						estimatedDeliveryTime={option.estimatedDeliveryTime}
						isSelected={option.id === currentOption.id}
					>
						<FormattedMessage
							id="shortMonetary"
							values={{ value: option.price }}
						/>
					</ShippingItem>
				);
			})}
		</Dropdown>
	);
};

export default ShippingMethodAlternatives;
