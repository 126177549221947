import RecipientForm from "@components/recipient/recipientForm/RecipientForm";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const NewFormRecipientFormProvider = () => {
	const country = useSelector((state) => state.checkout.storeLanguage);
	const phone = useSelector((state) => state.recipient.data.phone);
	const name = useSelector((state) => state.recipient.data.name);
	const addressLine1 = useSelector((state) => state.recipient.data.addressLine1);
	const postcode = useSelector((state) => state.recipient.data.postcode);
	const city = useSelector((state) => state.recipient.data.city);
	const addressLine2 = useSelector((state) => state.recipient.data.addressLine2);
	const email = useSelector((state) => state.recipient.data.email);

	const methods = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: {
			country,
			email,
			phone,
			name,
			addressLine1,
			postcode,
			city,
			addressLine2,
			updateUserProfile: true,
		},
	});

	return (
		<FormProvider {...methods}>
			<RecipientForm />
		</FormProvider>
	);
};

export default NewFormRecipientFormProvider;
