import { removeAllGiftCards } from "@actions/payment/methods.actions";
import { sendPaymentEvent } from "@utils/GTMUtils";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedHTMLMessage } from "react-intl";
import { useDispatch } from "react-redux";

import ReactCheckbox from "../../form/ReactCheckbox";

import GiftCardForm from "./GiftCardForm";

const GiftCardSection = ({
	giftCard,
	giftCards,
	showgiftCardLoader,
	isDeletingGiftCard,
	showGiftCardExistsAlert,
	isTotalCoveredByGiftCard,
}) => {
	const dispatch = useDispatch();
	const [showGiftCardForm, setShowGiftCardForm] = useState(false);

	const handleShowGiftCardForm = ({ target: { checked } }) => {
		sendPaymentEvent(`ClickedGiftCardForm: ${Number(checked)}`);

		if (!checked) {
			dispatch(removeAllGiftCards());
		}

		setShowGiftCardForm(checked);
	};

	const isActive = !!giftCards.length || showGiftCardForm;

	return (
		<section className="gift-card">
			<ReactCheckbox
				value={isActive}
				handleChange={handleShowGiftCardForm}
				id="giftCardOpenFormButton"
				automationId="giftCard.openForm"
				label={
					<FormattedHTMLMessage
						id={
							giftCard
								? "payment.giftCard.apply.success"
								: "payment.giftCard.openForm"
						}
					/>
				}
			/>
			{isActive && (
				<GiftCardForm
					giftCards={giftCards}
					showgiftCardLoader={showgiftCardLoader}
					isDeletingGiftCard={isDeletingGiftCard}
					showGiftCardExistsAlert={showGiftCardExistsAlert}
					isTotalCoveredByGiftCard={isTotalCoveredByGiftCard}
				/>
			)}
		</section>
	);
};

GiftCardSection.propTypes = {
	giftCard: PropTypes.object,
	giftCards: PropTypes.array,
	showgiftCardLoader: PropTypes.bool,
	isDeletingGiftCard: PropTypes.bool,
	isTotalCoveredByGiftCard: PropTypes.bool,
	showGiftCardExistsAlert: PropTypes.func,
};

export default GiftCardSection;
