export const processLogisticUnits = (jsonOrder, state) => {
	const logisticUnits =
		jsonOrder.logisticUnits?.filter((x) => x.shipping) ?? [];

	for (let logisticUnit of logisticUnits) {
		const currentLogisticUnit = state?.logisticUnits.find(
			({ id }) => id === logisticUnit.id,
		);
		const selectedShipping = logisticUnit.shipping.selectedShipping;
		const currentMethod = logisticUnit.shipping.availableShipping.find(
			(shipping) => shipping.typeCode === selectedShipping.typeCode,
		);

		logisticUnit.shipping.currentMethod = currentMethod;
		logisticUnit.shipping.currentOption = currentMethod.options.find(
			(option) => option.id === selectedShipping.shippingOptionId,
		);
		logisticUnit.shipping.extraServiceIds = selectedShipping.extraServiceIds;

		for (let availableShipping of logisticUnit.shipping.availableShipping) {
			const currentAvailableShippingOption =
				currentLogisticUnit?.shipping.availableShipping.find(
					({ typeCode }) => typeCode === availableShipping.typeCode,
				);
			const isAvailableShippingCurrent =
				availableShipping.typeCode === selectedShipping.typeCode;
			const defaultOptionId = availableShipping.options[0]?.id;
			const currentOptionId = currentAvailableShippingOption?.selectedOptionId;
			const selectedShippingOptionId = selectedShipping.shippingOptionId;

			availableShipping.selectedOptionId = isAvailableShippingCurrent
				? selectedShippingOptionId
				: currentOptionId || defaultOptionId;

			availableShipping.hasDifferentTransporters =
				new Set(availableShipping.options.map((option) => option.transporter))
					.size > 1;

			for (let option of availableShipping.options) {
				if (option.price !== availableShipping.cheapestOptionPrice) {
					availableShipping.hasDifferentPrices = true;
				}

				if (option.label !== availableShipping.label) {
					availableShipping.hasDifferentNames = true;
					option.hasDifferentNames = true;
				}
			}
		}
	}

	return { ...jsonOrder, logisticUnits };
};
