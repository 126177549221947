import rolloutFeatures from "@reducers/rolloutFeatures.reducer";
import { combineReducers } from "redux";

import alerts from "./alerts.reducer";
import checkout from "./checkout.reducer";
import gtm from "./gtm.reducer";
import order from "./order.reducer";
import payment from "./payment.reducer";
import recipient from "./recipient.reducer";
import summary from "./summary.reducer";
import user from "./user.reducer";

const rootReducer = combineReducers({
	checkout,
	recipient,
	order,
	summary,
	payment,
	gtm,
	user,
	alerts,
	rolloutFeatures,
});

export default rootReducer;
