import { unuseBonusPoints, useBonusPoints } from "@actions/payment/methods.actions";
import ReactCheckbox from "@components/form/ReactCheckbox";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { Container } from "./BonusPointsContainer.styles";
import { BonusPointsLabel } from "./BonusPointsLabel";

const BonusPointsContainer = ({
	bonusPoints,
	disabled,
	isPaymentEligibleForBonusPoints
}) => {
	const dispatch = useDispatch();
	const isFetching = disabled;
	const isDisabledByPaymentMethod =
		!isPaymentEligibleForBonusPoints ||
		!bonusPoints ||
		bonusPoints?.availableBonusPoints === 0;
	const isActive = bonusPoints?.isUsed ?? false;
	const handleUseBonusPoints = () =>
		// eslint-disable-next-line react-hooks/rules-of-hooks
		bonusPoints.isUsed ? dispatch(unuseBonusPoints()) : dispatch(useBonusPoints());

	return (
		<Container>
			<ReactCheckbox
				id="useBonusPoints"
				name="useBonusPoints"
				className="use-bonus-points"
				disabled={isFetching || isDisabledByPaymentMethod}
				value={isActive}
				handleChange={handleUseBonusPoints}
				label={
					<BonusPointsLabel
						active={isActive}
						disabled={isDisabledByPaymentMethod}
						usedAmount={bonusPoints?.usedBonusPoints}
						amount={bonusPoints?.availableBonusPoints}
					/>
				}
				automationid="useBonusPoints"
				testId="bonuspoints.checkbox"
			/>
		</Container>
	);
};

BonusPointsContainer.propTypes = {
	disabled: PropTypes.bool,
	isPaymentEligibleForBonusPoints: PropTypes.bool,
	bonusPoints: PropTypes.shape({
		isUsed: PropTypes.bool,
		usedBonusPoints: PropTypes.number,
		usedAmount: PropTypes.number,
		availableBonusPoints: PropTypes.number,
	}),
};

export default BonusPointsContainer;
