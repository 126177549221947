import {
	RenewalHeaderDesktop,
	RenewalHeaderMobile,
	RenewalHeaderWrapper,
} from "@components/v2/components/Renewal/RenewalHeader/RenewalHeader.styles";
import React from "react";
import { useIntl } from "react-intl";

const RenewalHeader = () => {
	const { formatMessage } = useIntl();
	return (
		<RenewalHeaderWrapper>
			<RenewalHeaderDesktop>
				{formatMessage({ id: "renewal.header.desktop" })}
			</RenewalHeaderDesktop>
			<RenewalHeaderMobile>
				{formatMessage({ id: "renewal.header.mobile" })}
			</RenewalHeaderMobile>
		</RenewalHeaderWrapper>
	);
};

export default RenewalHeader;
