const digits = (count) => Array(count).fill(/\d/);

export const POSTCODE_MASKS = Object.freeze({
	NO: digits(4),
	DA: digits(4),
	SV: [...digits(3), " ", ...digits(2)],
});

export const PHONE_REGEXES = Object.freeze({
	NO: new RegExp(/^(4|9)\d{7}$/),
	DA: new RegExp(
		/^(2\d|3[0-6,8-9]|4\d|5\d|6[0-6,9]|7\d|8[1-2,6-9]|9[1-3,6-9])\d{6}$/,
	),
	SV: new RegExp(/^07(0|2|3|6|9)\d{7}$/),
});

export const VALIDATION_REGEXP = Object.freeze({
	WORD_COUNT: new RegExp(/\S+\s+\S+/),
	DIGITS: new RegExp(/\d/),
	PHONE: PHONE_REGEXES,
	FORBIDDEN_CHARACTERS: new RegExp(/[<>]/),
	ALPHABETIC_CHARACTERS: new RegExp(/[a-zA-Z]/),
	CITY: new RegExp(/\D+$/, "i"),
});
