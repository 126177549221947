import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

const FinanceModalContent = ({ methodTypePrefix }) => {
	return (
		<Fragment>
			<header className="header">
				<h1 className="title" id="financePaymentTermsModalHeader">
					<FormattedMessage id={`${methodTypePrefix}.terms.title`} />
				</h1>
			</header>
			<section className="content" id="financePaymentTermsModalContent">
				<div className="richtext">
					<FormattedHTMLMessage
						tagName="div"
						id={`${methodTypePrefix}.terms.content`}
					/>
				</div>
			</section>
		</Fragment>
	);
};

FinanceModalContent.propTypes = {
	methodTypePrefix: PropTypes.string,
};

export { FinanceModalContent };
