import React from "react";
import { useIntl } from "react-intl";

const Details = ({ effectiveInterestRate, residualValue, period }) => {
	const { formatMessage, formatNumber } = useIntl();

	return (
		<ul className="details">
			<li className="residual-value">
				<span>
					{formatMessage({
						id: "payment.methods.FairownSubscription.residualValue",
					})}
				</span>
				<strong>
					{formatMessage(
						{ id: "currencySymbol" },
						{ value: formatNumber(residualValue) },
					)}
				</strong>
			</li>
			<li className="effective-interest-rate">
				<span>
					{formatMessage({
						id: "payment.methods.FairownSubscription.effectiveInterestRate",
					})}
				</span>
				<strong>{formatNumber(effectiveInterestRate)}%</strong>
			</li>
			<li className="pay-today">
				<span>
					{formatMessage({
						id: "payment.methods.FairownSubscription.payToday",
					})}
				</span>
				<strong>
					{formatMessage({ id: "currencySymbol" }, { value: formatNumber(0) })}
				</strong>
			</li>
			<li className="change-after">
				<span>
					{formatMessage({
						id: "payment.methods.FairownSubscription.changeAfter",
					})}
				</span>
				<strong>{formatMessage({ id: "month" }, { value: period })}</strong>
			</li>
		</ul>
	);
};

export default Details;
