import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Info = styled.div(
	({ theme }) => css`
		margin-top: 10px;
		color: #333;
		background: #fff;
		font-size: 14px;
		line-height: 24px;
		padding: 16px;

		@media (max-width: ${theme.breakpoints.sm}) {
			padding: 16px 16px 16px 2px;
		}
	`,
);

const enableLegacyStyleForInfo = () => css`
	margin: 0;
	padding: 10px 0 0 0;
`;

export const LabelContainer = styled.div(
	({ enableLegacyStyle }) => css`
		margin-top: 5px;
		flex: 1;

		${Info} {
			${enableLegacyStyle && enableLegacyStyleForInfo()}
		}
	`,
);
