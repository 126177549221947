import {
	RadioInput,
	RadioLabel,
} from "@components/v2/components/form/radioButton.styles";
import { INPUT_TYPES } from "@components/v2/constants";
import React from "react";

const RadioButton = ({
	id,
	className,
	value,
	name,
	checked,
	label,
	disabled,
	onChange = () => {},
	onClick = () => {},
}) => {
	return (
		<RadioLabel
			className={className}
			isChecked={checked}
			automationid={`${id}.radioButtonWrapper`}
			data-testid={`${id}.radioButtonWrapper`}
		>
			<RadioInput
				type={INPUT_TYPES.RADIO}
				id={id}
				name={name}
				value={value}
				checked={checked}
				disabled={disabled}
				onChange={onChange}
				onClick={onClick}
				data-automationid={`${id}.radioButton`}
				data-testid={`${id}.radioButton`}
			/>
			<span>{label}</span>
		</RadioLabel>
	);
};

export default RadioButton;
