import React from "react";
import { FormattedMessage } from "react-intl";

export const ShippingExtraServicesLabel = ({
	service: { label, price },
}) => (
	<>
		<span className="extra-option-name">{label}</span>
		{!!price && <FormattedMessage id="monetary" values={{ value: price }} />}
	</>
);

export default ShippingExtraServicesLabel;
