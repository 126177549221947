import { selectShipping } from "@actions/shipping.actions";
import ShippingMethod from "@components/order/shipping/ShippingMethod";
import ShippingMethodContent from "@components/order/shipping/ShippingMethodContent";
import { Panels } from "@components/v2/components/collapsiblePanel/collapsiblePanel.styles";
import { TYPECODES } from "@constants/constants";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const ShippingMethods = ({ isSectionDisabled }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const porterBuddyInstructions = useSelector(
		(state) => state.order.porterBuddyInstructions,
	);
	const logisticUnitId = useSelector(
		(state) => state.order.logisticUnits[0].id,
	);
	const selectShippingState = (state) => state.order.logisticUnits[0].shipping;
	const currentMethod = useSelector(
		(state) => selectShippingState(state).currentMethod,
	);
	const currentOption = useSelector(
		(state) => selectShippingState(state).currentOption,
	);
	const extraServiceIds = useSelector(
		(state) => selectShippingState(state).extraServiceIds,
	);
	const availableShipping = useSelector(
		(state) => selectShippingState(state).availableShipping,
	);

	const selectMethod = (methodId) => {
		const selectedMethod = availableShipping.find(
			(shipping) => shipping.typeCode === methodId,
		);
		dispatch(selectShipping(methodId, selectedMethod.options[0].id));
	};

	const handleShippingClick = (extraServiceIds) => {
		dispatch(
			selectShipping(currentMethod.typeCode, currentOption.id, extraServiceIds),
		);
	};

	const handleSelectOption = (methodId) => (optionId) => {
		dispatch(selectShipping(methodId, optionId));
	};

	const isOnlyOneShippingMethod = availableShipping.length === 1;

	return (
		<Panels as="fieldset">
			{availableShipping.map((shipping) => {
				const isSelected = currentMethod.typeCode === shipping.typeCode;
				const hasDeliveryWindow = shipping.options.some(
					(option) => !!option.hours,
				);
				const hasGreenDeliveryLabel = shipping.options.some(
					(option) => !!option.greenDeliveryLabel,
				);
				const isCarryin = shipping.typeCode === TYPECODES.CARRYIN;
				const isExpanded = isCarryin || isSelected;

				return (
					<ShippingMethod
						isSelected={isSelected}
						isExpanded={isExpanded}
						shipping={shipping}
						handleActivatePanel={selectMethod}
						isSectionDisabled={isSectionDisabled}
						key={shipping.typeCode}
					>
						<ShippingMethodContent
							currentMethod={currentMethod}
							currentOption={currentOption}
							extraServiceIds={extraServiceIds}
							handleSelectOption={handleSelectOption}
							handleShippingClick={handleShippingClick}
							isSectionDisabled={isSectionDisabled}
							porterBuddyInstructions={porterBuddyInstructions}
							formatMessage={formatMessage}
							logisticUnitId={logisticUnitId}
							shipping={shipping}
							hasDeliveryWindow={hasDeliveryWindow}
							hasGreenDeliveryLabel={hasGreenDeliveryLabel}
						/>
					</ShippingMethod>
				);
			})}
			{isOnlyOneShippingMethod && (
				<span className="one-method-available-message">
					<FormattedMessage id="order.shipping.methods.noother" />
				</span>
			)}
		</Panels>
	);
};

export default ShippingMethods;
