import AlertsContainer from "@components/alerts/AlertsContainer";
import Link from "@components/v2/components/link/Link.styles";
import { FAIROWN_ALERT_MESSAGES } from "@constants/constants";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const PaymentAlert = () => {
	const { formatMessage } = useIntl();
	const isDisabled = useSelector(
		(state) => state.payment.isSectionDisabled || state.checkout.isDisabled,
	);
	const cartUrl = useSelector((state) => state.checkout.cartUrl);

	const shouldShowBackToCartLink = useSelector(({ alerts }) => {
		const paymentGeneralAlertsKey = "checkout.payment.general";
		const paymentGeneralAlertsMessages = Object.values(
			alerts[paymentGeneralAlertsKey] || {},
		).map(({ message }) => message);

		return paymentGeneralAlertsMessages.find((alertMessage) =>
			Object.values(FAIROWN_ALERT_MESSAGES).includes(alertMessage),
		);
	});

	return (
		<AlertsContainer role="checkout.payment.general" disabled={isDisabled}>
			{shouldShowBackToCartLink && (
				<Link
					href={cartUrl}
					data-testid="backToCartLink"
					data-automationid="backToCartLink"
				>
					{formatMessage({
						id: "payment.methods.subscription.back.to.cart",
					})}
				</Link>
			)}
		</AlertsContainer>
	);
};

export default PaymentAlert;
