import * as AlertActions from "@actions/alert.actions";
import { ALERT_LEVELS } from "@constants/constants";
import anime from "animejs/lib/anime.es.js";
import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Alert } from "./Alerts";
import { Container } from "./AlertsContainer.styles.js";

const AlertsContainer = ({ role, children, disabled, shouldScroll = true }) => {
	const intlHook = useIntl();
	const emergencyMessage = intlHook.formatMessage({
		id: "emergencyGeneralWarning",
	});
	const hasEmergencyWarning =
		role === "checkout.general" &&
		emergencyMessage !== "KomPay.emergencyGeneralWarning";
	const shouldScrollToAlert = hasEmergencyWarning ? false : shouldScroll;
	const { alerts } = useSelector((state) => state);
	const dispatch = useDispatch();
	const alertsToDisplay = Object.values(alerts[role] || {});
	const hasAlerts = alertsToDisplay.length;
	const alertsContainer = useRef(null);

	useEffect(() => {
		if (shouldScrollToAlert && !disabled && hasAlerts) {
			const elementOffset = alertsContainer.current.getBoundingClientRect().top;
			const scrollPosition = window.scrollY;
			const scrollOffset = elementOffset + scrollPosition;

			anime({
				targets: [document.documentElement, document.body],
				scrollTop: scrollOffset,
				duration: 1000,
				delay: 250,
				easing: "easeInOutQuart",
			});
		}
	}, [shouldScrollToAlert, disabled, hasAlerts]);

	const handleAlertClose = (alertMessage, alertId) =>
		dispatch(AlertActions.dismissAlert(role, alertMessage, alertId));

	return (
		<Container ref={alertsContainer} className="alerts">
			{hasEmergencyWarning && (
				<Alert
					level={ALERT_LEVELS.INFO}
					key="emergencyGeneralError"
					id="emergencyGeneralError"
					message="emergencyGeneralWarning"
					role={role}
				/>
			)}
			{alertsToDisplay.map((alert, key) => (
				<Alert
					closeButtonLabel={intlHook.formatMessage({ id: "alert.close" })}
					onAlertClose={handleAlertClose}
					key={key}
					role={role}
					{...alert}
				>
					{children}
				</Alert>
			))}
		</Container>
	);
};

AlertsContainer.propTypes = {
	alerts: PropTypes.object,
	role: PropTypes.string,
	dismissAlert: PropTypes.func,
	disabled: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	shouldScroll: PropTypes.bool,
};

export default AlertsContainer;
