import { getLinkStyles } from "@components/v2/components/link/Link.styles";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const StyledButton = styled.button(
	({ theme: { colors }, disabled, rounded }) => css`
		cursor: ${disabled ? "not-allowed" : "pointer"};
		padding: 8px 20px;
		border-radius: ${rounded ? "100px" : "4px"};
		font-weight: 500;
		font-size: 14px;

		&.link {
			${getLinkStyles({ theme: { colors } })}
			display: inline-block;
			font-size: inherit;
			font-weight: 400;
			line-height: inherit;
			background: none;
			padding: 0;
			margin: 0;
			text-decoration: underline;
		}

		&.primary {
			color: ${colors.button.primary.color};
			background-color: ${colors.button.primary.background};
			box-shadow: 0 ${disabled ? 0 : "2px"} 0 ${colors.button.primary.shadow};
			padding: 20px;
			font-size: 16px;
			transition: box-shadow 250ms linear, background-color 250ms linear,
				border 250ms linear;

			&:hover {
				background-color: ${colors.button.primary.hoverBackground};
			}

			&:active {
				background-color: ${colors.button.primary.activeBackground};
			}

			&:disabled {
				background-color: ${colors.button.primary.disabledBackground};
			}
		}

		&.primary-text {
			color: ${colors.button.primaryText.primaryBlue};
			background: none;
			text-decoration: underline;
			border-radius: unset;
		}

		&.secondary {
			color: ${colors.button.secondary.color};
			background: none;
			font-weight: 400;
			border: 1px solid ${colors.button.secondary.border};

			&:hover {
				border-color: ${colors.button.secondary.borderHover};
			}

			&:active {
				border-color: ${colors.button.secondary.borderActive};
			}

			&:disabled {
				color: ${colors.button.secondary.disabled};
				border-color: ${colors.button.secondary.disabledBorder};
			}
		}
	`,
);
