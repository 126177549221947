import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const RenewalHeaderWrapper = styled.section(
	({ theme: { breakpoints } }) => css`
		grid-area: renewal-header;
		margin: 0 0 0 16px;

		@media (min-width: ${breakpoints.md}) {
			font-size: 32px;
			margin: 0 0 24px 0;
		}
	`,
);

export const RenewalHeaderDesktop = styled.h1(
	({ theme: { breakpoints } }) => css`
		display: none;

		@media (min-width: ${breakpoints.md}) {
			display: block;
			font-size: 32px;
			font-weight: 700;
			line-height: 48px;
		}
	`,
);

export const RenewalHeaderMobile = styled.h1(
	({ theme: { breakpoints } }) => css`
		font-size: 20px;
		font-weight: 700;
		line-height: 48px;
		margin-bottom: 8px;

		@media (min-width: ${breakpoints.md}) {
			display: none;
		}
	`,
);
