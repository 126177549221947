import * as CheckoutActions from "@actions/checkout.actions";
import * as RecipientActions from "@actions/recipient.actions";
import * as ShippingActions from "@actions/shipping.actions";
import * as SummaryActions from "@actions/summary.actions";

const defaultState = {
	status: null,
	storeId: "",
	cartUrl: null,
	storeUrl: null,
	b2BLoginRedirectUrl: null,
	orderConfirmationUrl: null,
	settings: {
		locale: "nb-NO",
	},
	hasSentCheckoutInitiatedToAnalytics: false,
	hasSentPurchaseCompleteToAnalytics: false,
	errors: [],
	warnings: [],
	isUserAccepted: false,
	isLoaded: false,
	isDisabled: false,
	isBlurred: false,
	isLoading: false,
	isConfirmButtonDisabled: false,
	discounts: [],
	agreedToTerms: false,
};

const checkoutReducer = (state = defaultState, action) => {
	switch (action.type) {
		case CheckoutActions.CHECKOUT_FETCH_DONE: {
			return {
				...state,
				settings: {
					locale: action.json.storeCulture,
				},
				id: action.json.id,
				googleAnalyticsEnvironment: action.json.googleAnalyticsEnvironment,
				status: action.json.status,
				storeId: action.json.storeId,
				isDirectBuy: action.json.isDirectBuy,
				isInitiatedAsDirectBuy: action.json.isInitiatedAsDirectBuy,
				storeLanguage: action.json.storeLanguage,
				cartUrl: action.json.cartUrl,
				ordersUrl: action.json.ordersUrl,
				storeUrl: action.json.storeUrl,
				orderConfirmationUrl: action.json.orderConfirmationUrl,
				totalCost: action.json.totalCost,
				hasSentCheckoutInitiatedToAnalytics:
					action.json.hasSentCheckoutInitiatedToAnalytics || false,
				hasSentPurchaseCompleteToAnalytics:
					action.json.hasSentPurchaseCompleteToAnalytics || false,
				isDisabled: false,
				discounts: action.json.discounts,
				isCoveredByGiftCard:
					action.json.payment?.directPayment?.isCoveredByGiftCard || false,
				isCoveredByBonusPoints:
					action.json.payment?.directPayment?.isCoveredByBonusPoints || false,
				isCoveredByGiftCardsAndBonusPoints:
					action.json.payment?.directPayment
						?.isCoveredByGiftCardsAndBonusPoints || false,
			};
		}
		case CheckoutActions.CHECKOUT_LOADED:
			return {
				...state,
				isLoaded: true,
			};
		case RecipientActions.RECIPIENT_SET_REQUESTED:
			return {
				...state,
				isUserAccepted: true,
			};

		case CheckoutActions.CHECKOUT_FETCH_FAILED:
			return {
				...state,
				isDisabled: false,
				errors: [...state.errors, action.error, action.type],
			};

		case RecipientActions.RECIPIENT_SET_FAILED:
			return {
				...state,
				errors: [...state.errors, action.error, action.type],
			};

		case CheckoutActions.CHECKOUT_URLS_DONE:
			return {
				...state,
				cartUrl: action.json.cartUrl,
				storeUrl: action.json.storeUrl,
				isDisabled: false,
			};

		case CheckoutActions.CHECKOUT_URLS_FAILED:
			return {
				...state,
				cartUrl: "",
				storeUrl: "",
				isDisabled: false,
			};

		case CheckoutActions.CHECKOUT_SET_INITIAL_DATA:
			return {
				...state,
				...action.data,
			};

		case CheckoutActions.LOCALE_CHANGED:
			return {
				...state,
				settings: {
					...state.settings,
					locale: action.locale,
				},
			};

		case SummaryActions.ORDER_PLACE_DONE:
			return {
				...state,
				isDisabled: false,
			};

		case CheckoutActions.CHECKOUT_CONFIRM_REQUESTED:
		case CheckoutActions.CHECKOUT_FETCH_REQUESTED:
		case CheckoutActions.CHECKOUT_URLS_REQUESTED:
		case ShippingActions.SHIPPING_SELECT_REQUESTED:
		case SummaryActions.ORDER_PLACE_REQUESTED:
			return {
				...state,
				isDisabled: true,
			};

		case CheckoutActions.CHECKOUT_URLS_CANCELLED:
		case CheckoutActions.CHECKOUT_CONFIRM_FAILED:
		case CheckoutActions.CHECKOUT_CONFIRM_DONE:
		case RecipientActions.RECIPIENT_SAVE_DONE:
		case ShippingActions.SHIPPING_SELECT_DONE:
		case ShippingActions.SHIPPING_SELECT_FAILED:
		case SummaryActions.CHECKOUT_CONFIRM_CANCELLED:
		case SummaryActions.CHECKOUT_CONFIRM_FAILED:
		case SummaryActions.ORDER_PLACE_FAILED:
			return {
				...state,
				isDisabled: false,
			};

		case CheckoutActions.CHECKOUT_BLUR_ENABLE:
			return {
				...state,
				isBlurred: true,
			};

		case CheckoutActions.CHECKOUT_BLUR_DISABLE:
			return {
				...state,
				isBlurred: false,
			};

		case CheckoutActions.CHECKOUT_ENABLE_CONFIRM_BUTTON:
			return {
				...state,
				isConfirmButtonDisabled: false,
			};

		case CheckoutActions.CHECKOUT_DISABLE_CONFIRM_BUTTON:
			return {
				...state,
				isConfirmButtonDisabled: true,
			};

		case CheckoutActions.CHECKOUT_TOGGLE_AGREE_TERMS:
			return {
				...state,
				agreedToTerms: !state.agreedToTerms,
			};

		case CheckoutActions.CHECKOUT_LOADER_ENABLE:
			return {
				...state,
				isLoading: true,
			};

		case CheckoutActions.CHECKOUT_LOADER_DISABLE:
			return {
				...state,
				isLoading: false,
			};

		default:
			return state;
	}
};

export default checkoutReducer;
