import { STORE_IDS } from "@constants/constants";
import { SECTIONS_NAMES } from "@constants/constants";
import { LinkButton } from "@uiElements/LinkButton.styles";
import { sendPaymentEvent } from "@utils/GTMUtils";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";

import Modal from "../modal/Modal";

import { Footer, TermsInfo, SVGImage } from "./Terms.styles";
import { TermsContent } from "./TermsContent";

const Terms = ({ info, trigger, active, customerType, storeId }) => {
	const [activated, setActivated] = useState(active || false);
	const intlHook = useIntl();
	const sectionElement = useRef(null);

	const openFullTerms = () => {
		const fullTermsLink =
			storeId === STORE_IDS.BLUSH_NO
				? customerType === "Commercial"
					? "footer.termsandconditions.blush.b2b.dialog.links.url"
					: "footer.termsandconditions.blush.dialog.links.url"
				: "footer.termsandconditions.b2b.dialog.links.url";

		sendPaymentEvent("openedFullSalesTerms");
		window.open(intlHook.formatMessage({ id: fullTermsLink }, "_blank"));
	};

	const handleActivateTermsModal = (e) => {
		e.preventDefault();
		customerType === "Commercial" || storeId === STORE_IDS.BLUSH_NO
			? openFullTerms()
			: setActivated(true);
	};

	const handleDeactivateTermsModal = (e) => {
		e.preventDefault();
		setActivated(false);
	};

	const termsLink =
		customerType === "Commercial"
			? "footer.termsandconditions.b2b.dialog.links.url"
			: "footer.termsandconditions.dialog.links.url";

	const termsContent =
		customerType === "Commercial"
			? "footer.termsandconditions.b2b.content"
			: "footer.termsandconditions.content";
	return (
		<Footer id={SECTIONS_NAMES.TERMS} ref={sectionElement}>
			<TermsInfo>
				{info && <div className="info">{info}</div>}
				<LinkButton
					type="button"
					onClick={handleActivateTermsModal}
					data-automationid="termsInfoTrigger"
				>
					{trigger}
				</LinkButton>
				<Modal
					isOpen={activated}
					onRequestClose={handleDeactivateTermsModal}
					className="summary-terms-modal"
				>
					<TermsContent
						contentId={termsContent}
						linkId={intlHook.formatMessage({ id: termsLink })}
						handleOpenLink={() => sendPaymentEvent("openedFullSalesTerms")}
					/>
				</Modal>
			</TermsInfo>
			<SVGImage />
		</Footer>
	);
};

Terms.propTypes = {
	info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	active: PropTypes.bool,
	intl: PropTypes.object,
	customerType: PropTypes.string,
	storeId: PropTypes.string,
};

export default Terms;
