import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonTypes } from "./Button.types";

export const StyledButton = styled("button")<ButtonTypes>(
  ({ theme, background, hoverBackground, color, className }) => css`
    background: ${background ? theme.colors[background] : "none"};
    color: ${color ? theme.colors[color] : theme.colors.labelText};
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin: 5px;

    &:hover {
      background: ${hoverBackground ? theme.colors[hoverBackground] : "none"};
    }
    ${className}
  `
);

export const ButtonIcon = styled("span")`
  max-height: 18px;
  margin-right: 25px;

  svg {
    width: 13px;
    height: 18px;
  }
`;

export const ButtonIconGrey = styled("span")`
  margin-right: 25px;
  background: #f5f5f5;
  border-radius: 50%;
  display: inline-block;
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 13px;
    height: 18px;
  }
`;
