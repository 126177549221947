import {
	applyGiftCard,
	removeSingleGiftCard,
	showGiftCardExistsAlert,
} from "@actions/payment/methods.actions";
import AlertsContainer from "@components/alerts/AlertsContainer";
import { TextInput } from "@components/recipient/Recipient.styles";
import { sendPaymentEvent } from "@utils/GTMUtils";
import { debounce } from "@utils/debounce";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import Validation from "../../../validation/Validation";

const validation = new Validation({
	code: [
		{ name: "minLength", params: { length: 16 } },
		{ name: "maxLength", params: { length: 17 } },
		{ name: "giftCard" },
	],
});

const GiftCardForm = ({
	giftCards,
	showgiftCardLoader,
	isDeletingGiftCard,
	isTotalCoveredByGiftCard,
}) => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const [code, setCode] = useState("");
	const [formErrors, setFormErrors] = useState({ code: null });

	const sendTrackingEvent = debounce(() => {
		sendPaymentEvent("TypingInFor: GiftCardCode");
	}, 1000);

	const handleChange = (event) => {
		sendTrackingEvent();
		setCode(event.target.value.replaceAll(" ", ""));
	};

	const handlePaste = (e) => {
		e.preventDefault();

		const text = (e.originalEvent || e).clipboardData.getData("text/plain");

		handleChange({
			target: {
				value: text.replaceAll(" ", ""),
			},
		});
	};

	const handleRemoveGiftCard = (giftCardCode) => {
		sendPaymentEvent("RemovedGiftCard");
		dispatch(removeSingleGiftCard(giftCardCode));
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const validationResult = validation.validateSingleField("code", code);
		setFormErrors({ ...validationResult });

		if (giftCards.find((el) => el.code === code)) {
			dispatch(showGiftCardExistsAlert());

			return;
		}

		if (!validationResult.code) {
			dispatch(applyGiftCard(code)).then(() => setCode(""));
			sendPaymentEvent("GiftCardApplied");
		}
	};

	const id = "gift-card-input";
	return (
		<form
			id="giftCardForm"
			className="gift-card-form"
			onSubmit={handleSubmit}
			data-automationid="giftCard.form"
		>
			<div className="gift-card-list" data-testid="giftCard.container">
				{giftCards.length > 0 &&
					giftCards.map((giftcard, key) => (
						<div
							key={key}
							className="applied-gift-card"
							data-automationid="giftCard.form.submitted"
							data-testid={`giftCard.item.${giftcard.code}`}
						>
							<div className="giftcard-data">
								<p className="code">{giftcard.code}</p>
								<p className="used-amount">
									<FormattedHTMLMessage
										id="payment.giftCard.amount.used"
										values={{
											usedAmount: giftcard.usedAmount,
											availableAmount: giftcard.availableAmount,
										}}
									/>
								</p>
							</div>
							<button
								onClick={() => handleRemoveGiftCard(giftcard.code)}
								className="delete-btn"
								type="button"
								disabled={isDeletingGiftCard}
							>
								Delete
							</button>
						</div>
					))}
			</div>
			{!isTotalCoveredByGiftCard && (
				<>
					<div className="code-container">
						<div className="gift-card-code">
							<label htmlFor={id}>
								<FormattedMessage id="payment.giftCard.label" />
							</label>
							<TextInput
								id={id}
								name="giftCard.form"
								className="field"
								onChange={handleChange}
								onPaste={handlePaste}
								value={code}
								autoComplete="off"
								aria-describedby="gift-card-input.error"
							/>
							<span
								className="error-message"
								aria-live="polite"
								aria-atomic="true"
								id="gift-card-input.error"
							>
								{formErrors.code && (
									<span className="contents">
										{formatMessage({ id: formErrors.code })}
									</span>
								)}
							</span>
						</div>
					</div>
					<button
						type="submit"
						className="submit"
						data-automationid="giftCard.form.submit"
						disabled={showgiftCardLoader}
					>
						<FormattedMessage id="payment.giftCard.apply.text" />
					</button>
				</>
			)}
			<AlertsContainer role="checkout.payment.giftCard" />
		</form>
	);
};

GiftCardForm.propTypes = {
	giftCards: PropTypes.array,
	showgiftCardLoader: PropTypes.bool,
	isDeletingGiftCard: PropTypes.bool,
	isTotalCoveredByGiftCard: PropTypes.bool,
};

export default GiftCardForm;
