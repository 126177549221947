import BonusPointsContainer from "@components/payment/methods/bonusPoints/BonusPointsContainer";
import { PAYMENT_METHOD_NAMES } from "@constants/constants";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const BonusPointsSection = () => {
	const bonusPoints = useSelector((state) => state.payment.bonusPoints);
	const currentSelectedMethod = useSelector(
		({ payment: { directMethods, selectedMethod } }) =>
			directMethods[selectedMethod],
	);
	const isDisabled = useSelector(
		(state) => state.payment.isSectionDisabled || state.checkout.isDisabled,
	);
	const { formatMessage } = useIntl();

	return (
		<section>
			<h2 className="direct-payment-subtitle">
				{formatMessage({ id: "payment.section.name" })}
			</h2>
			<div className="panels" role="radiogroup">
				<BonusPointsContainer
					key={PAYMENT_METHOD_NAMES.KOMPLETT_BANK_BONUS_POINTS_V2}
					disabled={isDisabled}
					isPaymentEligibleForBonusPoints={
						currentSelectedMethod.komplettBonusPointsPaymentEnabled
					}
					bonusPoints={bonusPoints}
				/>
			</div>
		</section>
	)
};

export default BonusPointsSection;
