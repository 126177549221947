import styled from '@emotion/styled';

export const InputField = styled("input")<{ error: boolean }>(({ error }) => ({
	background: "#f3f4f6",
	border: `1px solid ${error ? '#f87171' : '#e5e7eb'}`,
	outline: "none",
	padding: "10px",
	borderRadius: "0.375rem",
	fontSize: 16,
	width: "100%",
}))

export const PhoneNumberField = styled("input")(() => ({
	outline: "none",
	borderRadius: "0.375rem",
	height: 'auto',
	fontSize: 16,
	width: "100%",
	background: "#f3f4f6",
	border: 'none'
}))

export const PhoneNumberWrapper = styled("div")<{ error: boolean }>(({ error }) => ({
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	justifyContent: "flex-start",
	gap: 4,
	background: "#f3f4f6",
	border: `1px solid ${error ? '#f87171' : '#e5e7eb'}`,
	outline: "none",
	padding: "10px",
	borderRadius: "0.375rem",
	fontSize: 16,
	width: "100%",
}))

export const DrawerErrorMessage = styled('p')({
	color: '#f87171',
	fontSize: 16,
	marginTop: 5,
	textAlign: 'left',
	fontWeight: 500
})

export const DrawerForm = styled("div")({
	display: 'flex',
	flexDirection: "column",
	gap: 10,
	padding: "0 20px"
})

export const Title = styled("h2")({
	fontSize: 38,
	fontWeight: "bold",
});

export const Description = styled("p")({
	fontSize: 16,
	padding: "0 20px",
});

export const DrawerSubmitButton = styled("button")({
	padding: "10px",
	background: "#f8b912",
	border: "1px solid #d29300",
	borderRadius: "0.375rem",
	fontSize: "16px !important",
	cursor: "pointer",
})