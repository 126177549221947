import { TextInput } from "@components/recipient/Recipient.styles";
import {
	requiredMessage,
	validateName,
} from "@components/recipient/recipientForm/recipientFormValidation";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

const NameInput = () => {
	const {
		formState: { errors },
	} = useFormContext();

	const isSaving = useSelector((state) => state.recipient.isSaving);
	const isB2BUser = useSelector(
		(state) => state.recipient.customerType === "Commercial",
	);

	return (
		<Controller
			name="name"
			rules={{
				required: requiredMessage,
				validate: !isB2BUser && {
					validateName,
				},
			}}
			render={({ field }) => (
				<TextInput
					id="name"
					autoComplete="name"
					maxLength={70}
					disabled={isSaving}
					readOnly={isB2BUser}
					isInvalid={!!errors.name}
					ariaInvalid={!!errors.name}
					hotJarSuppress={true}
					{...field}
				/>
			)}
		/>
	);
};

export default NameInput;
