const debounce = (func, wait = 300) => {
	let timeout;
	return (...params) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			func(params);
		}, wait);
	};
};

export { debounce };
