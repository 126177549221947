import * as RecipientActions from "@actions/recipient.actions";
import { USER_STATES } from "@constants/constants";

import { getUser as getUserImpl } from "../components/authentication/authenticationConfig";

/* action types */

export const USER_STATE_REQUESTED = "USER_STATE_REQUESTED";
export const USER_STATE_DONE = "USER_STATE_DONE";

/* action creators */

export function getUser() {
	return (dispatch) => {
		dispatch({
			type: USER_STATE_REQUESTED,
			userState: USER_STATES.AUTHENTICATING,
		});

		return getUserImpl().then(({ user, userState }) => {
			dispatch({ type: USER_STATE_DONE, userState });
			dispatch(RecipientActions.setRecipient(user));
		});
	};
}
