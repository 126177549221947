import { setInitialData } from "@actions/checkout.actions";
import getCheckoutId from "@actions/utils/common/getCheckoutId";
import getNavigationData from "@actions/utils/common/getNavigationData";
import noAuthFetch from "@actions/utils/common/noAuthFetch";
import AppContainer from "@components/app/AppContainer";
import ErrorPage from "@components/app/errorPage/ErrorPage";
import { signOutCallback } from "@components/authentication/authenticationConfig";
import { ThemeProvider } from "@emotion/react";
import configureStore from "@store/configureStore";
import BlushTheme from "@themes/BlushTheme";
import KomplettTheme from "@themes/KomplettTheme";
import customIntlFormats from "@utils/customIntlFormats";
import React from "react";
import { render } from "react-dom";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";

import { theme } from "./theme";

const store = configureStore();

const renderProvider = (strings = null, culture) => {
	render(
		<React.Fragment>
			{window.location.host.includes("blush") ? (
				<BlushTheme />
			) : (
				<KomplettTheme />
			)}
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<IntlProvider
						locale={culture}
						key={culture}
						messages={strings}
						formats={customIntlFormats}
						textComponent="span"
					>
						{strings ? <AppContainer /> : <ErrorPage />}
					</IntlProvider>
				</ThemeProvider>
			</Provider>
		</React.Fragment>,
		document.getElementById("app"),
	);
};

if (!getCheckoutId()) {
	signOutCallback();
}

const getInitialData = () => {
	const checkoutId = getCheckoutId();
	if (checkoutId) {
		getNavigationData(checkoutId)
			.then((navigationData) => {
				store.dispatch(setInitialData(navigationData));
				noAuthFetch({
					url: `/api/dictionaries?storeNumber=${navigationData.storeNumber}`,
					options: { cache: "default" },
				})
					.then((response) => response.json())
					.then((strings) =>
						renderProvider(strings, navigationData.storeCulture),
					);
			})
			.catch(() => renderProvider());
	} else {
		renderProvider();
	}
};

getInitialData();
