import styled from "@emotion/styled";

export const Container = styled.section`
	margin: 15px 0 0 12px;
	padding-right: 40px;
	position: relative;

	.react-checkbox {
		> .label {
			align-items: flex-start;

			> span {
				line-height: 1.2;
				padding-top: 3px;
			}
		}
	}
`;
