import AlertsContainer from "@components/alerts/AlertsContainer";
import Header from "@components/app/Header";
import {
	AddressList,
	ContactList,
	ListItem,
	RecipientData,
} from "@components/recipient/Recipient.styles";
import {
	RenewalHeaderDesktop,
	RenewalHeaderMobile,
	RenewalHeaderWrapper,
} from "@components/v2/components/Renewal/RenewalHeader/RenewalHeader.styles";
import {
	ListItemLabel,
	ListItemNumberWrapper,
	ListItemSeparator,
	ListItemSeparatorWrapper,
	RenewalStepListItem,
	RenewalStepsList,
	RenewalStepsSection,
} from "@components/v2/components/Renewal/RenewalSteps/RenewalSteps.styles";
import { Divider } from "@components/v2/components/dividers/Divider";
import Section from "@components/v2/components/section/Section";
import {
	SectionContent,
	SectionHeader,
	SectionLabel,
} from "@components/v2/components/section/section.styles";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const AppPlaceholder = () => {
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);

	return (
		<React.Fragment>
			<div className="wrapper-loader-foreground" />
			<div className="wrapper wrapper-placeholder">
				<Header showBackToCartLink={false} />
				<AlertsContainer />
				<main
					className={classNames("main", { "renewal-layout": isRenewalMode })}
				>
					{isRenewalMode && (
						<>
							<RenewalStepsSection>
								<RenewalStepsList>
									<RenewalStepListItem aria-current="step">
										<ListItemNumberWrapper>
											<div className="ph ph-header-circle-xl" />
										</ListItemNumberWrapper>
										<ListItemLabel>
											<div className="ph ph-xs" />
										</ListItemLabel>
									</RenewalStepListItem>

									<ListItemSeparatorWrapper>
										<ListItemSeparator />
									</ListItemSeparatorWrapper>

									<RenewalStepListItem>
										<ListItemNumberWrapper>
											<div className="ph ph-header-circle-xl" />
										</ListItemNumberWrapper>
										<ListItemLabel>
											<div className="ph ph-xs" />
										</ListItemLabel>
									</RenewalStepListItem>

									<ListItemSeparatorWrapper>
										<ListItemSeparator />
									</ListItemSeparatorWrapper>

									<RenewalStepListItem>
										<ListItemNumberWrapper>
											<div className="ph ph-header-circle-xl" />
										</ListItemNumberWrapper>
										<ListItemLabel>
											<div className="ph ph-xs" />
										</ListItemLabel>
									</RenewalStepListItem>
								</RenewalStepsList>
							</RenewalStepsSection>
							<RenewalHeaderWrapper>
								<RenewalHeaderMobile>
									<div className="ph ph-md" />
								</RenewalHeaderMobile>
								<RenewalHeaderDesktop>
									<div className="ph ph-md" />
								</RenewalHeaderDesktop>
							</RenewalHeaderWrapper>
						</>
					)}

					<AlertsContainer />
					<Section className="hero placeholder-section">
						<SectionHeader>
							<div className="ph ph-header-circle" />
							<SectionLabel>
								<div className="ph ph-sm" />
							</SectionLabel>
							<div className="ph ph-xs" />
						</SectionHeader>
						<Divider />
						<RecipientData className="section-content">
							<AddressList>
								<ListItem>
									<div className="ph ph-md" />
								</ListItem>
								<ListItem>
									<div className="ph ph-md" />
								</ListItem>
							</AddressList>
							<ContactList>
								<ListItem>
									<div className="ph ph-md" />
								</ListItem>
								<ListItem>
									<div className="ph ph-md" />
								</ListItem>
							</ContactList>
						</RecipientData>
					</Section>
					<Section className="shipping placeholder-section">
						<SectionHeader>
							<div className="ph ph-header-circle" />
							<SectionLabel>
								<div className="ph ph-md" />
							</SectionLabel>
						</SectionHeader>
						<Divider />
						<SectionContent className="methods section-content placeholder-section">
							<div className="ph ph-xl ph-option" />
							<div className="ph ph-xl ph-option" />
							<div className="ph ph-xl ph-option" />
						</SectionContent>
					</Section>
					<Section className="section-payment placeholder-section">
						<SectionHeader>
							<div className="ph ph-header-circle" />
							<SectionLabel>
								<div className="ph ph-md" />
							</SectionLabel>
						</SectionHeader>
						<Divider />
						<SectionContent className="methods section-content placeholder-section">
							<div className="ph ph-xl ph-option" />
							<div className="ph ph-xl ph-option" />
							<div className="ph ph-xl ph-option" />
						</SectionContent>
					</Section>
					<section id="summary" className="section-summary-and-items">
						<section>
							<section id="items" className="items section-items">
								<h1 className="title">
									<div className="ph ph-md" />
								</h1>
								<table className="items-listing">
									<thead className="header">
										<tr>
											<th>
												<div className="ph ph-xs" />
											</th>
											<th>
												<div className="ph ph-xs" />
											</th>
											<th>
												<div className="ph ph-xs" />
											</th>
										</tr>
									</thead>
									<tbody className="offer">
										<tr className="product">
											<td className="figure empty">
												<div className="image ph ph-xs" />
											</td>
											<td className="name">
												<div className="ph ph-md" />
											</td>
											<td className="amount">
												<div className="ph ph-xs" />
											</td>
										</tr>
									</tbody>
								</table>
							</section>
							<section className="section-summary" id="summary">
								<div className="summary-info">
									<dl className="summary direct-payment">
										<dt className="title">
											<div className="ph ph-md" />
										</dt>
										<dt className="label cost">
											<div className="ph ph-xs" />
										</dt>
										<dd className="value cost">
											<div className="ph ph-xs" />
										</dd>
										<dt className="label total">
											<div className="ph ph-xs" />
										</dt>
										<dd className="value total bigger-text">
											<div className="ph ph-xs" />
										</dd>
									</dl>
									<dl className="summary">
										<dt className="label vat">
											<div className="ph ph-xs" />
										</dt>
										<dd className="value vat">
											<span className="desc">
												<div className="ph ph-xs" />
											</span>
											<div className="ph ph-xs" />
										</dd>
									</dl>
								</div>
							</section>
						</section>
					</section>
					<section id="items" className="items section-items mobile">
						<h1 className="title">
							<div className="ph ph-md" />
						</h1>
						<table className="items-listing">
							<thead className="header">
								<tr>
									<th>
										<div className="ph ph-xs" />
									</th>
									<th>
										<div className="ph ph-xs" />
									</th>
									<th>
										<div className="ph ph-xs" />
									</th>
								</tr>
							</thead>
							<tbody className="offer">
								<tr className="product">
									<td className="figure empty">
										<div className="image ph ph-xs" />
									</td>
									<td className="name">
										<div className="ph ph-md" />
									</td>
									<td className="amount">
										<div className="ph ph-xs" />
									</td>
								</tr>
							</tbody>
						</table>
					</section>
				</main>
			</div>
		</React.Fragment>
	);
};

export default AppPlaceholder;
