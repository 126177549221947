import {
	PAYMENT_FAIROWN_CONSENT_CHECKBOX_SET,
	setValidity,
} from "@actions/payment/methods.actions";
import Checkbox from "@components/form/Checkbox";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const ConsentCheckbox = () => {
	const genericKey = useRef(0);
	const labelRef = useRef(null);
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();
	const methodType = useSelector(
		(state) => state.payment.subscriptionMethod.type,
	);
	const isConsentChecked = useSelector((state) => {
		return state.payment.subscriptionMethod.consent.isConsentChecked || false;
	});
	const isValid = useSelector(
		(state) => state.payment.isPaymentSubscriptionSectionValid,
	);
	const errorMessage = isValid
		? ""
		: formatMessage({
				id: "payment.methods.FairownSubscription.consents.error",
		  });

	const handleConsentChange = (e) => {
		dispatch({
			type: PAYMENT_FAIROWN_CONSENT_CHECKBOX_SET,
			payload: {
				checked: e.target.checked,
				content: labelRef.current?.innerText,
			},
		});
		dispatch(setValidity(methodType, e.target.checked));
	};

	return (
		<div className="consent-checkbox-content">
			<Checkbox
				className="consents-checkbox"
				handleChange={handleConsentChange}
				name="flexConsent"
				value={isConsentChecked}
				label={formatMessage(
					{
						id: "payment.methods.FairownSubscription.flexConsent",
					},
					{
						span: (...str) => (
							<span ref={labelRef} key={++genericKey.current}>
								{str}
							</span>
						),
						generalconditions: (...str) => (
							<a
								key={++genericKey.current}
								target="_blank"
								href={formatMessage({
									id: "payment.methods.FairownSubscription.flexConsent.link.1",
								})}
								rel="noreferrer"
							>
								{str}
							</a>
						),
						privacypolicy: (...str) => (
							<a
								key={++genericKey.current}
								target="_blank"
								href={formatMessage({
									id: "payment.methods.FairownSubscription.flexConsent.link.2",
								})}
								rel="noreferrer"
							>
								{str}
							</a>
						),
					},
				)}
			/>
			<span className="error-message">{errorMessage}</span>
		</div>
	);
};

export default ConsentCheckbox;
