import ShippingOptionLabel from "@components/order/shipping/ShippingOptionLabel";
import Radiobutton from "@components/v2/components/form/RadioButton";
import { TYPECODES } from "@constants/constants";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const ShippingItemRadioSet = ({
	options,
	isSectionDisabled,
	handleSelectOption,
	typeCode,
	currentOption,
	hasDifferentPrices,
	hasDifferentNames,
	hasDifferentTransporters,
}) => {
	const isLoading = useSelector((state) => state.checkout.isLoading);

	const isPorterWithGreenDelivery =
		typeCode === TYPECODES.PORTER &&
		options.some((option) => option.greenDeliveryLabel);

	return (
		<fieldset
			className={classNames("shipping-method-options", typeCode, {
				column: isPorterWithGreenDelivery,
			})}
		>
			{options.map((option) => {
				const isDisabled =
					(isLoading && option.id !== currentOption.id) || isSectionDisabled;
				const isChecked = option.id === currentOption.id;

				return (
					<Radiobutton
						className="shipping-method-option option"
						key={option.id}
						id={option.id}
						value={option.id}
						name={option.name}
						label={
							<ShippingOptionLabel
								typeCode={typeCode}
								header={option.header}
								subHeader={option.subHeader}
								label={option.label}
								hasDifferentNames={hasDifferentNames}
								hours={option.hours}
								transporter={option.transporter}
								hasDifferentTransporters={hasDifferentTransporters}
								street={option.street}
								postCode={option.postCode}
								price={option.price}
								hasDifferentPrices={hasDifferentPrices}
								estimatedDeliveryDate={option.estimatedDeliveryDate}
								estimatedDeliveryTime={option.estimatedDeliveryTime}
								greenDeliveryLabel={option.greenDeliveryLabel}
							/>
						}
						checked={isChecked}
						disabled={isDisabled}
						onChange={(e) => {
							handleSelectOption(typeCode)(e.target.value);
						}} // TODO: Do something with closure after dropdown cleanup.
					/>
				);
			})}
		</fieldset>
	);
};

export default ShippingItemRadioSet;
