import styled from "@emotion/styled";

export const PaymentTitle = styled.span`
	display: grid;
	grid-gap: 12px;
	grid-template-columns: repeat(auto-fit, minmax(0, max-content));
	align-items: center;
	justify-content: start;

	> span {
		line-height: 130%;
	}
`;
