import { INPUT_TYPES } from "@constants/constants";
import classNames from "classnames";
import React, { forwardRef } from "react";

const Input = (
	{
		value,
		id,
		className,
		name,
		placeholder,
		type = "text",
		disabled,
		readOnly,
		onChange,
		onBlur,
		onKeyPress,
		onPaste,
		maxLength,
		ariaInvalid = false,
		hotJarSuppress = false,
		autoComplete = "",
	},
	ref,
) => {
	const isInputNumeric = [INPUT_TYPES.NUMBER, INPUT_TYPES.TEL].includes(type);
	const isValueNotNumber = (value) => /[^0-9]/g.test(value);

	const handleKeyPress = (e) => {
		if (!isInputNumeric) {
			onKeyPress?.(e);

			return;
		}

		if (isValueNotNumber(e.key)) {
			e.preventDefault();

			return;
		}

		onKeyPress?.(e);
	};

	const handlePaste = (e) => {
		onPaste?.(e);
	};

	return (
		<input
			value={value}
			id={id}
			className={classNames(className, {
				disabled: disabled,
				"data-hj-suppress": hotJarSuppress,
			})}
			name={name}
			placeholder={placeholder}
			type={type}
			disabled={disabled}
			readOnly={readOnly}
			onChange={onChange}
			onBlur={onBlur}
			onKeyPress={handleKeyPress}
			onPaste={handlePaste}
			autoComplete={autoComplete}
			maxLength={maxLength}
			aria-invalid={ariaInvalid}
			aria-errormessage={`${name}-error`}
			data-automationid={`${name}.input`}
			data-testid={`${name}.input`}
			ref={ref}
		/>
	);
};

export default forwardRef(Input);
