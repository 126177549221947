import OrderItemsTable from "@components/order/OrderItemsTable";
import { PAYMENT_METHOD_NAMES, SECTIONS_NAMES } from "@constants/constants";
import classNames from "classnames";
import React, { useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

const OrderItems = ({ className }) => {
	const { formatMessage } = useIntl();

	const isFairownSubscription = useSelector(
		(state) =>
			state.payment?.subscriptionMethod?.type ===
			PAYMENT_METHOD_NAMES.FAIROWN_SUBSCRIPTION,
	);
	const isHidden = useSelector((state) => state.summary.isHidden);
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);
	const sectionElement = useRef(null);

	if (isHidden) {
		return null;
	}

	return (
		<section
			id={SECTIONS_NAMES.ITEMS}
			ref={sectionElement}
			className={classNames("items", "section-items", className, {
				"renewal-layout": isRenewalMode,
			})}
			data-automationid="items"
			aria-label={formatMessage({
				id: "order.headings.items",
			})}
		>
			{isFairownSubscription && <SubscriptionOffers />}
			{!isFairownSubscription && <PayNowOffers />}
		</section>
	);
};

const selectLogisticUnit = (state) => state.order.logisticUnits?.[0] || {};

const PayNowOffers = () => {
	const offers = useSelector((state) => selectLogisticUnit(state).offers || []);

	return (
		!!offers.length && (
			<OrderItemsTable
				offers={offers}
				title={<FormattedMessage id={"order.shop.summary"} />}
			/>
		)
	);
};

const SubscriptionOffers = () => {
	const offers = useSelector((state) => selectLogisticUnit(state).offers || []);

	const subscriptionItems = offers.filter((offer) => offer.isSubscriptionOffer);
	const accessories = offers.filter((offer) => !offer.isSubscriptionOffer);

	const renewalPeriod = useSelector(
		(state) => state.payment?.subscriptionMethod?.selectedOptionCode,
	);

	return (
		!!offers.length && (
			<>
				<OrderItemsTable
					offers={subscriptionItems}
					title={
						<FormattedMessage
							id={"order.shop.summary.exchange.title"}
							values={{ renewalPeriod }}
						/>
					}
				/>
				{!!accessories.length && (
					<OrderItemsTable
						offers={accessories}
						title={
							<FormattedMessage
								id="order.shop.summary.keep.title"
								values={{ renewalPeriod }}
							/>
						}
					/>
				)}
			</>
		)
	);
};

export default OrderItems;
