import React, { useEffect, useReducer } from "react";
import Box from "../../common/Box/Box";
import { Break } from "../../common/Break/Break";
import { Checkbox } from "../../common/Checkbox/Checkbox";

import {
  getSubscriptionState,
  setSubscriptionState,
} from "./FlexConsent.action";
import { ACTIONS, initialState, reducer } from "./FlexConsent.reducer";

interface FlexConsentsProps {
  apiHost?: string;
  text: string;
  label: string;
  className?: string;
  errorMessage?: string | undefined;
  onChangeSuccess?: () => void;
  onChangeFailure?: () => void;
  containerStyles?: React.CSSProperties;
}

const FlexConsent: React.FC<FlexConsentsProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onChange = async () => {
    dispatch({ type: ACTIONS.SET_IS_FETCHING, payload: true });
    dispatch({ type: ACTIONS.SET_SUBSCRIPTION, payload: !state.isSubscribed });

    const response = await setSubscriptionState({
      apiHost: props?.apiHost,
      isSubscribed: state.isSubscribed,
      consentContent: props.text,
    });

    if (response.isSuccess) {
      dispatch({ type: ACTIONS.SET_IS_FETCHING, payload: false });
      props.onChangeSuccess?.();
    } else {
      dispatch({ type: ACTIONS.SET_IS_FETCHING, payload: false });

      dispatch({
        type: ACTIONS.SET_SUBSCRIPTION,
        payload: !state.isSubscribed,
      });

      if (props.errorMessage) {
        dispatch({
          type: ACTIONS.SET_ERROR_MESSAGE,
          payload: props.errorMessage,
        });
      }

      props.onChangeFailure?.();
    }
  };

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_IS_FETCHING, payload: true });
    const fetchData = async () => {
      const response = await getSubscriptionState({ apiHost: props?.apiHost });

      dispatch({ type: ACTIONS.SET_IS_FETCHING, payload: false });

      // response.content is undefined for new users
      dispatch({
        type: ACTIONS.GET_SUBSCRIPTION,
        payload:
          typeof response?.content === "undefined" ? true : response.content,
      });
    };

    fetchData();
  }, []);

  return (
    <Box style={props.containerStyles} className={props.className}>
      <span dangerouslySetInnerHTML={{ __html: props.text }} />
      <Break spacing={20} />
      <Checkbox
        disabled={state.isFetching}
        value={(!state.isSubscribed).toString()}
        error={state.errorMessage}
        id="flex.consents.checkbox"
        label={props.label}
        onChange={onChange}
      />
    </Box>
  );
};

export default FlexConsent;
