import { PAYMENT_METHOD_NAMES } from "@constants/constants";
import { sendPaymentEvent } from "@utils/GTMUtils";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export const useInfoTextString = ({
	options,
	paymentPlan,
	code,
	cost,
	type,
}) => {
	const methodTypePrefix = `payment.methods.${type}`;
	const period = 24;
	const isFairownSubscription =
		type === PAYMENT_METHOD_NAMES.FAIROWN_SUBSCRIPTION;

	const { formatNumber } = useIntl();
	const [isGTMEventDispatched, setIsGTMEventDispatched] = useState(false);
	const testWalleyDescriptionText = useSelector(
		(state) =>
			!isFairownSubscription &&
			!state.checkout.isRenewalMode &&
			[
				PAYMENT_METHOD_NAMES.WALLEY_INVOICE,
				PAYMENT_METHOD_NAMES.WALLEY_DEFERRED,
				PAYMENT_METHOD_NAMES.WALLEY_INSTALLMENT,
			].includes(type) &&
			state.rolloutFeatures.WalleyDescriptionText,
	);

	useEffect(() => {
		if (testWalleyDescriptionText && !isGTMEventDispatched) {
			sendPaymentEvent("Checkout variant B");
			setIsGTMEventDispatched(true);
		}
	}, [isGTMEventDispatched, testWalleyDescriptionText]);

	const fairownSubscriptionInfix = useSelector(
		(state) => `${state.checkout.isRenewalMode ? ".renewal" : ".regular"}`,
	);

	const [id] = useState(() =>
		isFairownSubscription
			? `${methodTypePrefix}.paymentPlan${fairownSubscriptionInfix}.info.v2`
			: testWalleyDescriptionText
			? `${methodTypePrefix}.paymentPlan.v2`
			: `${methodTypePrefix}.paymentPlan`,
	);

	const [values] = useState(() => {
		if (testWalleyDescriptionText) {
			return Object.values(options).reduce(
				(variables, { paymentPlan, code, ...rest }) => {
					const flattenPaymentPlanVariables = {
						...rest,
						...paymentPlan,
					};

					const withFormattedKeys = {
						...variables,
						...Object.entries(flattenPaymentPlanVariables).reduce(
							(flattenVariables, [key, value]) => ({
								...flattenVariables,
								[`${code}.${key}`]: value,
							}),
							{},
						),
					};

					return Object.entries(withFormattedKeys).reduce(
						(formattedValues, [key, value]) => {
							const shouldFormatPrice =
								typeof value === "number" && key !== "numberOfTerms";

							return {
								...formattedValues,
								[key]: shouldFormatPrice
									? formatNumber(value, {
											style: "decimal",
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
									  })
									: value,
							};
						},
						{},
					);
				},
				{},
			);
		}

		return {
			...paymentPlan,
			effectiveInterestRate: formatNumber(paymentPlan.effectiveInterestRate, {
				style: "decimal",
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}),
			renewalPeriod: code,
			totalToBePaidDuringThePeriod: paymentPlan.monthlyCost * period,
			startupFeeCost: `${cost}`,
		};
	});

	return { id, values };
};
