import * as CheckoutActions from "@actions/checkout.actions";

import initial from "./createInitialGtmData";

const defaultState = {};

export default (state = defaultState, action) => {
	switch (action.type) {
		case CheckoutActions.CHECKOUT_FETCH_DONE:
			return {
				hasSentCheckoutInitiatedToAnalytics:
					action.json.hasSentCheckoutInitiatedToAnalytics,
				hasSentPurchaseCompleteToAnalytics:
					action.json.hasSentPurchaseCompleteToAnalytics,
				initial: initial(action.json),
			};

		default:
			return state;
	}
};
