import PropTypes from "prop-types";
import React from "react";

const Spinner = ({ type = "global" }) => (
	<div className={`overlay-loader-${type}`}>
		<div className="throbber" role="progressbar">
			<svg className="circular" data-automationid="circular">
				<circle className="path" cx="40" cy="40" r="38" />
			</svg>
		</div>
	</div>
);

Spinner.propTypes = {
	type: PropTypes.string,
};

export default Spinner;
