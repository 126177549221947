import * as UserActions from "@actions/user.actions";

const defaultState = {
	state: null,
	isFetching: false,
	isEditing: false,
	data: {
		email: "",
		phone: "",
		name: "",
		addressLine1: "",
		addressLine2: "",
		postcode: "",
		city: "",
		termsAgreed: false,
	},
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case UserActions.USER_STATE_DONE:
			return {
				...state,
				state: action.userState,
			};

		default:
			return state;
	}
};
