import AlertsContainer from "@components/alerts/AlertsContainer";
import React from "react";
import { useSelector } from "react-redux";

import Shipping from "./shipping/Shipping";

const Order = () => {
	const isHidden = useSelector((state) => state.order.isHidden);
	const logisticUnits = useSelector((state) => state.order.logisticUnits);

	const isShippingAvailable = !!logisticUnits?.[0]?.shipping;

	if (isHidden) {
		return null;
	}

	return (
		<>
			{isShippingAvailable && <Shipping />}
			<AlertsContainer role="checkout.order.general" />
		</>
	);
};

export default Order;
