import React, { useRef } from "react";
import { useIntl } from "react-intl";

const FinanceInformation = ({ secciUrl }) => {
	const genericKey = useRef(0);
	const { formatMessage } = useIntl();

	return (
		<section className="finance-information">
			{formatMessage(
				{
					id: "payment.methods.FairownSubscription.financeInformation",
				},
				{
					ul: (...str) => <ul key={++genericKey.current}>{str}</ul>,
					li: (...str) => <li key={++genericKey.current}>{str}</li>,
					secci: (...str) => (
						<a
							key={++genericKey.current}
							target="_blank"
							href={secciUrl}
							rel="noreferrer"
						>
							{str}
						</a>
					),
				},
			)}
		</section>
	);
};

export default FinanceInformation;
