import authFetch from "@actions/utils/common/authFetch";
import getCheckoutId from "@actions/utils/common/getCheckoutId";
import pushToDataLayer from "@utils/pushToDataLayer";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Authentication from "../authentication/Authentication";

import App from "./App";
import ErrorPage from "./errorPage/ErrorPage";
import AppPlaceholder from "./loaders/appPlaceholder/AppPlaceholder";
import Spinner from "./loaders/spinner/Spinner";

const AppContainer = () => {
	const intlHook = useIntl();
	const isLoaded = useSelector((state) => state.checkout.isLoaded);
	const errors = useSelector((state) => state.checkout.errors);
	const status = useSelector((state) => state.checkout.status);
	const storeId = useSelector((state) => state.checkout.storeId);
	const storeLanguage = useSelector((state) => state.checkout.storeLanguage);
	const email = useSelector((state) => state.recipient.data.email);

	const [isPspInitiated, setIsPspInitiated] = useState(
		status === "PspInitiated",
	);

	useEffect(() => {
		if (storeId && email) {
			pushToDataLayer({ storeId: storeId, profile_email: email});
		}
	}, [storeId, email])

	useEffect(() => {
		document.title = intlHook.formatMessage({ id: "checkout.page.title" });
		document.documentElement.lang = storeLanguage.toLowerCase();
	}, [intlHook, storeLanguage]);

	useEffect(() => {
		if (isPspInitiated) {
			authFetch({
				url: `/api/orders/${getCheckoutId()}/tryFinish`,
				method: "POST",
			}).finally(() => {
				setIsPspInitiated(false);
			});
		}
	}, [isPspInitiated]);

	const getAppContent = () => {
		if (errors.length) {
			return <ErrorPage />;
		}

		if (!isLoaded) {
			return <AppPlaceholder />;
		}

		return <App />;
	};

	return isPspInitiated ? (
		<Spinner />
	) : (
		<Authentication appContent={getAppContent()} />
	);
};

export default AppContainer;
