import { ReactComponent as BlushLogo } from "@assets/img/headerLogos/blush.svg";
import { ReactComponent as KomplettLogo } from "@assets/img/headerLogos/komplett.svg";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const Header = ({
	showBackToCartLink = true,
	cartUrl = null,
	storeUrl = null,
}) => {
	const intlHook = useIntl();
	const logoSet = {
		blush: <BlushLogo />,
		komplett: <KomplettLogo />,
	};

	const logo = window.location.host.includes("blush") ? "blush" : "komplett";
	const goBackLabel = intlHook.formatMessage({ id: "navigation.shop.goback" });

	return (
		<header className="header">
			{showBackToCartLink && cartUrl && (
				<a className="back" href={cartUrl} data-automationid="backToCart">
					<FormattedMessage id="header.back.name" />
				</a>
			)}
			{storeUrl ? (
				<a className="logoLink" href={storeUrl} aria-label={goBackLabel}>
					{logoSet[logo]}
				</a>
			) : (
				<div className="logoLink" aria-label={goBackLabel}>
					{logoSet[logo]}
				</div>
			)}
		</header>
	);
};

Header.propTypes = {
	showBackToCartLink: PropTypes.bool,
	cartUrl: PropTypes.string,
	storeUrl: PropTypes.string,
};

export default Header;
