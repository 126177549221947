import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const Phone = ({
	input,
	className,
	id,
	name,
	value,
	error,
	label,
	autoComplete,
	placeholder,
	type,
	disabled,
	automationid,
	handleChange,
	pattern,
}) => {
	const onChange = (event) => {
		const { value } = event.target;
		const regex = new RegExp(pattern, "g");

		if (regex.test(value)) {
			handleChange({ target: { name, value } });
		}
	};

	const alertId = `${id}-error`;
	return (
		<div className={className}>
			<label className="label" htmlFor={id}>
				{label}
			</label>
			<input
				{...input}
				className="field"
				id={id}
				name={name}
				placeholder={placeholder}
				autoComplete={autoComplete}
				type={type}
				data-hj-suppress
				data-skipui-cobrowse
				aria-invalid={!!error}
				data-automationid={automationid}
				data-testid="phone.input"
				disabled={disabled}
				aria-describedby={alertId}
				onChange={onChange}
				value={value}
				required
			/>
			<span
				className="error-message"
				aria-live="polite"
				aria-atomic="true"
				id={alertId}
			>
				{error && (
					<span className="contents" data-testid={`error.message.${id}`}>
						<FormattedMessage id={error} values={{ fieldname: label }} />
					</span>
				)}
			</span>
		</div>
	);
};

Phone.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	handleChange: PropTypes.func,
	input: PropTypes.object,
	className: PropTypes.string,
	id: PropTypes.string,
	error: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	autoComplete: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	automationid: PropTypes.string,
	pattern: PropTypes.oneOfType([
		PropTypes.instanceOf(RegExp),
		PropTypes.string,
	]),
};

export default Phone;
