import { ALERT_LEVELS } from "@constants/constants";
import {
	GTM_EVENT_NAMES,
	pushToDataLayerAsync,
	sendPaymentEvent,
} from "@utils/GTMUtils";

import * as AlertActions from "../alert.actions";
import * as CheckoutActions from "../checkout.actions";
import authFetch from "../utils/common/authFetch";
import getCheckoutId from "../utils/common/getCheckoutId";

export const PAYMENT_METHOD_NEW_OPTION_SAVE = "PAYMENT_METHOD_NEW_OPTION_SAVE";
export const PAYMENT_METHOD_OPTION_CHANGE = "PAYMENT_METHOD_OPTION_CHANGE";
export const PAYMENT_METHOD_OPTION_UPDATE = "PAYMENT_METHOD_OPTION_UPDATE";

export const PAYMENT_METHOD_VALIDITY_SET = "PAYMENT_METHOD_VALIDITY_SET";
export const PAYMENT_FAIROWN_CONSENT_CHECKBOX_SET =
	"PAYMENT_FAIROWN_CONSENT_CHECKBOX_SET";
export const PAYMENT_SECTION_VALIDITY_SET = "PAYMENT_SECTION_VALIDITY_SET";
export const PAYMENT_SUBSCRIPTION_SECTION_VALIDITY_SET =
	"PAYMENT_SUBSCRIPTION_SECTION_VALIDITY_SET";

/* gift card */
export const ENABLE_GIFT_CARD = "ENABLE_GIFT_CARD";
export const SET_GIFT_CARD_DATA = "SET_GIFT_CARD_DATA";

export const APPLY_GIFT_CARD_REQUESTED = "APPLY_GIFT_CARD_REQUESTED";
export const APPLY_GIFT_CARD_DONE = "APPLY_GIFT_CARD_DONE";

export const REMOVE_GIFT_CARD_REQUESTED = "REMOVE_GIFT_CARD_REQUESTED";
export const REMOVE_GIFT_CARD_DONE = "REMOVE_GIFT_CARD_DONE";

export const REMOVE_ALL_GIFT_CARD_REQUESTED = "REMOVE_ALL_GIFT_CARD_REQUESTED";
export const REMOVE_ALL_GIFT_CARD_DONE = "REMOVE_ALL_GIFT_CARD_DONE";

/* section */
export const DISABLE_SECTION = "DISABLE_SECTION";
export const ENABLE_SECTION = "ENABLE_SECTION";

export const PAYMENT_SSN_SET = "PAYMENT_SSN_SET";

export const SET_BONUS_POINTS = "SET_BONUS_POINTS";

export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";
export const REMOVE_DIRECT_PAYMENT_METHOD = "REMOVE_DIRECT_PAYMENT_METHOD";

export const SET_SUBSCRIPTION_PAYMENT_METHOD =
	"SET_SUBSCRIPTION_PAYMENT_METHOD";
export const REMOVE_SUBSCRIPTION_PAYMENT_METHOD =
	"REMOVE_SUBSCRIPTION_PAYMENT_METHOD";

export const SELECT_PAYMENT_METHOD_REQUESTED =
	"SELECT_PAYMENT_METHOD_REQUESTED";
export const SELECT_PAYMENT_METHOD_DONE = "SELECT_PAYMENT_METHOD_DONE";
export const SELECT_PAYMENT_METHOD_REVERTED = "SELECT_PAYMENT_METHOD_REVERTED";

export const SET_SWISH_PHONE_NUMBER = "SET_SWISH_PHONE_NUMBER";

export const setSwishPhoneNumber = (phoneNumber) => {
	return (dispatch) => {
		dispatch({
			type: SET_SWISH_PHONE_NUMBER,
			payload: { swishPhoneNumber: phoneNumber },
		});
	};
};

export const setPayment = ({ payment }) => {
	return (dispatch, getState) => {
		if (payment.subscriptionPayment) {
			const {
				payment: { isPaymentSubscriptionSectionValid },
			} = getState();

			const isValid = isPaymentSubscriptionSectionValid !== false;
			dispatch(setSubscriptionPaymentMethod(payment.subscriptionPayment));
			dispatch(setSubscriptionSectionValidity(isValid));
		} else {
			dispatch(removeSubscriptionPaymentMethod());
		}

		if (payment.directPayment) {
			const {
				payment: { selectedMethod },
			} = getState();

			if (
				selectedMethod &&
				selectedMethod !== payment.directPayment.selectedMethod
			) {
				dispatch({
					type: SELECT_PAYMENT_METHOD_REVERTED,
					revertToMethodType: payment.directPayment.selectedMethod,
				});

				dispatch(
					AlertActions.addAlert(
						"checkout.payment.general",
						ALERT_LEVELS.ERROR,
						"payment.alert.select.method",
					),
				);
			}

			dispatch(setGiftCard(payment.directPayment.giftCard));
			dispatch(setBonusPoints(payment.directPayment.bonusPoints));
			dispatch(
				setMethods(
					payment.directPayment.methods,
					payment.directPayment.selectedMethod,
				),
			);
		} else {
			dispatch(removeDirectPaymentMethod());
		}
	};
};

export const setMethods = (methods, selectedMethod) => {
	return (dispatch) => {
		dispatch({
			type: SET_PAYMENT_METHODS,
			payload: { methods, selectedMethod },
		});
	};
};

export const setSubscriptionPaymentMethod = (subscriptionMethod) => {
	return (dispatch) => {
		dispatch({
			type: SET_SUBSCRIPTION_PAYMENT_METHOD,
			payload: { subscriptionMethod },
		});
	};
};

export const setBonusPoints = (bonusPoints) => {
	return (dispatch) => {
		dispatch({ type: SET_BONUS_POINTS, bonusPoints });
	};
};

export const setGiftCard = (giftCard) => {
	return (dispatch) => {
		dispatch(enableGiftCardForPayment(giftCard.isEnabled));
		dispatch(setGiftCardData(giftCard.giftCards));
		dispatch(setSectionValidityWhenCoveredByGiftCard());
	};
};

export const enableGiftCardForPayment = (giftCardIsEnabled) => {
	return (dispatch) => {
		dispatch({ type: ENABLE_GIFT_CARD, payload: { giftCardIsEnabled } });
	};
};

export const setGiftCardData = (giftCardData) => {
	return (dispatch, getState) => {
		const {
			payment: { giftCards },
		} = getState();
		if (giftCardData.length < giftCards.length) {
			const removedGiftCards = giftCards
				.slice(giftCardData.length)
				.map((gc) => gc.code);
			if (removedGiftCards.length > 1) {
				dispatch(
					AlertActions.addAlert(
						"checkout.payment.giftCard",
						ALERT_LEVELS.WARN,
						"payment.giftCard.are.notNeeded",
						{ giftcards: removedGiftCards.join(", ") },
					),
				);
			} else {
				dispatch(
					AlertActions.addAlert(
						"checkout.payment.giftCard",
						ALERT_LEVELS.WARN,
						"payment.giftCard.is.notNeeded",
						{ giftcard: removedGiftCards[0] },
					),
				);
			}
		}
		dispatch({ type: SET_GIFT_CARD_DATA, payload: { giftCardData } });
	};
};

export const setSectionValidityWhenCoveredByGiftCard = () => {
	return (dispatch, getState) => {
		const {
			checkout: { isCoveredByGiftCard },
		} = getState();
		isCoveredByGiftCard && dispatch(setSectionValidity(isCoveredByGiftCard));
	};
};

export const setSectionValidityWhenCoveredByBonusPoints = () => {
	return (dispatch, getState) => {
		const {
			checkout: { isCoveredByBonusPoints },
		} = getState();

		isCoveredByBonusPoints &&
			dispatch(setSectionValidity(isCoveredByBonusPoints));
	};
};

export const setSectionValidityWhenCoveredByGiftCardsAndBonusPoints = () => {
	return (dispatch, getState) => {
		const {
			checkout: { isCoveredByGiftCardsAndBonusPoints },
		} = getState();

		isCoveredByGiftCardsAndBonusPoints &&
			dispatch(setSectionValidity(isCoveredByGiftCardsAndBonusPoints));
	};
};

export const selectMethod =
	(methodType, shouldRemoveGiftCard) => async (dispatch) => {
		try {
			dispatch(CheckoutActions.showLoader());
			dispatch({ type: SELECT_PAYMENT_METHOD_REQUESTED, methodType });

			if (shouldRemoveGiftCard) {
				await dispatch(removeAllGiftCardsRequest());
			}

			await authFetch({
				url: `/api/checkouts/${getCheckoutId()}/selectedPaymentMethod/${methodType}`,
				method: "PUT",
			});

			await pushToDataLayerAsync({
				data: {
					event: GTM_EVENT_NAMES.PAYMENT_METHOD_SELECTED,
					selected: methodType,
				},
			});
			await dispatch(CheckoutActions.fetchCheckout());

			dispatch({ type: SELECT_PAYMENT_METHOD_DONE });
		} catch (e) {
			if (e.status) {
				await dispatch(CheckoutActions.fetchCheckout());
			} else {
				dispatch(
					AlertActions.addAlert(
						"checkout.general",
						ALERT_LEVELS.ERROR,
						"network.lost",
					),
				);
			}
		} finally {
			dispatch(CheckoutActions.hideLoader());
		}
	};

export function selectMethodOption(methodType, optionCode) {
	return (dispatch) => {
		dispatch({ type: PAYMENT_METHOD_OPTION_CHANGE, methodType, optionCode });
		dispatch(AlertActions.removeAllAlerts("checkout.payment.general"));
	};
}

export const setMethodValidity = (methodType, isValid) => {
	return (dispatch, getState) => {
		const state = getState();
		const isSubscriptionMethod =
			state.payment?.subscriptionMethod?.type === methodType;

		if (isSubscriptionMethod) {
			dispatch(setSubscriptionSectionValidity(isValid));

			return;
		}

		dispatch({ type: PAYMENT_METHOD_VALIDITY_SET, methodType, isValid });
	};
};

export const setSectionValidity = (isValid) => {
	return (dispatch) => {
		dispatch({ type: PAYMENT_SECTION_VALIDITY_SET, isValid });
	};
};

export const setSubscriptionSectionValidity = (isValid) => {
	return (dispatch) => {
		dispatch({ type: PAYMENT_SUBSCRIPTION_SECTION_VALIDITY_SET, isValid });
	};
};

export const removeSubscriptionPaymentMethod = () => {
	return (dispatch) => {
		dispatch({ type: REMOVE_SUBSCRIPTION_PAYMENT_METHOD });
	};
};

export const removeDirectPaymentMethod = () => {
	return (dispatch) => {
		dispatch({ type: REMOVE_DIRECT_PAYMENT_METHOD });
	};
};

export function setValidity(methodType, isValid) {
	return (dispatch) => {
		dispatch(setMethodValidity(methodType, isValid));
		dispatch(setSectionValidity(isValid));
	};
}

export function useBonusPoints() {
	return (dispatch) => {
		dispatch(CheckoutActions.showLoader());
		sendPaymentEvent("BonusPoints: 1");
		authFetch({
			url: `api/checkouts/${getCheckoutId()}/payments/bonusPoints/use`,
			method: "POST",
		})
			.then(() => dispatch(CheckoutActions.fetchCheckout()))
			.finally(() => dispatch(CheckoutActions.hideLoader()));
	};
}

export function unuseBonusPoints() {
	return (dispatch) => {
		dispatch(CheckoutActions.showLoader());
		sendPaymentEvent("BonusPoints: 0");
		authFetch({
			url: `api/checkouts/${getCheckoutId()}/payments/bonusPoints/unuse`,
			method: "POST",
		})
			.then(() => dispatch(CheckoutActions.fetchCheckout()))
			.finally(() => dispatch(CheckoutActions.hideLoader()));
	};
}

/* gift card */
export const applyGiftCard = (giftCardCode) => async (dispatch) => {
	try {
		const encodedGiftCardCode = encodeURIComponent(giftCardCode);

		dispatch(CheckoutActions.showLoader());
		dispatch({ type: APPLY_GIFT_CARD_REQUESTED });
		dispatch(AlertActions.removeAllAlerts("checkout.payment.giftCard"));

		await authFetch({
			url: `api/checkouts/${getCheckoutId()}/giftcards/${encodedGiftCardCode}`,
			method: "POST",
		});
		await dispatch(CheckoutActions.fetchCheckout());
	} catch (response) {
		dispatch(
			response.body
				? AlertActions.addAlert(
						"checkout.payment.giftCard",
						ALERT_LEVELS.WARN,
						response.body.stringKey,
				  )
				: AlertActions.addAlert(
						"checkout.general",
						ALERT_LEVELS.ERROR,
						"network.lost",
				  ),
		);
	} finally {
		dispatch({ type: APPLY_GIFT_CARD_DONE });
		dispatch(CheckoutActions.hideLoader());
	}
};

export const removeAllGiftCards = () => async (dispatch) => {
	try {
		dispatch(CheckoutActions.showLoader());
		dispatch(AlertActions.removeAllAlerts("checkout.payment.giftCard"));

		await dispatch(removeAllGiftCardsRequest());
		await dispatch(CheckoutActions.fetchCheckout());
	} finally {
		dispatch(CheckoutActions.hideLoader());
	}
};
export const removeSingleGiftCard = (code) => async (dispatch) => {
	try {
		dispatch(CheckoutActions.showLoader());
		dispatch(AlertActions.removeAllAlerts("checkout.payment.giftCard"));
		dispatch({ type: REMOVE_GIFT_CARD_REQUESTED, code });

		await authFetch({
			url: `api/checkouts/${getCheckoutId()}/giftcards/${code}`,
			method: "DELETE",
		});
		await dispatch(CheckoutActions.fetchCheckout());

		dispatch({ type: REMOVE_GIFT_CARD_DONE });
	} catch (e) {
		dispatch(
			AlertActions.addAlert(
				"checkout.payment.giftCard",
				ALERT_LEVELS.WARN,
				"payment.giftCard.delete.error",
			),
		);
	} finally {
		dispatch(CheckoutActions.hideLoader());
	}
};

export const removeAllGiftCardsRequest = () => async (dispatch) => {
	dispatch(AlertActions.removeAllAlerts("checkout.payment.giftCard"));

	try {
		dispatch({ type: REMOVE_ALL_GIFT_CARD_REQUESTED });
		await authFetch({
			url: `api/checkouts/${getCheckoutId()}/giftcards`,
			method: "DELETE",
		});
		dispatch({ type: REMOVE_ALL_GIFT_CARD_DONE });
	} catch (e) {
		dispatch(
			AlertActions.addAlert(
				"checkout.payment.giftCard",
				ALERT_LEVELS.WARN,
				"payment.giftCard.delete.error",
			),
		);
	}
};

export const showGiftCardExistsAlert = () => {
	return (dispatch) => {
		dispatch(
			AlertActions.addAlert(
				"checkout.payment.giftCard",
				ALERT_LEVELS.WARN,
				"payment.giftCard.exists",
			),
		);
	};
};

/* Fairown Subscription */
export const switchToDirectPayment = () => async (dispatch) => {
	dispatch(CheckoutActions.showLoader());

	try {
		await authFetch({
			url: `/api/checkouts/${getCheckoutId()}/subscriptionPayment`,
			method: "DELETE",
		});
		await dispatch(CheckoutActions.fetchCheckout());
	} finally {
		dispatch(CheckoutActions.hideLoader());
	}
};

/* section */
export const disableSection = () => {
	return (dispatch) => dispatch({ type: DISABLE_SECTION });
};

export const enableSection = () => {
	return (dispatch) => dispatch({ type: ENABLE_SECTION });
};

export const setSSN = (ssn) => {
	return (dispatch) => {
		dispatch({ type: PAYMENT_SSN_SET, ssn });
	};
};
