import * as UserActions from "@actions/user.actions";
import Spinner from "@components/app/loaders/spinner/Spinner";
import { USER_STATES } from "@constants/constants";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const Authentication = ({ appContent }) => {
	const user = useSelector((state) => state.user.state);
	const isUserAccepted = useSelector((state) => state.checkout.isUserAccepted);

	const dispatch = useDispatch();

	const showAppContent = user === USER_STATES.AUTHENTICATED && isUserAccepted;

	useEffect(() => {
		dispatch(UserActions.getUser());
	}, [dispatch]);

	return showAppContent ? appContent : <Spinner />;
};

export default Authentication;
