import { KEY_MAP } from "@constants/constants";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const ReactCheckbox = ({
	value,
	handleChange,
	id,
	label,
	disabled,
	automationId,
	testId,
}) => {
	const handleKeyDown = (e) => {
		if (e.which === KEY_MAP.SPACE) {
			e.preventDefault();
			handleChange({ target: { checked: !value } });
		}
	};

	return (
		<div
			className={classNames("react-checkbox", { disabled })}
			data-automationid={automationId}
			data-testid={testId}
		>
			<input
				checked={value}
				id={id}
				type="checkbox"
				className="control"
				disabled={disabled}
				onChange={handleChange}
				readOnly
			/>
			<label
				className="label"
				htmlFor={id}
				tabIndex={disabled ? -1 : 0}
				onKeyDown={handleKeyDown}
			>
				{label}
			</label>
		</div>
	);
};

ReactCheckbox.propTypes = {
	value: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	id: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	disabled: PropTypes.bool,
	automationId: PropTypes.string,
	testId: PropTypes.string,
};

export default ReactCheckbox;
