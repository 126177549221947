import React from "react";
import { useIntl } from "react-intl";

const Faq = () => {
	const { formatMessage } = useIntl();
	return (
		<a
			className="faq"
			target="_blank"
			href={formatMessage({
				id: "payment.methods.FairownSubscription.faq.link",
			})}
			rel="noreferrer"
		>
			{formatMessage({
				id: "payment.methods.FairownSubscription.faq",
			})}
		</a>
	);
};

export default Faq;
