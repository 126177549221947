export default {
	number: {
		monetary: {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		},
		shortMonetary: {
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		},
		monthlyMonetary: {
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		},
	},
};
