import storageFactory from "./StorageFactory";

const OidcUserKeyPrefix = "oidc.user";

export default class BackendStorage {
	constructor() {
		this.storage = storageFactory.getStorage();
	}

	setItem(key, value) {
		this.storage.setItem(key, value);

		if (key.startsWith(OidcUserKeyPrefix)) {
			return;
		}

		const request = new XMLHttpRequest();
		request.open("PUT", `api/storage?key=${encodeURIComponent(key)}`, false); // `false` makes the request synchronous
		request.setRequestHeader("Content-Type", "application/json");
		request.send(value);
	}

	getItem(key) {
		const item = this.storage.getItem(key);
		if (item) {
			return item;
		}

		if (key.startsWith(OidcUserKeyPrefix)) {
			return null;
		}

		const request = new XMLHttpRequest();
		request.open("GET", `api/storage?key=${encodeURIComponent(key)}`, false); // `false` makes the request synchronous
		request.send(null);

		if (request.status === 200) {
			this.storage.setItem(key, request.responseText);
			return request.responseText;
		}
		return null;
	}

	removeItem(key) {
		this.storage.removeItem(key);

		if (key.startsWith(OidcUserKeyPrefix)) {
			return;
		}

		const request = new XMLHttpRequest();
		request.open("DELETE", `api/storage?key=${encodeURIComponent(key)}`, false); // `false` makes the request synchronous
		request.send(null);
	}

	key(index) {
		return this.storage.key(index);
	}
}
