import React, { useRef } from "react";
import { useIntl } from "react-intl";

const Choices = ({
	period,
	residualValue,
	monthlyCost,
	numberOfTermsLeftAfterRenewalPeriod,
}) => {
	const { formatMessage, formatNumber } = useIntl();
	const genericKey = useRef(0);

	return (
		<section className="choices">
			<h2 className="header">
				{formatMessage(
					{ id: "payment.methods.FairownSubscription.choices.header" },
					{ period: formatMessage({ id: "month" }, { value: period }) },
				)}
			</h2>
			<p className="choice">
				<strong>
					{formatMessage({
						id: "payment.methods.FairownSubscription.choices.choice1.title",
					})}
				</strong>
				<span>
					{formatMessage(
						{
							id: "payment.methods.FairownSubscription.choices.choice1.description",
						},
						{
							residualValue: formatMessage(
								{ id: "currencySymbol" },
								{ value: formatNumber(residualValue) },
							),
							span: (...str) => <span key={++genericKey.current}>{str}</span>,
							strong: (...str) => (
								<strong key={++genericKey.current}>{str}</strong>
							),
						},
					)}
				</span>
			</p>
			<span className="choice-separator">
				{formatMessage({
					id: "payment.methods.FairownSubscription.choices.or",
				})}
			</span>
			<p className="choice">
				<strong>
					{formatMessage({
						id: "payment.methods.FairownSubscription.choices.choice2.title",
					})}
				</strong>
				<span>
					{formatMessage(
						{
							id: "payment.methods.FairownSubscription.choices.choice2.description",
						},
						{
							monthlyCost: formatMessage(
								{ id: "perMonth" },
								{
									value: formatMessage(
										{ id: "currencySymbol" },
										{ value: formatNumber(monthlyCost) },
									),
								},
							),
							numberOfTermsLeftAfterRenewalPeriod: formatMessage(
								{ id: "month" },
								{ value: numberOfTermsLeftAfterRenewalPeriod },
							),
							span: (...str) => <span key={++genericKey.current}>{str}</span>,
							strong: (...str) => (
								<strong key={++genericKey.current}>{str}</strong>
							),
						},
					)}
				</span>
			</p>
		</section>
	);
};

export default Choices;
