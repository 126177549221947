import React from "react";

const Collapsible = ({
	id,
	className,
	dataTestId,
	dataAutomationId,
	expanded,
	children,
	trigger,
	preventCollapse = false,
}) => {
	const shouldDisplayContent = !!children && (expanded || preventCollapse);

	return (
		<div
			id={id}
			data-testid={dataTestId || id}
			data-automationid={dataAutomationId || id}
			className={className}
			aria-live="polite"
			aria-relevant="additions text"
			aria-atomic="false"
		>
			{trigger}
			{shouldDisplayContent && children}
		</div>
	);
};

export default Collapsible;
