import { setPorterBuddyInstructions } from "@actions/shipping.actions";
import TextArea from "@components/form/TextArea";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

const PorterInstructions = ({
	instructionsValue,
	characterLimit,
	placeholder,
}) => {
	const dispatch = useDispatch();
	const handlePorterInstructionsChange = (porterBuddyInstruction) => {
		dispatch(setPorterBuddyInstructions(porterBuddyInstruction));
	};

	return (
		<>
			<TextArea
				pattern={/^[^{}\[\]<>\\_;^#~]*$/}
				value={instructionsValue}
				onChange={handlePorterInstructionsChange}
				fieldValidation={{ maxLength: characterLimit }}
				className="porterbuddy-delivery-instructions"
				placeholder={placeholder}
			/>
			<span
				className={classNames("porterbuddy-character-limit", {
					"near-max": instructionsValue.length >= characterLimit / 2,
					maxed: instructionsValue.length === characterLimit,
				})}
			>
				{instructionsValue.length} / {characterLimit}
			</span>
		</>
	);
};

PorterInstructions.propTypes = {
	instructionsValue: PropTypes.string,
	characterLimit: PropTypes.number,
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default PorterInstructions;
