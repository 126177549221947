import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const AppLoader = ({ show }) => {
	return (
		<div className={classNames("app-loader", { show })}>
			{show && (
				<div className="throbber" role="progressbar">
					<svg className="circular" data-automationid="circular">
						<circle className="path" cx="40" cy="40" r="38" />
					</svg>
				</div>
			)}
		</div>
	);
};

AppLoader.propTypes = {
	show: PropTypes.bool,
};

export default AppLoader;
