const StorageFeatureDetectionKey = "LocalStorageFeatureDetectionKey";

export default class StorageFeatureDetection {
	static isEnabled(storageType) {
		try {
			window[storageType].setItem(
				StorageFeatureDetectionKey,
				"feature detection check",
			);
			window[storageType].getItem(StorageFeatureDetectionKey);
			window[storageType].removeItem(StorageFeatureDetectionKey);
			return true;
		} catch (e) {
			return false;
		}
	}
}
