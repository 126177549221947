import AlertsContainer from "@components/alerts/AlertsContainer";
import ShippingMethods from "@components/order/shipping/ShippingMethods";
import { Divider } from "@components/v2/components/dividers/Divider";
import Section from "@components/v2/components/section/Section";
import {
	SectionContent,
	SectionHeader,
	SectionLabel,
	SectionNumber,
} from "@components/v2/components/section/section.styles";
import { SECTIONS_NAMES } from "@constants/constants";
import { GTM_EVENT_NAMES, pushToDataLayerAsync } from "@utils/GTMUtils";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useElementObserver } from "../../../customHooks/useElementObserver";

const Shipping = () => {
	const { formatMessage } = useIntl();
	const shippingRef = useRef(null);
	const isSectionDisabled = useSelector(
		(state) => state.order.isSectionDisabled,
	);
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);
	const availableShipping = useSelector(
		(state) => state.order.logisticUnits[0].shipping.availableShipping || [],
	);

	const shippingPerTransport = [
		...new Set(
			availableShipping.flatMap(({ typeCode, options }) =>
				options.map((option) => `${typeCode} ${option.transporter}`),
			),
		),
	];

	useElementObserver(shippingRef, () => {
		pushToDataLayerAsync({
			data: {
				event: GTM_EVENT_NAMES.SHIPPING_VISITED,
				options: shippingPerTransport.join(", "),
			},
		});
	});

	return (
		<Section
			id={SECTIONS_NAMES.SHIPPING}
			className="shipping"
			ref={shippingRef}
		>
			<SectionHeader>
				{!isRenewalMode && <SectionNumber>{2}</SectionNumber>}
				<SectionLabel>
					{formatMessage({ id: "order.shipping.methods.label" })}
				</SectionLabel>
			</SectionHeader>
			<Divider />
			<SectionContent className="methods" data-automationid="shippingMethods">
				<ShippingMethods isSectionDisabled={isSectionDisabled} />
			</SectionContent>
			<AlertsContainer
				role="checkout.shipping.general"
				disabled={isSectionDisabled}
			/>
		</Section>
	);
};

export default Shipping;
