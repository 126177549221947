import { ReactComponent as MasterCardLogo } from "@assets/img/paymentCards/mastercard.svg";
import { ReactComponent as VisaLogo } from "@assets/img/paymentCards/visa.svg";
import { ReactComponent as SwishLogo } from "@assets/img/swish-logo.svg";
import { ReactComponent as VippsLogo } from "@assets/img/vipps-logo.svg";
import { ReactComponent as WalleyLogo } from "@assets/img/walley-logo.svg";
import {
	PAYMENT_METHOD_NAMES,
	PAYMENT_METHOD_LOGOS,
} from "@constants/constants";
import PropTypes from "prop-types";
import React from "react";
import { FormattedHTMLMessage, useIntl } from "react-intl";

import { PaymentTitle } from "./PaymentHeader.styles";

const extractPaymentCost = (method) =>
	method.optionsOrder
		.map((optionKey) => {
			const option = method.options[optionKey];
			const paymentPlan = option.paymentPlan || {};
			const monthlyCost = paymentPlan.monthlyCost;
			const cost = option.cost;

			return monthlyCost || cost;
		})
		.sort((a, b) => a - b)[0];

const PaymentHeader = ({ method }) => {
	const { formatMessage } = useIntl();
	const methodType = method.type;
	const paymentCost = extractPaymentCost(method);
	const methodHasLogo = PAYMENT_METHOD_LOGOS.includes(methodType);
	const methodHasAriaLabel = [
		PAYMENT_METHOD_NAMES.VIPPS,
		PAYMENT_METHOD_NAMES.SWISH,
	].includes(methodType);
	const logos = {
		[PAYMENT_METHOD_NAMES.VIPPS]: VippsLogo,
		[PAYMENT_METHOD_NAMES.SWISH]: SwishLogo,
		[PAYMENT_METHOD_NAMES.WALLEY_INVOICE]: WalleyLogo,
		[PAYMENT_METHOD_NAMES.WALLEY_DEFERRED]: WalleyLogo,
		[PAYMENT_METHOD_NAMES.WALLEY_INSTALLMENT]: WalleyLogo,
	};
	const ComponentLogo = logos[methodType];
	const ariaLabel = methodHasLogo &&
		methodHasAriaLabel && {
			"aria-label": formatMessage({
				id: `payment.methods.${methodType}.title`,
				values: { value: `${paymentCost}` },
			}),
		};

	return (
		<PaymentTitle
			className={`title method-${methodType.toLowerCase()}`}
			{...ariaLabel}
		>
			{methodHasLogo && <ComponentLogo />}
			<FormattedHTMLMessage
				id={`payment.methods.${methodType}.title`}
				values={{ value: `${paymentCost}` }}
			/>
			{methodType === PAYMENT_METHOD_NAMES.CREDIT_CARD && (
				<>
					<MasterCardLogo /> <VisaLogo />
				</>
			)}
		</PaymentTitle>
	);
};

PaymentHeader.propTypes = {
	method: PropTypes.shape({
		type: PropTypes.string.isRequired,
		optionsOrder: PropTypes.array.isRequired,
		options: PropTypes.object.isRequired,
	}),
};

export default PaymentHeader;
