import Collapsible from "@components/v2/components/collapsible/Collapsible";
import RadioButton from "@components/v2/components/form/RadioButton";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Panels = styled.div`
	display: grid;
	grid-gap: 16px;
`;

export const CollapsiblePanel = styled(Collapsible)(
	({
		theme: {
			colors: { collapsiblePanel },
		},
	}) => css`
		background: ${collapsiblePanel.background};
		border-radius: 8px;
		box-shadow: 0 4px 26px rgb(0, 0, 0, 9%);
		line-height: 24px;
	`,
);

export const CollapsablePanelHeaderRadio = styled(RadioButton)`
	padding: 16px;
`;

export const CollapsiblePanelContent = styled.div`
	padding: 16px;
`;
