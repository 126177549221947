import { DrawerBackdrop, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerWrapper } from './Drawer.styles';
import { drawerCloseIcon } from '../../../utils/svgProvider';
import React, { useEffect } from 'react';

const HEADER_IMAGE: Record<string, string> = Object.freeze({
	"310": "/static/mf/assets/callback_drawer_header_no.png",
	"324": "/static/mf/assets/callback_drawer_header_no.png",
	"325": "/static/mf/assets/callback_drawer_header_no.png",
	
	"312": "/static/mf/assets/callback_drawer_header_se.png",
	"318": "/static/mf/assets/callback_drawer_header_se.png",
	"344": "/static/mf/assets/callback_drawer_header_se.png",
	
	"321": "/static/mf/assets/callback_drawer_header_dk.png"
});

type DrawerProps = {
	children: React.ReactNode;
	title: string;
	
	isOpen: boolean;
	onClose: () => void;
	
	storeId: string;
}
export const Drawer: React.FC<DrawerProps> = ({ onClose, isOpen, children, storeId }) => {
	
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		}
		
		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [isOpen]);
	
	return (
		<>
			{isOpen && <DrawerBackdrop onClick={onClose} />}
			<DrawerWrapper isOpen={isOpen}>
				<DrawerHeader>
					<img
						src={HEADER_IMAGE[storeId]}
						style={{ width: '100%', aspectRatio: '9 / 3' }}
					/>
					<DrawerCloseButton onClick={onClose}>
						{drawerCloseIcon}
					</DrawerCloseButton>
				</DrawerHeader>
				<DrawerContent>
					{children}
				</DrawerContent>
			</DrawerWrapper>
		</>
	);
};