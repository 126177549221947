import React from "react";
import { castToBoolean } from "../../../utils/helpers";
import { checkboxChecked, checkboxUnchecked } from "../../../utils/svgProvider";

import {
  ErrorMessage,
  IconWrapper,
  Label,
  StyledCheckbox,
  Wrapper,
} from "./Checkbox.styles";

interface CheckboxProps extends React.ComponentPropsWithoutRef<"input"> {
  disabled: boolean;
  label: string;
  error?: string;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const isChecked = castToBoolean(props.value?.toString());

  return (
    <Wrapper>
      <Label htmlFor={props.id}>
        <IconWrapper isDisabled={props.disabled}>
          {isChecked ? checkboxChecked : checkboxUnchecked}
        </IconWrapper>
        <StyledCheckbox
          data-testid="flexConsent.input"
          data-automationid="flexConsent.input"
          type="checkbox"
          id={props.id}
          checked={isChecked}
          value={props.value}
          onChange={props.onChange}
        />
        {props.label}
      </Label>
      {props.error && (
        <ErrorMessage
          data-testid="flexConsent.error"
          data-automationid="flexConsent.error"
        >
          {props.error}
        </ErrorMessage>
      )}
    </Wrapper>
  );
};

export default Checkbox;
