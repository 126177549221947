import React from "react";
import { StyledButton } from "./Button.styles";
import { ButtonTypes } from "./Button.types";
import { getTheme } from "../../../app/theme";
import { ThemeProvider } from "@emotion/react";

const Button: React.FC<ButtonTypes> = (props) => (
  <ThemeProvider theme={getTheme(props.storeId)}>
    <StyledButton {...props}>
      {props.icon} {props.label}
    </StyledButton>
  </ThemeProvider>
);

export default Button;
