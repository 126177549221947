import PropTypes from "prop-types";
import React from "react";

import Product from "../Product";

const Offer = ({
	name,
	description,
	quantity,
	image,
	subItems,
	discounts,
	productId,
	stock,
	price,
	monthlyPrice,
	offerPrice,
}) => {
	return (
		<tbody className="offer">
			<Product
				name={name}
				quantity={quantity}
				price={price}
				monthlyPrice={monthlyPrice}
				image={image}
				productId={productId}
				description={description}
				stock={stock}
				discounts={discounts}
				offerPrice={offerPrice}
			/>
			{subItems &&
				subItems.length > 0 &&
				subItems.map((subItem) => {
					return (
						<Product
							key={subItem.id}
							{...subItem}
							image={null} //blocks subitems image rendering
							description={null} //blocks subitems description rendering
							stock={null} //blocks subitems stock rendering
						/>
					);
				})}
		</tbody>
	);
};

Offer.propTypes = {
	subItems: PropTypes.array,
	discounts: PropTypes.array,
	name: PropTypes.string,
	quantity: PropTypes.number,
	price: PropTypes.number,
	monthlyPrice: PropTypes.number,
	image: PropTypes.string,
	productId: PropTypes.string,
	description: PropTypes.string,
	stock: PropTypes.object,
};

export default Offer;
