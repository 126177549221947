import { getUser } from "@components/authentication/authenticationConfig";
import handleErrors from "@utils/handleErrors";

import getCheckoutId from "./getCheckoutId";

export default ({ url, method = "GET", body, options = {} }) =>
	getUser().then(({ user } = {}) =>
		window
			.fetch(url, {
				method,
				headers: new Headers({
					Authorization: `Bearer ${user.access_token}`,
					"Content-Type": "application/json",
					Accept: "application/json",
					"Request-Id": getCheckoutId(),
				}),
				body,
				mode: "same-origin",
				cache: "no-store",
				...options,
			})
			.then(handleErrors),
	);
