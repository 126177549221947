import {
	RECIPIENT_SAVE_DONE,
	saveRecipient,
	setEditModeVisibility,
} from "@actions/recipient.actions";
import AlertsContainer from "@components/alerts/AlertsContainer";
import {
	CancelButton,
	CancelIcon,
	Error,
	Fieldset,
	Form,
	FormWrapper,
	Label,
	Legend,
	SubmitButton,
	TextInput,
} from "@components/recipient/Recipient.styles";
import Email from "@components/recipient/recipientForm/Email";
import NameInput from "@components/recipient/recipientForm/NameInput";
import PhoneInput from "@components/recipient/recipientForm/PhoneInput";
import PostcodeInput from "@components/recipient/recipientForm/PostcodeInput";
import UpdateUserProfileCheckbox from "@components/recipient/recipientForm/UpdateUserProfileCheckbox";
import {
	requiredMessage,
	validateAlphabeticCharacters,
	validateCity,
	validateForbiddenCharacters,
	validateMinLength,
} from "@components/recipient/recipientForm/recipientFormValidation";
import { Divider } from "@components/v2/components/dividers/Divider";
import {
	BUTTON_TYPES,
	BUTTON_VARIANTS,
	DIVIDER_VARIANTS,
} from "@components/v2/constants";
import { KEY_MAP } from "@constants/constants";
import { sendPaymentEvent } from "@utils/GTMUtils";
import React, { useCallback, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const RecipientForm = () => {
	const { formatMessage } = useIntl();
	const dispatch = useDispatch();

	const selectIsAlertVisible = (alert) => !!Object.keys(alert || []).length;

	const isUserAnonymousFindEmailAlertVisible = useSelector((state) =>
		selectIsAlertVisible(state.alerts["user.anonymous.find.email"]),
	);
	const isCheckoutRecipientGeneralAlertVisible = useSelector((state) =>
		selectIsAlertVisible(state.alerts["checkout.recipient.general"]),
	);

	const isSaving = useSelector((state) => state.recipient.isSaving);

	const {
		handleSubmit,
		trigger,
		formState: { errors, isValid, dirtyFields },
	} = useFormContext();

	const dirtyFieldsNamesArray = Object.keys(dirtyFields);
	const shouldDisplayUpdateUserProfile =
		!!dirtyFieldsNamesArray.length && isValid;

	const handleSubmitForm = handleSubmit(async (formData) => {
		if (!dirtyFieldsNamesArray.length) {
			dispatch({ type: RECIPIENT_SAVE_DONE });
			dispatch(setEditModeVisibility(false));

			return;
		}

		await sendPaymentEvent("ClickedUpdateAddress");
		await dispatch(saveRecipient(formData));
	});

	const handleCloseForm = useCallback(() => {
		dispatch({ type: RECIPIENT_SAVE_DONE });
		dispatch(setEditModeVisibility(false));
	}, [dispatch]);

	const dialogCallbackRef = useCallback((dialogElement) => {
		if (!dialogElement) {
			return;
		}

		dialogElement.tabIndex = -1;
		dialogElement.focus();
	}, []);

	useEffect(() => {
		trigger();
	}, [trigger]);

	useEffect(() => {
		const handleCloseFormOnEscape = ({ keyCode }) =>
			keyCode === KEY_MAP.ESCAPE && handleCloseForm();

		document.addEventListener("keydown", handleCloseFormOnEscape);

		return () =>
			document.removeEventListener("keydown", handleCloseFormOnEscape);
	}, [handleCloseForm]);

	return (
		<FormWrapper ref={dialogCallbackRef}>
			<Form
				className="recipient-form"
				autoComplete="on"
				onSubmit={handleSubmitForm}
			>
				<Legend>
					<FormattedMessage id="recipient.section.header" />
				</Legend>

				<Email />

				<Divider variant={DIVIDER_VARIANTS.DASHED} />

				{isUserAnonymousFindEmailAlertVisible && (
					<AlertsContainer role="user.anonymous.find.email" />
				)}

				<p className="phone">
					<Label htmlFor="phone">
						{formatMessage({ id: "recipient.data.phone.label" })}
					</Label>
					<PhoneInput />
					<Error inputName="phone" />
				</p>

				<p className="name">
					<Label htmlFor="name">
						{formatMessage({ id: "recipient.data.name.label" })}
					</Label>
					<NameInput />
					<Error inputName={"name"} />
				</p>

				<p className="address-line-1">
					<Label htmlFor="addressLine1">
						{formatMessage({ id: "recipient.data.address.label" })}
					</Label>
					<Controller
						name="addressLine1"
						rules={{
							required: requiredMessage,
							validate: {
								validateMinLength: (value) => validateMinLength(value, 2),
								validateForbiddenCharacters,
								validateAlphabeticCharacters,
							},
						}}
						render={({ field }) => (
							<TextInput
								id="addressLine1"
								type="text"
								autoComplete="address-line1"
								placeholder={formatMessage({
									id: "recipient.data.address.placeholder",
								})}
								maxLength={50}
								disabled={isSaving}
								isInvalid={!!errors.addressLine1}
								ariaInvalid={!!errors.addressLine1}
								hotJarSuppress={true}
								{...field}
							/>
						)}
					/>
					<Error inputName="addressLine1" />
				</p>

				<Fieldset id="recipientForm.post">
					<p className="postcode">
						<Label htmlFor="postcode">
							{formatMessage({ id: "recipient.data.postcode.label" })}
						</Label>
						<PostcodeInput />
						<Error inputName="postcode" />
					</p>

					<p className="city">
						<Label htmlFor="city">
							{formatMessage({ id: "recipient.data.city.label" })}
						</Label>
						<Controller
							name="city"
							rules={{
								required: requiredMessage,
								validate: {
									validateForbiddenCharacters,
									validateCity,
								},
							}}
							render={({ field }) => (
								<TextInput
									id="city"
									autoComplete="address-level2"
									maxLength={50}
									disabled={isSaving}
									isInvalid={!!errors.city}
									ariaInvalid={!!errors.city}
									hotJarSuppress={true}
									{...field}
								/>
							)}
						/>
						<Error inputName="city" />
					</p>
				</Fieldset>

				<p className="address-line-2">
					<Label htmlFor="addressLine2">
						{formatMessage({ id: "recipient.data.address2.label" })}
						{formatMessage({ id: "recipient.data.optional" })}
					</Label>
					<Controller
						name="addressLine2"
						rules={{
							validate: {
								validateForbiddenCharacters,
							},
						}}
						render={({ field }) => (
							<TextInput
								id="addressLine2"
								autoComplete="address-level2"
								maxLength={40}
								disabled={isSaving}
								isInvalid={!!errors.addressLine2}
								ariaInvalid={!!errors.addressLine2}
								hotJarSuppress={true}
								{...field}
							/>
						)}
					/>
					<Error inputName={"addressLine2"} />
				</p>

				{shouldDisplayUpdateUserProfile && <UpdateUserProfileCheckbox />}

				{isCheckoutRecipientGeneralAlertVisible && (
					<AlertsContainer
						role="checkout.recipient.general"
						shouldScroll={false}
					/>
				)}

				<SubmitButton
					id="submit-recipient-form"
					type={BUTTON_TYPES.SUBMIT}
					variant={BUTTON_VARIANTS.PRIMARY}
					className="submit-button"
					disabled={!isValid}
				>
					{formatMessage({ id: "recipient.data.update.button.label" })}
				</SubmitButton>

				<CancelButton
					title={formatMessage({
						id: "recipient.section.edit.cancel",
					})}
					onClick={handleCloseForm}
				>
					<CancelIcon />
				</CancelButton>
			</Form>
		</FormWrapper>
	);
};

export default RecipientForm;
