import CheckBox from "@components/form/Checkbox";
import ShippingExtraServicesLabel from "@components/order/shipping/carryin/ShippingExtraServicesLabel";
import {
	GTM_EVENT_NAMES,
	pushToDataLayer,
	pushToDataLayerAsync,
} from "@utils/GTMUtils";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import { useElementObserver } from "../../../customHooks/useElementObserver";

const ShippingExtraServices = ({
	extraServices,
	extraServiceIds = [],
	onExtraServiceCheck,
	isDisabled,
}) => {
	const extraServicesRef = useRef(null);

	const handleChangeExtraShipping = (e) => {
		const uniqueExtraServiceIds = new Set(extraServiceIds);

		const isExtraServiceSelected = e.target.checked;

		isExtraServiceSelected
			? uniqueExtraServiceIds.add(e.target.name)
			: uniqueExtraServiceIds.delete(e.target.name);

		const updatedExtraServiceIds = [...uniqueExtraServiceIds];

		pushToDataLayer({
			data: {
				event: GTM_EVENT_NAMES.EXTRA_SERVICES_SELECTED,
				selected: updatedExtraServiceIds.join(", "),
			},
		});

		onExtraServiceCheck(updatedExtraServiceIds);
	};

	useElementObserver(extraServicesRef, () => {
		pushToDataLayerAsync({
			data: {
				event: GTM_EVENT_NAMES.EXTRA_SERVICES_VISITED,
				options: extraServices.map(({ id }) => id).join(", "),
			},
		});
	});

	return (
		<ul id="extraServices" className="extra-options" ref={extraServicesRef}>
			{extraServices.map((service) => (
				<li key={service.id} data-service-id={service.id}>
					<CheckBox
						handleChange={handleChangeExtraShipping}
						name={service.id}
						value={extraServiceIds.includes(service.id)}
						id={service.id}
						className="extra-option"
						disabled={isDisabled}
						label={<ShippingExtraServicesLabel service={service} />}
					/>
				</li>
			))}
		</ul>
	);
};

ShippingExtraServices.propTypes = {
	extraServices: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			price: PropTypes.number,
		}),
	),
	extraServiceIds: PropTypes.arrayOf(PropTypes.string),
	onExtraServiceCheck: PropTypes.func,
	isDisabled: PropTypes.bool,
	showPrice: PropTypes.bool,
};

export default ShippingExtraServices;
