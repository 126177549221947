function callApi(path, method, data, headers = {}) {
  return new Promise((resolve, reject) => {
    let init = {
      method,
      credentials: "include",
      headers: new Headers(headers),
    };
    if (data) {
      init.body = JSON.stringify(data);
    }

    fetch(path, init)
      .then((response) => {
        if (response.ok) {
          if (response.status === 204) {
            resolve();
          } else {
            resolve(response.json());
          }
        } else {
          // resolve also if error
          resolve();
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function post(path, data) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return callApi(path, "POST", data, headers);
}

export function get(path) {
  return callApi(path, "GET", undefined, {}, true);
}
