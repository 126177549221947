import { sendPaymentEvent } from "@utils/GTMUtils";

const ALPHANUMERIC_CHARSET = "0123456789ABCDEFGHIJKLMNOPQRSTWXYZ";
const SPECIAL_CHARSET = "@$#&*{}[],=().+;'/\"";

const generateGiftCardCode = (length, useSpecialCharacters = false) => {
	let code = "";
	let charset;

	for (let i = 0; i < length; i++) {
		if (useSpecialCharacters && i % 2 == 0) {
			charset = SPECIAL_CHARSET;
		} else {
			charset = ALPHANUMERIC_CHARSET;
		}

		code += charset[Math.floor(Math.random() * charset.length)];
		if (i + 1 < length && (i + 1) % 5 == 0) {
			code += "-";
		}
	}

	return code;
};

export const generateAlphanumericCode = (length) => {
	return generateGiftCardCode(length);
};

export const generateSpecialCharsetCode = (length) => {
	return generateGiftCardCode(length, true);
};

export const sendFormErrorsToGA = (validationErrors) => {
	const errorMessages = new Set();
	for (const [key, value] of Object.entries(validationErrors)) {
		if (value) {
			errorMessages.add(`${key}: ${value}`);
		}
	}
	errorMessages.size > 0 &&
		sendPaymentEvent(`FormErrors: ${JSON.stringify([...errorMessages])}`);
};
