import { post } from "../../../utils/http";

const HOST = window.location.hostname;

export const getSubscriptionState = async ({
  apiHost,
}: {
  apiHost?: string | undefined;
}) => {
  const response = await post(
    `//${apiHost || HOST}/newsletter/flexmarketingconsents/issubscribed`
  );

  return response;
};

interface SetSubscriptionStateI {
  apiHost?: string | undefined;
  isSubscribed: boolean;
  consentContent? : string | undefined;
}
export const setSubscriptionState = async (props: SetSubscriptionStateI) => {
  return Promise.all([
    await post(
      props.isSubscribed
        ? `//${
            props.apiHost || HOST
          }/newsletter/flexmarketingconsents/unsubscribe`
        : `//${props.apiHost || HOST}/newsletter/flexmarketingconsents/subscribe`
    ),
    await post(`//${props.apiHost || HOST}/consents/customerconsents/flex/update`,{
      consentContent: props.consentContent,
      consented: props.isSubscribed
    }),
  ]).then((response) => {
    return response[0];
  });
};
