import handleErrors from "@utils/handleErrors";

export default ({ url, method = "GET", body, options = {} }) =>
	window
		.fetch(url, {
			method,
			headers: new Headers({
				"Content-Type": "application/json",
				Accept: "application/json",
			}),
			body,
			mode: "same-origin",
			cache: "no-store",
			...options,
		})
		.then(handleErrors);
