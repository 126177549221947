import {
	POSTCODE_MASKS,
	VALIDATION_REGEXP,
} from "@components/recipient/constants";

const forbiddenCharactersMessage = "validation.forbidden.characters";
const alphabeticCharactersMessage = "validation.alphabetic.characters";
const minLengthMessage = "validation.min.length";

export const requiredMessage = "validation.required";

export const validateMinLength = (value, length) => {
	if (value?.trim().length < length) {
		return minLengthMessage;
	}

	return true;
};

export const validateForbiddenCharacters = (value) =>
	VALIDATION_REGEXP.FORBIDDEN_CHARACTERS.test(value)
		? forbiddenCharactersMessage
		: true;

export const validateAlphabeticCharacters = (value) =>
	!VALIDATION_REGEXP.ALPHABETIC_CHARACTERS.test(value)
		? alphabeticCharactersMessage
		: true;

export const validateCity = (value) =>
	!VALIDATION_REGEXP.CITY.test(value) ? forbiddenCharactersMessage : true;
export const validateName = (value) => {
	if (VALIDATION_REGEXP.FORBIDDEN_CHARACTERS.test(value)) {
		return forbiddenCharactersMessage;
	}

	const maxLength = 35;

	const trimmedValue = value.trim();
	const splitValue = trimmedValue.split(" ");

	if (splitValue.length < 2) {
		const firstName = splitValue[0];

		if (firstName.length > maxLength) {
			return "validation.firstName.tooLong";
		}

		return "validation.words.count.invalid";
	}

	const firstName = splitValue.slice(0, -1).join(" ");

	if (firstName.length > maxLength) {
		return "validation.firstName.tooLong";
	}

	const lastName = splitValue.pop();

	if (lastName.length > maxLength) {
		return "validation.lastName.tooLong";
	}

	return true;
};

export const validatePhoneNumber = (value, country) =>
	VALIDATION_REGEXP.PHONE[country].test(value) || "validation.phone.invalid";

export const validatePostCodeLength = (postcode, country) => {
	const trimmedPostcode = postcode.replace(/_/g, "");
	const validPostcodeLength = POSTCODE_MASKS[country].length;

	if (trimmedPostcode.length < validPostcodeLength) {
		return "validation.postcode.length";
	}

	return null;
};
