import { WebStorageStateStore } from "oidc-client/lib/oidc-client";

import BackendStorage from "./BackendStorage";

const OidcConfiguration = {
	authority: window.identityServiceConfig.url,
	client_id: window.identityServiceConfig.clientId,
	redirect_uri:
		window.location.protocol + "//" + window.location.host + "/callback.html",
	post_logout_redirect_uri:
		window.location.protocol + "//" + window.location.host + "/index.html",
	response_type: "id_token token",
	scope: "openid kompay.api userprofile",
	userStore: new WebStorageStateStore({ store: new BackendStorage() }),
	stateStore: new WebStorageStateStore({ store: new BackendStorage() }),
	loadUserInfo: false,
	automaticSilentRenew: false,
	silentRequestTimeout: 5000,
	clockSkew: 43200,
};

export default OidcConfiguration;
