import * as CheckoutActions from "@actions/checkout.actions";
import * as RecipientActions from "@actions/recipient.actions";
import { STATUS_NAMES } from "@constants/constants";

const defaultState = {
	customerType: "Private",
	data: {
		name: "",
		addressLine1: "",
		addressLine2: "",
		postcode: "",
		city: "",
		country: "",
		email: "",
		phone: "",
		updateUserProfile: true,
	},
	status: {
		changed: false,
		hasToBeSaved: false,
	},
	isEditing: false,
	isValid: true,
	isSaving: false,
	isHidden: false,
};

const recipient = (state = defaultState, action) => {
	switch (action.type) {
		case CheckoutActions.CHECKOUT_FETCH_DONE: {
			const { status } = action.json.recipient;
			const canBeSaved =
				status.canBeSaved || action.json.recipient.status.canBeSaved;
			const hasToBeSaved =
				status.hasToBeSaved || action.json.recipient.status.hasToBeSaved;

			const { ...properAddressData } = action.json.recipient.address;
			const { ...properContactData } = action.json.recipient.contact;

			return {
				...state,
				customerType: action.json.recipient.customerType,
				data: {
					...state.data,
					...properAddressData,
					...properContactData,
					updateUserProfile: canBeSaved && state.data.updateUserProfile,
				},
				status: {
					changed: state.status.changed || status.changed,
					canBeSaved,
					hasToBeSaved,
				},
				isValid: action.json.recipient.isValid,
				isHidden:
					!action.json.recipient ||
					action.json.status === STATUS_NAMES.EXPIRED ||
					action.json.status === STATUS_NAMES.PLACED,
			};
		}

		case RecipientActions.RECIPIENT_SAVE_REQUESTED:
			return {
				...state,
				data: {
					...state.data,
					...action.recipient,
				},
				isSaving: true,
			};

		case RecipientActions.RECIPIENT_SAVE_DONE:
			return {
				...state,
				data: {
					...state.data,
					updateUserProfile: true,
				},
				status: {
					...state.status,
					changed: false,
				},
				isSaving: false,
			};

		case RecipientActions.RECIPIENT_VALIDITY_CHANGE:
			return {
				...state,
				isValid: action.payload.valid,
			};

		case RecipientActions.RECIPIENT_SAVE_FAILED:
			return {
				...state,
				isSaving: false,
			};

		case RecipientActions.EDIT_MODE_SHOW:
			return {
				...state,
				isEditing: true,
			};

		case RecipientActions.EDIT_MODE_HIDE:
			return {
				...state,
				isEditing: false,
			};
		default:
			return state;
	}
};

export default recipient;
