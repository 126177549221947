class ScrollObserver {
	constructor() {
		this.elements = {};
		this.threshold = 0.9;
		this.observer = new IntersectionObserver(
			(entries) => this.handleIntersection(entries),
			{ threshold: [this.threshold] },
		);
	}

	registerElement(element, callback) {
		if (this.isElementObserved(element)) {
			return;
		}

		this.elements[element.id] = { seen: false, callback };
		this.observer.observe(element);
	}

	unregisterElement(element) {
		if (this.elements[element?.id]) {
			delete this.elements[element.id];

			this.observer.unobserve(element);
		}
	}

	isElementObserved(element) {
		return element?.id ? !!this.elements[element.id] : false;
	}

	handleIntersection(entries) {
		entries.forEach((entry) => {
			const isElementSeen = this.elements[entry.target.id].seen;

			if (entry.isIntersecting && !isElementSeen) {
				this.handleCallback(entry.target.id);
				this.elements[entry.target.id].seen = true;
			}
		});
	}

	handleCallback(id) {
		this.elements[id]?.callback?.();
	}
}

export default new ScrollObserver();
