import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

const getStockIconClass = (stockStatus) => {
	switch (stockStatus) {
		case "Stocked":
		case "DigitalStocked":
		case "AlwaysInStock":
			return "instock";
		case "ConfirmedPurchase":
		case "WaitingForQualityInspection":
			return "incomingstock";
		case "NotInStock":
		case "OnDemand":
			return "prebook";
		case "NewPurchase":
		case "UnconfirmedPurchase":
		case "WarehouseRegistration":
			return "outofstock";
		default:
			return null;
	}
};

import { StatusContainer, StatusIcon, StatusText } from "./StockStatus.styles";

const StockStatus = ({ stringKey, params, stockStatus }) => {
	const iconStatus = getStockIconClass(stockStatus);
	return (
		<StatusContainer role="contentinfo">
			{iconStatus && <StatusIcon status={iconStatus} />}
			<StatusText>
				<FormattedMessage
					id={stringKey}
					values={{
						DATE: params.date,
						QUANTITY: params.quantity,
						LEADTIME: params.leadtime,
					}}
				/>
			</StatusText>
		</StatusContainer>
	);
};

StockStatus.propTypes = {
	stringKey: PropTypes.string,
	params: PropTypes.shape({
		date: PropTypes.string,
		quantity: PropTypes.string,
		leadtime: PropTypes.string,
	}),
	stockStatus: PropTypes.string,
};

export default StockStatus;
