import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

const ErrorContainer = ({ inputName, className }) => {
	const { errors } = useFormContext();
	const { formatMessage } = useIntl();

	return (
		<span className={classNames("error-message", className)} aria-live="polite">
			<ErrorMessage
				errors={errors}
				name={inputName}
				render={({ message }) => (
					<span
						id={`${inputName}-error`}
						aria-atomic="true"
						data-testid={`${inputName}.error.message`}
					>
						{formatMessage(
							{ id: message },
							{
								fieldname: formatMessage({
									id: `recipient.data.${inputName}.label`,
								}),
							},
						)}
					</span>
				)}
			/>
		</span>
	);
};

export default ErrorContainer;
