import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

import { LabelContainer, Info } from "./BonusPointsLabel.styles";

const DisabledBonusPointsRender = ({ amount }) => {
	return amount > 0 ? (
		<span>
			<FormattedMessage
				id="payment.methods.bonuspoints.disabled"
				values={{ points: amount }}
			/>
		</span>
	) : (
		<span data-testid="bonuspointslabel.disabled.zero">
			<FormattedMessage id="payment.methods.bonuspoints.disabled.zero" />
		</span>
	);
};

const ActiveBonusPointsRender = ({
	enableLegacyStyle,
	active,
	usedAmount,
	amount,
}) => {
	return (
		<LabelContainer enableLegacyStyle={enableLegacyStyle}>
			<FormattedMessage id="payment.methods.bonuspoints.label" />
			{active && (
				<div>
					<Info data-testid="bonuspointslabel.enabled">
						<FormattedMessage
							id="payment.methods.bonuspoints.amount"
							values={{ usedAmount, amount }}
						/>
					</Info>
				</div>
			)}
		</LabelContainer>
	);
};

const BonusPointsLabel = ({
	enableLegacyStyle = false,
	active,
	disabled,
	usedAmount,
	amount = 0,
}) => {
	return disabled ? (
		<DisabledBonusPointsRender amount={amount} />
	) : (
		<ActiveBonusPointsRender
			enableLegacyStyle={enableLegacyStyle}
			active={active}
			usedAmount={usedAmount}
			amount={amount}
		/>
	);
};

BonusPointsLabel.propTypes = {
	enableLegacyStyle: PropTypes.bool,
	disabled: PropTypes.bool,
	amount: PropTypes.number,
	usedAmount: PropTypes.number,
};

export { BonusPointsLabel };
