import styled from "@emotion/styled";
import {AlertStatus} from "./Alert.types";

export const AlertContainer = styled('div')<{ status: AlertStatus }>(({ theme, status }) => ({
	display: 'flex',
	backgroundColor: theme.alerts[status].background,
	padding: '1rem',
	marginBottom: '1rem',
	borderRadius: '0.5rem',
	alignItems: 'center'
}))

export const AlertContent = styled('div')<{ status: AlertStatus }>(({ theme, status }) => ({
	marginLeft: '0.875rem',
	color: '#000',
	fontWeight: 600,
	fontSize: 18,
	textAlign: 'left'
}))

export const AlertIcon = styled('span')<{ status: AlertStatus }>(({ status }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: '#fee2e2'
}))

export const AlertButton = styled('button')(() => ({
	marginLeft: 'auto',
	marginRight: '0.375rem',
	border: 'none',
	background: 'none',
	cursor: 'pointer'
}))