import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

const TermsContent = ({ contentId, linkId, handleOpenLink }) => {
	return (
		<Fragment>
			<header className="header" id="summaryTermsModalHeader">
				<h1 className="title">
					<FormattedMessage id="footer.termsandconditions.header" />
				</h1>
			</header>
			<div className="content" id="summaryTermsModalContent">
				<div className="richtext">
					<FormattedHTMLMessage tagName="div" id={contentId} />
				</div>
			</div>
			<a
				href={linkId}
				target="_blank"
				rel="noopener noreferrer"
				className="more"
				onClick={handleOpenLink}
			>
				<FormattedMessage
					id={"footer.termsandconditions.dialog.links.content"}
				/>
			</a>
		</Fragment>
	);
};

TermsContent.propTypes = {
	contentId: PropTypes.string,
	linkId: PropTypes.string,
	handleOpenLink: PropTypes.func,
};

export { TermsContent };
