import FinancePaymentMethodInfo from "@components/v2/components/payments/methods/financePaymentMethodInfo/FinancePaymentMethodInfo";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

const FinancePaymentMethod = ({ type, version }) => {
	const selectPaymentMethod = (state) => state.payment.directMethods[type];
	const options = useSelector((state) => selectPaymentMethod(state).options);
	const selectedOptionCode = useSelector(
		(state) => selectPaymentMethod(state).selectedOptionCode,
	);
	const selectedOption = options[selectedOptionCode];

	return (
		<div className="finance-payment" data-automationid={`${type}.container`}>
			{selectedOption.paymentPlan && (
				<FinancePaymentMethodInfo
					version={version}
					type={type}
					selectedOption={selectedOption}
					options={options}
				/>
			)}
		</div>
	);
};

FinancePaymentMethod.propTypes = {
	type: PropTypes.string,
};

export default FinancePaymentMethod;
