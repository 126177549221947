import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled("div")`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const Label = styled("label")`
  && {
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
`;

export const StyledCheckbox = styled("input")`
  -webkit-appearance: none;
`;

export const ErrorMessage = styled("span")`
  font-size: 14px;
  color: #f00;
`;

export const IconWrapper = styled("div")(
  ({ isDisabled }: { isDisabled: boolean }) => css`
    display: flex;
    padding-right: 8px;
    pointer-events: ${isDisabled ? "none" : "all"};
    opacity: ${isDisabled ? "0.6" : "1"};
  `
);
