export default (getCheckoutResponse) => ({
	event: "checkoutLoadedEvent",
	containerId: "GTM-KRFG2W5",
	checkoutId: getCheckoutResponse.id,
	pageType: "Checkout",
	initialCheckoutType: "KomPay",
	initialPaymentMethod:
		getCheckoutResponse?.payment?.subscriptionPayment?.type ||
		getCheckoutResponse?.payment?.directPayment?.initialPaymentType,
	initialFinancingPaymentMethod:
		getCheckoutResponse?.payment?.directPayment?.initialFinancingPaymentMethod,
	checkoutOrderType: getCheckoutResponse.checkoutOrderType,
	storeId: getCheckoutResponse.storeId,
	all: {
		customerNumber: getCheckoutResponse.recipient.hashedRecipientId,
		customerType: getCheckoutResponse.recipient.customerType,
	},
});
